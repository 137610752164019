import React from 'react';

const Membership = () => {

  // Definition of missing functions
  const changeFontSize = (size) => {
    const body = document.body;
    const currentSize = parseFloat(window.getComputedStyle(body).fontSize);
    body.style.fontSize = `${currentSize + size}px`;
  };

  const revertStyles = () => {
    document.body.style.fontSize = "16px"; // Default font size
  };

  const close = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.style.display = 'none'; // Close the section
    }
  };

  return (
    <div id="shadow">
      <div id="all">
        <div id="back">
          <header id="header">
            <div className="logoheader">
              <h1 id="logo">
                <img src="/images/deustch-afrika-logo.png" alt="Germany-Africa!" />
              </h1>
              <table>
                <tr>
                  <td>
                    <div className="custom">
                      <p>&nbsp;</p>
                      <div>
                        <table>
                          <tbody>
                            <tr>
                            <td id="nomong" align="center" valign="bottom" bgcolor="transparent">
                                <span className="Style2">Deutschland-Afrika e.V.</span>
                              </td>
                            </tr>
                            <tr>
                              <td
                                id="tagline"
                                align="center"
                                valign="top"
                                bgcolor="transparent"
                              >
                                <span className="Style1">International NGO Germany-Africa</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <div id="lang"></div>
                  </td>
                </tr>
              </table>
            </div>
            <ul className="skiplinks">
              <li>
                <a href="#main" className="u2">
                  Go to content
                </a>
              </li>
              <li>
                <a href="#nav" className="u2">
                  Go to main menu and identification
                </a>
              </li>
              <li>
                <a href="#right" className="u2">
                  Go to additional information
                </a>
              </li>
            </ul>
            <h2 className="unseen">Search Navigation</h2>
            <h3 className="unseen">Navigation</h3>

            <div id="line">
              <div id="fontsize">
                <h3>Font Size</h3>
                <p>
                  <a
                    title="Increase size"
                    href="#"
                    onClick={(e) => { e.preventDefault(); changeFontSize(2); }}
                  >
                    Increase
                  </a>
                  <span className="unseen">.</span>
                  <a
                    href="#"
                    title="Revert to default styles"
                    onClick={(e) => { e.preventDefault(); revertStyles(); }}
                  >
                    Reset
                  </a>
                  <span className="unseen">.</span>
                  <a
                    href="#"
                    title="Decrease size"
                    onClick={(e) => { e.preventDefault(); changeFontSize(-2); }}
                  >
                    Decrease
                  </a>
                </p>
              </div>
              <h3 className="unseen">Search</h3>
              <div className="mod-languages">
                <ul className="lang-inline">
                  <li className="" dir="ltr">
                    <a href="Mitgliedschaft">
                      <img src="/lang/de_de.gif" alt="German" title="German" />
                    </a>
                  </li>
                  <li className="" dir="ltr">
                    <a href="Adhesion">
                      <img src="/lang/fr_fr.gif" alt="French" title="French" />
                    </a>
                  </li>
                  <li className="" dir="ltr">
                    <a href="Membership">
                      <img src="/lang/en.gif" alt="English (UK)" title="English (UK)" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </header>

          <div id="contentarea2">
            <div id="breadcrumbs">
              <div className="breadcrumbs">
                <span className="showHere">You are here: </span>
                <a href="HomeEn" className="pathway">
                  Home
                </a>
                <img src="/images/arrow.png" alt="" /> <span>Membership</span>
              </div>
            </div>

            <nav className="left1" id="nav">
              <div className="moduletable_menu">
                <h3>Main Menu</h3>
                <ul className="nav menu mod-list">
                  <li className="item-103 default">
                    <a href="HomeEn">Home</a>
                  </li>
                  <li className="item-105 divider deeper parent">
                    <span className="separator">Presentation</span>
                    <ul className="nav-child unstyled small">
                      <li className="item-106">
                        <a href="MotivationObjectif">Motivations / Objectives</a>
                      </li>
                      <li className="item-107">
                        <a href="TextsOfTheNgo">Texts of the NGO</a>
                      </li>
                      <li className="item-108">
                        <a href="PresidentGermanyAfrica">The international president</a>
                      </li>
                    </ul>
                  </li>
                  <li className="item-109 current active">
                    <a href="Membership">Membership</a>
                  </li>
                  <li className="item-110">
                    <a href="Contacts">Contacts</a>
                  </li>
                </ul>
              </div>
            </nav>

            <div id="wrapper">
              <div id="main">
                <div id="system-message-container"></div>

                <article className="item-page">
                  <div className="pull-left"></div>
                  <div style={{ textAlign: 'center' }}>
                    <span style={{ fontSize: '14pt' }}>
                      You can become a member of Germany-Africa!
                    </span>
                  </div>
                  <div>
                    <br />
                    <br />
                    <br />
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    <span style={{ fontSize: '14pt' }}>
                      After being informed
                    </span>
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    <span style={{ fontSize: '14pt' }}>
                      <li>
                        <a href="TextsOfTheNgo">about the articles related to membership in our texts</a>
                      </li>
                    </span>
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    <span style={{ fontSize: '14pt' }}>
                      submit a membership request by{' '}
                      <li>
                        <a href="Contacts">clicking here</a>
                      </li>
                    </span>
                  </div>
                  <p>&nbsp;</p>
                  <p>&nbsp;</p>
                </article>
              </div>
            </div>

            <div id="close">
              <a href="#" onClick={() => close('right')}>
                <span id="bild">Close the info</span>
              </a>
            </div>
            <aside id="right">
              <h2 className="unseen">Additional Information</h2>
              <div className="moduletable">
                <h3>Representation</h3>
                <div className="custom">
                  <p>Our headquarters is in Germany, in BONN.</p>
                  <p>
                    The Representation directs and coordinates in an African country, the activities of
                    "Germany-AFRICA".
                  </p>
                  <p>The first and only representation for now is in Côte d'Ivoire.</p>
                  <p>&nbsp;</p>
                  <p>&nbsp;</p>
                </div>
              </div>
            </aside>
          </div>
        </div>
            <div id="footer-outer">
                <div id="footer-sub">
                    <footer id="footer"></footer>
                </div>
            </div>
      </div>
    </div>
  );
};

export default Membership;