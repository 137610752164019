import React from 'react';

const Contacts = () => {
  return (
    <div id="all">
      <div id="back">
        <div id="header">
          <div className="logoheader">
            <h1 id="logo">
              <img src="/images/deustch-afrika-logo.png" alt="Deutschland-Afrika !" />
              <span className="header1">Deutschland-Afrika</span>
            </h1>
          </div>
          {/* End logoheader */}
          <ul className="skiplinks">
            <li>
              <a href="#wrapper2" className="u2">Go to error message</a>
            </li>
            <li>
              <a href="#nav" className="u2">Go to navigation</a>
            </li>
          </ul>
          <div id="line"></div>
          {/* End line */}
        </div>
        {/* End header */}

        <div id="contentarea2">
          <div className="left1" id="nav">
            <h2 className="unseen">Navigation</h2>
            <ul className="nav menu mod-list">
              <li className="item-103 default"><a href="HomeEn">Home</a></li>
              <li className="item-105 divider deeper parent">
                <span className="separator">Presentation</span>
                <ul className="nav-child unstyled small">
                  <li className="item-106"><a href="MotivationObjectif">Motivations / Objectives</a></li>
                  <li className="item-107"><a href="TextsOfTheNgo">Texts of the NGO</a></li>
                  <li className="item-108"><a href="PresidentGermanyAfrica">The International President</a></li>
                </ul>
              </li>
              <li className="item-109"><a href="Membership">Membership</a></li>
              <li className="item-110 current active"><a href="Contacts">Contact</a></li>
            </ul>
          </div>
          {/* End navigation */}

          <div id="wrapper2">
            <div id="errorboxbody">
              <h2>The requested page cannot be displayed.</h2>
              <h3>An error occurred while processing the request.</h3>
              <p>You cannot access this page because:</p>
              <ul>
                <li><strong>outdated bookmark/favorite</strong></li>
                <li><strong>incorrect address</strong></li>
                <li>A search engine has an <strong>outdated</strong> listing for this site</li>
                <li>You <strong>do not</strong> have access to this page</li>
              </ul>
              <div>
                {/* Start go to home page */}
                <p>
                  <a href="HomeEn" title="Go to the homepage">Homepage</a>
                </p>
              </div>
              {/* End go to home page */}
              <h3>If the issue persists, please contact the site administrator.</h3>
              <h2>#404&nbsp;Contact not found</h2>
              <br />
            </div>
            {/* End errorboxbody */}
          </div>
        </div>
        {/* End contentarea2 */}
      </div>
      {/* End back */}
      <div id="footer-outer">
        <div id="footer-sub">
          <footer id="footer"></footer>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
