import React from 'react';


const Contact = () => {
  return (
    <div id="all">
      <div id="back">
        <div id="header">
          <div className="logoheader">
            <h1 id="logo">
              <img src="/images/deustch-afrika-logo.png" alt="Deutschland-Afrika !" />
              <span className="header1">Deutschland-Afrika</span>
            </h1>
          </div>
          {/* End logoheader */}
          <ul className="skiplinks">
            <li>
              <a href="#wrapper2" className="u2">Aller au message d'erreur</a>
            </li>
            <li>
              <a href="#nav" className="u2">Aller à la navigation</a>
            </li>
          </ul>
          <div id="line"></div>
          {/* End line */}
        </div>
        {/* End header */}

        <div id="contentarea2">
          <div className="left1" id="nav">
            <h2 className="unseen">Navigation</h2>
            <ul className="nav menu mod-list">
              <li className="item-103 default"><a href="Accueil">Accueil</a></li>
              <li className="item-105 divider deeper parent">
                <span className="separator">Présentation</span>
                <ul className="nav-child unstyled small">
                  <li className="item-106"><a href="MotivationsObjectifs">Motivations / Objectifs</a></li>
                  <li className="item-107"><a href="TextesAllemagneAfrique">Les textes de l'ONG</a></li>
                  <li className="item-108"><a href="PresidentAllemagneAfrique">Le président international</a></li>
                </ul>
              </li>
              <li className="item-109"><a href="Adhesion">Adhésion</a></li>
              <li className="item-110 current active"><a href="Contact">Contacts</a></li>
            </ul>
          </div>
          {/* End navi */}

          <div id="wrapper2">
            <div id="errorboxbody">
              <h2>La page recherchée ne peut être affichée.</h2>
              <h3>Une erreur est survenue pendant l'exécution de la requête.</h3>
              <p>Vous ne pouvez pas visiter cette page car :</p>
              <ul>
                <li><strong>bookmark/favori périmé</strong></li>
                <li>une adresse <strong>erronée</strong></li>
                <li>Un moteur de recherche possède un listing <strong>périmé</strong> pour ce site</li>
                <li>vous <strong>n'avez pas</strong> accès à cette page</li>
              </ul>
              <div>
                {/* start goto home page */}
                <p>
                  <a href="Accueil" title="Aller à la page d'accueil">Page d'accueil</a>
                </p>
              </div>
              {/* end goto home page */}
              <h3>Si les difficultés persistent, merci de contacter l'administrateur de ce site.</h3>
              <h2>#404&nbsp;Contact introuvable</h2>
              <br />
            </div>
            {/* end errorboxbody */}
          </div>
        </div>
        {/* end contentarea2 */}
      </div>
      {/* end back */}
           <div id="footer-outer">
                <div id="footer-sub">
                    <footer id="footer"></footer>
                </div>
            </div>
    </div>

   
  );
};

export default Contact;
