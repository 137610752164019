import React from 'react';

const MotivationsObjectifs = () => {

      // Définition des fonctions manquantes
      const changeFontSize = (size) => {
        const body = document.body;
        const currentSize = parseFloat(window.getComputedStyle(body).fontSize);
        body.style.fontSize = `${currentSize + size}px`;
      };

      const revertStyles = () => {
        document.body.style.fontSize = "16px"; // Taille de police par défaut
      };

      const auf = (id) => {
        const element = document.getElementById(id);
        if (element) {
          element.style.display = 'none'; // Ferme la section
        }
      };

  return (
    <>
      
        <title>Motivations / Objectifs</title>
      
      <body id="shadow">
        <div id="all">
          <div id="back">
            <header id="header">
              <div className="logoheader">
                <h1 id="logo">
                  <img src="/images/deustch-afrika-logo.png" alt="Deutschland-Afrika !" />
                </h1>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div className="custom">
                          <p>&nbsp;</p>
                          <div>
                            <table>
                              <tbody>
                                <tr>
                                  <td id="nomong" align="center" valign="bottom" bgcolor="transparent">
                                    <span className="Style2">Deutschland-Afrika e.V. </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td id="tagline" align="center" valign="top" bgcolor="transparent">
                                    <span className="Style1">ONG Internationale Allemagne-Afrique</span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>
                        <div id="lang"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <ul className="skiplinks">
                <li>
                  <a href="#main" className="u2">
                    Aller au contenu
                  </a>
                </li>
                <li>
                  <a href="#nav" className="u2">
                    Aller au menu principal et à l'identification
                  </a>
                </li>
                <li>
                  <a href="#right" className="u2">
                    Aller aux informations additionnelles
                  </a>
                </li>
              </ul>
            <div id="line">
              <div id="fontsize">
                <h3>Taille de la police</h3>
                <p>
                  <a
                    title="Augmenter la taille"
                    href=""
                    onClick={(e) => { e.preventDefault(); changeFontSize(2); }}
                  >
                    Augmenter
                  </a>
                  <span className="unseen">.</span>
                  <a
                    href=""
                    title="Revenir aux styles par défaut"
                    onClick={(e) => { e.preventDefault(); revertStyles(); }}
                  >
                    Réinitialiser
                  </a>
                  <span className="unseen">.</span>
                  <a
                    href=""
                    title="Réduire la taille"
                    onClick={(e) => { e.preventDefault(); changeFontSize(-2); }}
                  >
                    Diminuer
                  </a>
                </p>
              </div>
              <h3 className="unseen">Recherche</h3>
              <div className="mod-languages">
                <ul className="lang-inline">
                  <li className="" dir="ltr">
                    <a href="MotivationenZiele">
                      <img src="/lang/de_de.gif" alt="Deutsch" title="Deutsch" />
                    </a>
                  </li>
                  <li className="" dir="ltr">
                    <a href="MotivationsObjectifs">
                      <img src="/lang/fr_fr.gif" alt="Français" title="Français" />
                    </a>
                  </li>
                  <li className="" dir="ltr">
                    <a href="MotivationObjectif">
                      <img src="/lang/en.gif" alt="English (UK)" title="English (UK)" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </header>
            <div id="contentarea2">
              <div id="breadcrumbs">
                <div className="breadcrumbs">
                  <span className="showHere">Vous êtes ici : </span>
                  <a href="Accueil" className="pathway">
                    Accueil
                  </a>{' '}
                  <img src="/images/arrow.png" alt="" /> <span>Présentation</span>{' '}
                  <img src="/images/arrow.png" alt="" /> <span>Motivations / Objectifs</span>
                </div>
              </div>
              <nav className="left1" id="nav">
                <div className="moduletable_menu">
                  <h3>Menu principal</h3>
                  <ul className="nav menu mod-list">
                    <li className="item-103 default">
                      <a href="Accueil">Accueil</a>
                    </li>
                    <li className="item-105 active divider deeper parent">
                      <span className="separator">Présentation</span>
                      <ul className="nav-child unstyled small">
                        <li className="item-106 current active">
                          <a href="MotivationsObjectifs">Motivations / Objectifs</a>
                        </li>
                        <li className="item-107">
                          <a href="TextesAllemagneAfrique">Les textes de l'ONG</a>
                        </li>
                        <li className="item-108">
                          <a href="PresidentAllemagneAfrique">Le président international</a>
                        </li>
                      </ul>
                    </li>
                    <li className="item-109">
                      <a href="Adhesion">Adhésion</a>
                    </li>
                    <li className="item-110">
                      <a href="Contact">Contacts</a>
                    </li>
                  </ul>
                </div>
              </nav>
              <div id="wrapper">
                <div id="main">
                  <div id="system-message-container"></div>
                  <article className="item-page">
                    <p className="Stylemotiv">
                      Considérant que les graves problèmes de l’humanité ont une partie de leurs causes dans le rejet de l’autre.
                    </p>
                    <p className="Stylemotiv">
                      Considérant que ce rejet se fait très souvent sur la base de préjugés ;
                    </p>
                    <p className="Stylemotiv">
                      Convaincus que le déficit de communication est en grande partie à la base de ces préjugés ;
                    </p>
                    <p className="Stylemotiv">
                      Convaincus du fait que le rapprochement et l’entente entre les peuples pourront contribuer à l’harmonie sur terre et au rayonnement de l’humanité entière, nous avons décidé de créer une ONG pour le rapprochement et l’entente entre les peuples.
                    </p>
                    <div className="Layer4">
                      <span className="Style3" style={{ fontFamily: "verdana, geneva", fontSize: "10pt" }}>
                        NOS OBJECTIFS
                      </span>
                    </div>
                    <div>
                      <span style={{ fontFamily: "verdana, geneva", fontSize: "10pt" }}>&nbsp;</span>
                    </div>
                    <div className="LayMotObj">
                      <p className="Stylemotiv" align="left">
                        L’objectif de l’ONG est l’entente entre les peuples. Mais "Allemagne-Afrique" se concentre sur l’entente entre le peuple allemand et les peuples africains. L’ONG contribue au rapprochement du peuple allemand et des peuples africains. "Allemagne-Afrique" doit contribuer à la lutte contre les préjugés entre le peuple allemand et les peuples africains.
                      </p>
                      <p className="Stylemotiv" align="left">
                        L’ONG poursuit exclusivement et directement des buts d’intérêts communautaires dans le sens du paragraphe « objectifs favorisés en matière d’impôts » des textes sur la réglementation fiscale. L’objectif est réalisé surtout à travers les mesures suivantes :
                      </p>
                      <div align="left">
                        <ol className="Stylemotiv">
                          <li className="Stylemotiv">
                            Organisation de festivals de la culture allemande dans les pays africains. Une fois par an, "Allemagne-Afrique " organise dans un pays africain, un festival de la culture allemande. Le contenu du festival dépend du niveau de communication entre le peuple allemand et le pays africain concerné ;
                          </li>
                          <li className="Stylemotiv">Organisation de colloques dans le domaine de l’Education ;</li>
                          <li className="Stylemotiv">
                            Organisation d’émissions de radio ou de télévision sur l’Allemagne dans les pays africains et sur les pays africains en Allemagne ;
                          </li>
                          <li className="Stylemotiv">Organisation de festivals de la culture africaine en Allemagne.</li>
                        </ol>
                      </div>
                      <p className="Stylemotiv" align="left">
                        Pendant les manifestations d’"ALLEMAGNE-AFRIQUE" dans les pays africains, les langues de communication sont l’Allemand et la langue officielle du pays où la manifestation a lieu.<br />
                        <br /> "ALLEMAGNE-AFRIQUE" est bénévole. L’ONG ne poursuit pas de but lucratif. Les moyens de l’ONG ne doivent être utilisés que pour les objectifs visés dans les textes la régissant. Les membres ne perçoivent pas de gains et dans leur qualité de membres aucune subvention particulière émanant des moyens de l’ONG non plus. Il n’est pas permis de faire des dépenses qui sont étrangères aux objectifs de l’ONG ou de faire des compensations démesurées dans le but de favoriser une personne.
                      </p>
                      <p className="Stylemotiv" align="left">
                        En cas de dissolution de l’ONG ou en cas d’abandon de l’objectif pour lequel elle a été créée, les biens de l’ONG reviennent à l’Institut GOETHE pour qu’ils servent à la formation de personnes étrangères à l’Allemagne.
                      </p>
                      <p className="Stylemotiv" align="left">
                        Tous ceux qui dirigent les organes de l’ONG ne font qu’une fonction honorifique. Toute décision concernant un changement des textes doit être présentée à l’office des finances compétent avant d’être signalée au tribunal.
                      </p>
                    </div>
                    <p>
                      <span style={{ fontFamily: "verdana, geneva", fontSize: "10pt" }}>&nbsp;</span>
                    </p>
                  </article>
                </div>
              </div>
              <aside id="right">
                  <h2 className="unseen">Informations supplémentaires</h2>
                  <div className="moduletable">
                    <h3>Représentation</h3>
                    <div className="custom">
                      <p>Notre siège est en Allemagne, à BONN.</p>
                      <p>
                        La Représentation dirige et coordonne dans un pays africain, les activités de
                        "Allemagne-AFRIQUE".
                      </p>
                      <p>La première et seule représentation pour l'instant est en Côte d'Ivoire.</p>
                      <p>&nbsp;</p>
                      <p>&nbsp;</p>
                    </div>
                  </div>
                </aside>
            </div>
          </div>
            <div id="footer-outer">
              <div id="footer-sub">
                <footer id="footer"></footer>
              </div>
            </div>
    
        </div>
      </body>
    </>
  );
};

export default MotivationsObjectifs;
