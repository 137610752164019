import React from 'react';

const Mitgliedschaft = () => {

  // Definition der fehlenden Funktionen
  const changeFontSize = (size) => {
    const body = document.body;
    const currentSize = parseFloat(window.getComputedStyle(body).fontSize);
    body.style.fontSize = `${currentSize + size}px`;
  };

  const revertStyles = () => {
    document.body.style.fontSize = "16px"; // Standard-Schriftgröße
  };

  const auf = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.style.display = 'none'; // Abschnitt schließen
    }
  };

  return (
    <div id="shadow">
      <div id="all">
        <div id="back">
          <header id="header">
            <div className="logoheader">
              <h1 id="logo">
                <img src="/images/deustch-afrika-logo.png" alt="Deutschland-Afrika!" />
              </h1>
              <table>
                <tr>
                  <td>
                    <div className="custom">
                      <p>&nbsp;</p>
                      <div>
                        <table>
                          <tbody>
                            <tr>
                              <td
                                id="nomong"
                                align="center"
                                valign="bottom"
                                bgcolor="transparent"
                              >
                                <span className="Style2">Deutschland-Afrika e.V. </span>
                              </td>
                            </tr>
                            <tr>
                              <td
                                id="tagline"
                                align="center"
                                valign="top"
                                bgcolor="transparent"
                              >
                                <span className="Style1">Internationale NGO Deutschland-Afrika</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <div id="lang"></div>
                  </td>
                </tr>
              </table>
            </div>
            <ul className="skiplinks">
              <li>
                <a href="#main" className="u2">
                  Zum Inhalt springen
                </a>
              </li>
              <li>
                <a href="#nav" className="u2">
                  Zum Hauptmenü und Login springen
                </a>
              </li>
              <li>
                <a href="#right" className="u2">
                  Zu zusätzlichen Informationen springen
                </a>
              </li>
            </ul>
            <h2 className="unseen">Suchnavigation</h2>
            <h3 className="unseen">Navigation</h3>

            <div id="line">
              <div id="fontsize">
                <h3>Schriftgröße</h3>
                <p>
                  <a
                    title="Größe erhöhen"
                    href="#"
                    onClick={(e) => { e.preventDefault(); changeFontSize(2); }}
                  >
                    Vergrößern
                  </a>
                  <span className="unseen">.</span>
                  <a
                    href="#"
                    title="Auf Standard zurücksetzen"
                    onClick={(e) => { e.preventDefault(); revertStyles(); }}
                  >
                    Zurücksetzen
                  </a>
                  <span className="unseen">.</span>
                  <a
                    href="#"
                    title="Größe verringern"
                    onClick={(e) => { e.preventDefault(); changeFontSize(-2); }}
                  >
                    Verkleinern
                  </a>
                </p>
              </div>
              <h3 className="unseen">Suche</h3>
              <div className="mod-languages">
                <ul className="lang-inline">
                  <li className="" dir="ltr">
                    <a href="Mitgliedschaft">
                      <img src="/lang/de_de.gif" alt="Deutsch" title="Deutsch" />
                    </a>
                  </li>
                  <li className="" dir="ltr">
                    <a href="Adhesion">
                      <img src="/lang/fr_fr.gif" alt="Französisch" title="Französisch" />
                    </a>
                  </li>
                  <li className="" dir="ltr">
                    <a href="Membership">
                      <img src="/lang/en.gif" alt="Englisch (UK)" title="Englisch (UK)" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </header>

          <div id="contentarea2">
            <div id="breadcrumbs">
              <div className="breadcrumbs">
                <span className="showHere">Sie sind hier: </span>
                <a href="Empfang" className="pathway">
                Empfangsseite
                </a>
                <img src="/images/arrow.png" alt="" /> <span>Mitgliedschaft</span>
              </div>
            </div>

            <nav className="left1" id="nav">
              <div className="moduletable_menu">
                <h3>wichtigstes Menü</h3>
                <ul className="nav menu mod-list">
                  <li className="item-103 default">
                    <a href="Empfang">Empfangsseite</a>
                  </li>
                  <li className="item-105 divider deeper parent">
                    <span className="separator">Über uns</span>
                    <ul className="nav-child unstyled small">
                      <li className="item-106">
                        <a href="MotivationZiele">Motivation / Ziele</a>
                      </li>
                      <li className="item-107">
                        <a href="NGOText">SATZUNG DES VEREINS</a>
                      </li>
                      <li className="item-108">
                        <a href="PräsidentDeutschlandAfrika">Der internationale Präsident</a>
                      </li>
                    </ul>
                  </li>
                  <li className="item-109 current active">
                    <a href="Mitgliedschaft">Mitgliedschaft</a>
                  </li>
                  <li className="item-110">
                    <a href="Kontakt">Kontakt</a>
                  </li>
                </ul>
              </div>
            </nav>

            <div id="wrapper">
              <div id="main">
                <div id="system-message-container"></div>

                <article className="item-page">
                  <div className="pull-left"></div>
                  <div style={{ textAlign: 'center' }}>
                    <span style={{ fontSize: '14pt' }}>
                      Sie können Mitglied von Deutschland-Afrika werden!
                    </span>
                  </div>
                  <div>
                    <br />
                    <br />
                    <br />
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    <span style={{ fontSize: '14pt' }}>
                      Nach Kenntnisnahme von
                    </span>
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    <span style={{ fontSize: '14pt' }}>
                      <li>
                        <a href="NGOText">den Artikeln zu den Mitgliedern in unseren Texten</a>
                      </li>
                    </span>
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    <span style={{ fontSize: '14pt' }}>
                      stellen Sie einen Mitgliedsantrag, indem Sie{' '}
                      <li>
                        <a href="Kontakt">hier klicken</a>
                      </li>
                    </span>
                  </div>
                  <p>&nbsp;</p>
                  <p>&nbsp;</p>
                </article>
              </div>
            </div>

            <div id="close">
              <a href="#" onClick={() => auf('right')}>
                <span id="bild">Info schließen</span>
              </a>
            </div>
            <aside id="right">
              <h2 className="unseen">Zusätzliche Informationen</h2>
              <div className="moduletable">
                <h3>Vertretung</h3>
                <div className="custom">
                  <p>Unser Hauptsitz befindet sich in Bonn, Deutschland.</p>
                  <p>
                    Die Vertretung leitet und koordiniert in einem afrikanischen Land die Aktivitäten
                    von "Deutschland-Afrika".
                  </p>
                  <p>Die erste und einzige Vertretung befindet sich derzeit in Côte d'Ivoire.</p>
                  <p>&nbsp;</p>
                  <p>&nbsp;</p>
                </div>
              </div>
            </aside>
          </div>
        </div>
        <div id="footer-outer">
          <div id="footer-sub">
            <footer id="footer"></footer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mitgliedschaft;
