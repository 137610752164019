import React from 'react';

const Kontakt = () => {
  return (
    <div id="all">
      <div id="back">
        <div id="header">
          <div className="logoheader">
            <h1 id="logo">
              <img src="/images/deustch-afrika-logo.png" alt="Deutschland-Afrika !" />
              <span className="header1">Deutschland-Afrika e.V.</span>
            </h1>
            
          </div>
          {/* Ende logoheader */}
          <ul className="skiplinks">
            <li>
              <a href="#wrapper2" className="u2">Zum Fehlermeldung springen</a>
            </li>
            <li>
              <a href="#nav" className="u2">Zur Navigation springen</a>
            </li>
          </ul>
          <div id="line"></div>
          {/* Ende Linie */}
        </div>
        {/* Ende Header */}

        <div id="contentarea2">
          <div className="left1" id="nav">
            <h2 className="unseen">Navigation</h2>
            <ul className="nav menu mod-list">
              <li className="item-103 default"><a href="Empfang">Empfangsseite</a></li>
              <li className="item-105 divider deeper parent">
                <span className="separator">Vorstellung</span>
                <ul className="nav-child unstyled small">
                  <li className="item-106"><a href="MotivationenZiele">Motivationen / Ziele</a></li>
                  <li className="item-107"><a href="NGOText">SATZUNG DES VEREINS</a></li>
                  <li className="item-108"><a href="PräsidentDeutschlandAfrika">Der Internationale Präsident</a></li>
                </ul>
              </li>
              <li className="item-109"><a href="Mitgliedschaft">Mitgliedschaft</a></li>
              <li className="item-110 current active"><a href="Kontakt">Kontakt</a></li>
            </ul>
          </div>
          {/* Ende Navigation */}

          <div id="wrapper2">
            <div id="errorboxbody">
              <h2>Die angeforderte Seite kann nicht angezeigt werden.</h2>
              <h3>Ein Fehler ist während der Anfrage aufgetreten.</h3>
              <p>Sie können diese Seite nicht aufrufen, weil:</p>
              <ul>
                <li><strong>veraltetes Lesezeichen</strong></li>
                <li><strong>falsche Adresse</strong></li>
                <li>Eine Suchmaschine hat einen <strong>veralteten</strong> Eintrag für diese Seite</li>
                <li>Sie <strong>keinen Zugriff</strong> auf diese Seite haben</li>
              </ul>
              <div>
                {/* Startseite-Link */}
                <p>
                  <a href="Empfang" title="Zur Startseite gehen">Empfangsseite</a>
                </p>
              </div>
              {/* Ende Startseite-Link */}
              <h3>Falls das Problem weiterhin besteht, kontaktieren Sie bitte den Administrator dieser Seite.</h3>
              <h2>#404&nbsp;Kontakt nicht gefunden</h2>
              <br />
            </div>
            {/* Ende errorboxbody */}
          </div>
        </div>
        {/* Ende contentarea2 */}
      </div>
      {/* Ende back */}
      <div id="footer-outer">
        <div id="footer-sub">
          <footer id="footer"></footer>
        </div>
      </div>
    </div>
  );
};

export default Kontakt;
