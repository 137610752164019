import React from 'react';

const HomeEn = () => {
  // Definition of missing functions
  const changeFontSize = (size) => {
    const body = document.body;
    const currentSize = parseFloat(window.getComputedStyle(body).fontSize);
    body.style.fontSize = `${currentSize + size}px`;
  };

  const revertStyles = () => {
    document.body.style.fontSize = "16px"; // Default font size
  };

  const auf = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.style.display = 'none'; // Close the section
    }
  };

  return (
    <div id="shadow">
      <div id="all">
        <div id="back">
          <header id="header">
            <div className="logoheader">
              <h1 id="logo">
                <img src="/images/deustch-afrika-logo.png" alt="Deutschland-Afrika!" />
              </h1>
              <table>
                <tr colspan="2">
                  <td>
                    <div className="custom">
                      <p>&nbsp;</p>
                      <div>
                        <table>
                          <tbody>
                            <tr>
                              <td id="nomong" align="center" valign="bottom" bgcolor="transparent">
                                <span className="Style2">Deutschland-Afrika e.V.</span>
                              </td>
                            </tr>
                            <tr>
                              <td id="tagline" align="center" valign="top" bgcolor="transparent">
                                <span className="Style1">International NGO Germany-Africa</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <div id="lang"></div>
                  </td>
                </tr>
              </table>
            </div>
            <ul className="skiplinks">
              <li>
                <a href="#main" className="u2">Go to content</a>
              </li>
              <li>
                <a href="#nav" className="u2">Go to main menu and identification</a>
              </li>
              <li>
                <a href="#right" className="u2">Go to additional information</a>
              </li>
            </ul>
            <h2 className="unseen">Search navigation</h2>
            <h3 className="unseen">Navigation</h3>

            <div id="line">
              <div id="fontsize">
                <h3>Font size</h3>
                <p>
                  <a
                    title="Increase size"
                    href="#"
                    onClick={(e) => { e.preventDefault(); changeFontSize(2); }}
                  >
                    Increase
                  </a>
                  <span className="unseen">.</span>
                  <a
                    href="#"
                    title="Reset styles"
                    onClick={(e) => { e.preventDefault(); revertStyles(); }}
                  >
                    Reset
                  </a>
                  <span className="unseen">.</span>
                  <a
                    href="#"
                    title="Reduce size"
                    onClick={(e) => { e.preventDefault(); changeFontSize(-2); }}
                  >
                    Reduce
                  </a>
                </p>
              </div>
              <h3 className="unseen">Search</h3>
              <div className="mod-languages">
                <ul className="lang-inline">
                  <li className="" dir="ltr">
                    <a href="Empfang">
                      <img src="/lang/de_de.gif" alt="Deutsch" title="Deutsch" />
                    </a>
                  </li>
                  <li className="" dir="ltr">
                    <a href="Accueil">
                      <img src="/lang/fr_fr.gif" alt="Français" title="Français" />
                    </a>
                  </li>
                  <li className="" dir="ltr">
                    <a href="HomeEn">
                      <img src="/lang/en.gif" alt="English (UK)" title="English (UK)" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </header>

          <div id="contentarea2">
            <div id="breadcrumbs">
              <div className="breadcrumbs">
                <span className="showHere">You are here: </span><span>Home</span>
              </div>
            </div>

            <nav className="left1" id="nav">
              <div className="moduletable_menu">
                <h3>Main menu</h3>
                <ul className="nav menu mod-list">
                  <li className="item-103 default current active">
                    <a href="HomeEn">Home</a>
                  </li>
                  <li className="item-105 divider deeper parent">
                    <span className="separator">Introduction</span>
                    <ul className="nav-child unstyled small">
                      <li className="item-106"></li>
                      <li>
                        <a href="MotivationObjectif">Motivations / Objectives</a>
                      </li>
                      <li className="item-107"></li>
                      <li>
                        <a href="TextsOfTheNgo">Texts of the NGO</a>
                      </li>
                      <li className="item-108"></li>
                      <li>
                        <a href="PresidentGermanyAfrica">International president</a>
                      </li>
                    </ul>
                  </li>
                  <li className="item-109"></li>
                  <li>
                    <a href="Membership">Membership</a>
                  </li>
                  <li className="item-110"></li>
                  <li>
                    <a href="Contacts">Contact</a>
                  </li>
                </ul>
              </div>
            </nav>

            <div id="wrapper">
              <div id="main">
                <div id="top">
                  <div className="custom">
                    <div className="LayMotObj">
                      <p className="Stylemotiv">
                        <span style={{ fontFamily: 'verdana,geneva' }}>
                          <strong>
                            The world would be so beautiful, humanity would be so developed, if people accepted each other.
                          </strong>
                        </span>
                      </p>
                      <p className="Stylemotiv">
                        <span style={{ fontFamily: 'verdana,geneva' }}>
                          <strong>
                            The world would be so beautiful, humanity would be so fulfilled, if people didn’t reject each other based on prejudices.
                          </strong>
                        </span>
                      </p>
                      <p className="Stylemotiv">
                        <span style={{ fontFamily: 'verdana,geneva' }}>
                          <strong>
                            The world would be so beautiful, humanity would shine with joy, if the peoples of the world communicated with each other, lived in harmony and joy, and avoided inventing prejudices about others.
                          </strong>
                        </span>
                      </p>
                      <p>
                        <span style={{ fontFamily: 'verdana,geneva' }}>
                          <a href=""> </a>
                          <a href="MotivationObjectif" id="index.php?Item=6">Read the full text ...</a>
                        </span>
                      </p>
                    </div>
                    <p>&nbsp;</p>
                  </div>
                </div>

                <div id="system-message-container"></div>

                <section className="blog-featured">
                  <div className="items-leading">
                    <article className="leading-0">
                      <div className="pull-left"></div>
                      <div className="centrer_accueil">
                        <p style={{ textAlign: 'center' }}>
                          <img src="/images/Home_Germany_africa.png" alt="home deutschland" width="373" height="280" />
                        </p>
                      </div>
                      <div className="item-separator"></div>
                    </article>
                  </div>
                </section>
              </div>
            </div>

            <div id="close">
              <a href="#" onClick={() => auf('right')}>
                <span id="bild">Close info</span>
              </a>
            </div>

            <aside id="right">
              <h2 className="unseen">Additional information</h2>
              <div className="moduletable">
                <h3>Representation</h3>
                <div className="custom">
                  <p>Our Headquarters are in Germany, in BONN.</p>
                  <p>The Representation directs and coordinates in an African Country, the activities of “GERMANY-AFRICA”.</p>
                  <p>The first and only Representation is currently in Ivory Coast.</p>
                  <p>&nbsp;</p>
                  <p>&nbsp;</p>
                </div>
              </div>
            </aside>
          </div>

          <div className="wrap"></div>
        </div>
      </div>

      <div id="footer-outer">
        <div id="footer-sub">
          <footer id="footer"></footer>
        </div>
      </div>
    </div>
  );
};

export default HomeEn;