import React from "react";

const PräsidentDeutschlandAfrika = () => {

  // Definition der fehlenden Funktionen
  const changeFontSize = (size) => {
    const body = document.body;
    const currentSize = parseFloat(window.getComputedStyle(body).fontSize);
    body.style.fontSize = `${currentSize + size}px`;
  };

  const revertStyles = () => {
    document.body.style.fontSize = "16px"; // Standard-Schriftgröße
  };

  const auf = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.style.display = 'none'; // Schließt den Abschnitt
    }
  };
  
  return (
    <div id="shadow">
      <div id="all">
        <div id="back">
          <header id="header">
            <div className="logoheader">
              <h1 id="logo">
                <img
                  src="/images/deustch-afrika-logo.png"
                  alt="Deutschland-Afrika !"
                />
              </h1>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div className="custom">
                        <p>&nbsp;</p>
                        <div>
                          <table>
                            <tbody>
                              <tr>
                                <td
                                  id="nomong"
                                  align="center"
                                  valign="bottom"
                                  bgcolor="transparent"
                                >
                                  <span className="Style2">
                                    Deutschland-Afrika e.V.{" "}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  id="tagline"
                                  align="center"
                                  valign="top"
                                  bgcolor="transparent"
                                >
                                  <span className="Style1">
                                    Internationale NGO Deutschland-Afrika
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <div id="lang"></div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <ul className="skiplinks">
              <li>
                <a href="#main" className="u2">
                  Zum Inhalt springen
                </a>
              </li>
              <li>
                <a href="#nav" className="u2">
                  Zum Hauptmenü und zur Anmeldung
                </a>
              </li>
              <li>
                <a href="#right" className="u2">
                  Zu den zusätzlichen Informationen
                </a>
              </li>
            </ul>
            <h2 className="unseen">Suchnavigation</h2>
            <h3 className="unseen">Navigation</h3>

            <div id="line">
              <div id="fontsize">
                <h3>Schriftgröße</h3>
                <p>
                  <a
                    title="Schriftgröße vergrößern"
                    href=""
                    onClick={(e) => { e.preventDefault(); changeFontSize(2); }}
                  >
                    Vergrößern
                  </a>
                  <span className="unseen">.</span>
                  <a
                    href=""
                    title="Zurück zu den Standardstilen"
                    onClick={(e) => { e.preventDefault(); revertStyles(); }}
                  >
                    Zurücksetzen
                  </a>
                  <span className="unseen">.</span>
                  <a
                    href=""
                    title="Schriftgröße verkleinern"
                    onClick={(e) => { e.preventDefault(); changeFontSize(-2); }}
                  >
                    Verkleinern
                  </a>
                </p>
              </div>
              <h3 className="unseen">Suche</h3>
              <div className="mod-languages">
                <ul className="lang-inline">
                  <li className="" dir="ltr">
                    <a href="PräsidentDeutschlandAfrika">
                      <img src="/lang/de_de.gif" alt="Deutsch" title="Deutsch" />
                    </a>
                  </li>
                  <li className="" dir="ltr">
                    <a href="PresidentAllemagneAfrique">
                      <img src="/lang/fr_fr.gif" alt="Französisch" title="Französisch" />
                    </a>
                  </li>
                  <li className="" dir="ltr">
                    <a href="PresidentGermanyAfrica">
                      <img src="/lang/en.gif" alt="Englisch (UK)" title="Englisch (UK)" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </header>

          <div id="contentarea2">
            <div id="breadcrumbs">
              <div className="breadcrumbs">
                <span className="showHere">Sie sind hier: </span>
                <a href="Empfang" className="pathway">
                Empfangsseite
                </a>{" "}
                <img src="/images/arrow.png" alt="" /> <span>Präsentation</span>{" "}
                <img src="/images/arrow.png" alt="" />{" "}
                <span>Der internationale Präsident</span>
              </div>
            </div>

            <nav className="left1 " id="nav">
              <div className="moduletable_menu">
                <h3>Hauptmenü</h3>
                <ul className="nav menu mod-list">
                  <li className="item-103 default">
                    <a href="Empfang">Empfangsseite</a>
                  </li>
                  <li className="item-105 active divider deeper parent">
                    <span className="separator ">Vorstellung</span>
                    <ul className="nav-child unstyled small">
                      <li className="item-106">
                        <a href="MotivationenZiele">Motivationen / Ziele</a>
                      </li>
                      <li className="item-107">
                        <a href="NGOText">SATZUNG DES VEREINS</a>
                      </li>
                      <li className="item-108 current active">
                        <a href="PräsidentDeutschlandAfrika">Der internationale Präsident</a>
                      </li>
                    </ul>
                  </li>
                  <li className="item-109">
                    <a href="Mitgliedschaft">Mitgliedschaft</a>
                  </li>
                  <li className="item-110">
                    <a href="Kontakt">Kontakt</a>
                  </li>
                </ul>
              </div>
            </nav>

            <div id="wrapper">
              <div id="main">
                <div id="system-message-container"></div>

                <article className="item-page">
                  <div className="pull-left"></div>

                  <div className="centrer">
                    <div style={{ textAlign: "center" }}>
                      <a className="highslide">
                        <img
                          src="/images/photo-Dr-Daba.jpg"
                          alt="Thumbnail image"
                          width="178"
                          height="171"
                        />
                      </a>
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <strong>Professor DABA GBOTA</strong>
                    </div>
                    <div style={{ textAlign: "center", color: "#ff0000"}}>
                      <strong>Doktor Phil</strong>
                    </div>
                
                    <div style={{ textAlign: "center", color: "#ff0000"  }}>
                      <strong>(Spezialitäten: Geschichte und Deutsche Philologie)</strong>
                    </div>
                    <div style={{ textAlign: "center", color: "#0000ff" }}>
                      <strong>Am 24. Januar 2001 : DOKTORGRAD </strong>
                    </div>
                    <div style={{ textAlign: "center", color: "#800080" }}>
                      <strong>ERLANGT AN DER PHILOSOPHISCHEN FAKULTÄT DER </strong>
                    </div>
                    <div style={{ textAlign: "center", color: "#800080" }}>
                      <strong>HEINRICH-HEINE-UNIVERSITÄT DÜSSELDORF</strong>
                    </div>

                    <div style={{ textAlign: "center", color: "#0000ff" }}>
                          <a href="/Articles/THÈSE DE DOCTORAT.pdf"><strong>DOKTORARBEIT</strong></a>     
                    </div>

                    <div style={{ textAlign: "center", color: "#ff0000" }}>
                    <strong>Universitätsdozent; Fachgebiete: Deutsche Geschichte und Deutsche Literatur</strong>
                    </div>
                    <div style={{ textAlign: "center" }}></div>
                    <div style={{ textAlign: "center" }}>
                      <a href="Lebenslauf" target="_self">
                        <span style={{ fontFamily: "Arial", color: "#7030a0", fontSize: "large" }}>
                          <span style={{ color: "#ff0000" }}>LEBENSLAUF</span>
                        </span>
                      </a>
                    </div>
                    <div style={{ textAlign: "center" }}></div>
                  </div>
                </article>
              </div>
            </div>

            <div id="close">
              <a href="#" onClick={() => auf('right')}>
                <span id="bild"> Info Schließen</span>
              </a>
            </div>

            <aside id="right">
              <h2 className="unseen">Weitere Informationen</h2>
              <div className="moduletable">
                <h3>VERTRETUNG</h3>
                <div className="custom">
                  <p>Der Weltsitz des Vereins befindet sich in Deutschland, in BONN.</p>
                  <p>
                  Die Vertretung leitet und koordiniert in einem afrikanischen Land die Aktivitäten von Deutschland-Afrika.
                  </p>
                  <p>Die allererste Vertretung in Afrika befindet sich in der Republik Elfenbeinküste.</p>
                </div>
              </div>
            </aside>
          </div>
        </div>

        <div id="footer-outer">
          <div id="footer-sub">
            <footer id="footer"></footer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PräsidentDeutschlandAfrika;
