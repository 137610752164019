import React from 'react';

const NGOText = () => {

      // Definition der fehlenden Funktionen
      const changeFontSize = (size) => {
        const body = document.body;
        const currentSize = parseFloat(window.getComputedStyle(body).fontSize);
        body.style.fontSize = `${currentSize + size}px`;
      };

      const revertStyles = () => {
        document.body.style.fontSize = "16px"; // Standard-Schriftgröße
      };

      const auf = (id) => {
        const element = document.getElementById(id);
        if (element) {
          element.style.display = 'none'; // Abschnitt schließen
        }
      };

  return (
    <>
      
        <title>SATZUNG DES VEREINS</title>
      
      <body id="shadow">
        <div id="all">
          <div id="back">
            <header id="header">
              <div className="logoheader">
                <h1 id="logo">
                  <img src="/images/deustch-afrika-logo.png" alt="Deutschland-Afrika !" />
                </h1>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div className="custom">
                          <p>&nbsp;</p>
                          <div>
                            <table>
                              <tbody>
                                <tr>
                                  <td id="nomong" align="center" valign="bottom" bgcolor="transparent">
                                    <span className="Style2">Deutschland-Afrika e.V. </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td id="tagline" align="center" valign="top" bgcolor="transparent">
                                    <span className="Style1">Internationale NGO Deutschland-Afrika</span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>
                        <div id="lang"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <ul className="skiplinks">
                <li>
                  <a href="#main" className="u2">
                    Zum Inhalt gehen
                  </a>
                </li>
                <li>
                  <a href="#nav" className="u2">
                    Zum Hauptmenü und zur Identifikation gehen
                  </a>
                </li>
                <li>
                  <a href="#right" className="u2">
                    Zu weiteren Informationen gehen
                  </a>
                </li>
              </ul>
            <div id="line">
              <div id="fontsize">
                <h3>Schriftgröße</h3>
                <p>
                  <a
                    title="Schriftgröße vergrößern"
                    href=""
                    onClick={(e) => { e.preventDefault(); changeFontSize(2); }}
                  >
                    Vergrößern
                  </a>
                  <span className="unseen">.</span>
                  <a
                    href=""
                    title="Zurück zu den Standardstilen"
                    onClick={(e) => { e.preventDefault(); revertStyles(); }}
                  >
                    Zurücksetzen
                  </a>
                  <span className="unseen">.</span>
                  <a
                    href=""
                    title="Schriftgröße verringern"
                    onClick={(e) => { e.preventDefault(); changeFontSize(-2); }}
                  >
                    Verkleinern
                  </a>
                </p>
              </div>
              <h3 className="unseen">Suche</h3>
              <div className="mod-languages">
                <ul className="lang-inline">
                  <li className="" dir="ltr">
                    <a href="NGOText">
                      <img src="/lang/de_de.gif" alt="Deutsch" title="Deutsch" />
                    </a>
                  </li>
                  <li className="" dir="ltr">
                    <a href="TextesAllemagneAfrique">
                      <img src="/lang/fr_fr.gif" alt="Français" title="Français" />
                    </a>
                  </li>
                  <li className="" dir="ltr">
                    <a href="TextsOfTheNgo">
                      <img src="/lang/en.gif" alt="English (UK)" title="English (UK)" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </header>
            <div id="contentarea2">
              <div id="breadcrumbs">
                <div className="breadcrumbs">
                  <span className="showHere">Sie sind hier: </span>
                  <a href="Empfang" className="pathway">
                  Empfangsseite 
                  </a>{' '}
                  <img src="/images/arrow.png" alt="" /> <span>Vorstellung</span>{' '}
                  <img src="/images/arrow.png" alt="" /> <span>SATZUNG DES VEREINS</span>
                </div>
              </div>
              <nav className="left1" id="nav">
                <div className="moduletable_menu">
                  <h3>wichtigstes Menü</h3>
                  <ul className="nav menu mod-list">
                    <li className="item-103 default">
                      <a href="Empfang">Empfangsseite</a>
                    </li>
                    <li className="item-105 active divider deeper parent">
                      <span className="separator">Vorstellung</span>
                      <ul className="nav-child unstyled small">
                        <li className="item-106 ">
                          <a href="MotivationenZiele">Motivationen / Ziele</a>
                        </li>
                        <li className="item-107 current active">
                          <a href="NGOText">SATZUNG DES VEREINS</a>
                        </li>
                        <li className="item-108 ">
                          <a href="PräsidentDeutschlandAfrika">Der internationale Präsident</a>
                        </li>
                      </ul>
                    </li>
                    <li className="item-109">
                      <a href="Mitgliedschaft">Mitgliedschaft</a>
                    </li>
                    <li className="item-110">
                      <a href="Kontakt">Kontakt</a>
                    </li>
                  </ul>
                </div>
              </nav>

               <div id="wrapper">
    <div id="main">
        <div id="system-message-container"></div>
        <article className="item-page">
            <div className="pull-left"></div>
            <div className="styltit">
                <span style={{ fontFamily: 'verdana, geneva', fontSize: '10pt' }}>INHALT</span>
            </div>
            <div className="Laysom">
                <p className="Laysom">
                    <span style={{ fontFamily: 'verdana, geneva', fontSize: '10pt' }}>
                        <a href="#PRÄAMBEL">PRÄAMBEL</a>
                    </span>
                </p>
                <p className="Laysom">
                    <span style={{ fontFamily: 'verdana, geneva', fontSize: '10pt' }}>
                        <a href="#NAME, SITZ, GESCHÄFTSJAHR">NAME, SITZ, GESCHÄFTSJAHR</a>
                    </span>
                </p>
                <p className="Laysom">
                    <span style={{ fontFamily: 'verdana, geneva', fontSize: '10pt' }}>
                        <a href="#ZWECK">ZWECK</a>
                    </span>
                </p>
                <p className="Laysom">
                    <span style={{ fontFamily: 'verdana, geneva', fontSize: '10pt' }}>
                        <a href="#MITGLIED">MITGLIED</a>
                    </span>
                </p>
                <p className="Laysom">
                    <span style={{ fontFamily: 'verdana, geneva', fontSize: '10pt' }}>
                        <a href="#ORGANE">ORGANE</a>
                    </span>
                </p>
                <p className="Laysom">
                    <span style={{ fontFamily: 'verdana, geneva', fontSize: '10pt' }}>
                        <a href="#VERTRETUNG">VERTRETUNG</a>
                    </span>
                </p>
            </div>
            <div className="LayTxt">
                <p className="styltxt" align="center">
                    <span style={{ fontFamily: 'verdana, geneva', fontSize: '10pt', textDecoration: "underline" }}>
                        <div id="PRÄAMBEL">PRÄAMBEL</div>
                    </span>
                </p>
                <h3 className="styltxt" align="justify">
                    <span className="Style1" style={{ fontSize: '10pt', fontFamily: 'verdana, geneva' }}>
                    Die Welt wäre so schön, die Menschheit wäre so entfaltet, wenn Menschen einander akzeptierten.
Die Welt wäre so schön, die Menschheit wäre so aufgeblüht, wenn Menschen nicht einander auf der Basis von Vorurteilen abwiesen.
Die Welt wäre so schön, die Menschheit wäre so freudestrahlend, wenn die Völker der Erde miteinander kommunizierten, in Harmonie und Fröhlichkeit lebten, und wenn die einen vermeiden würden, bezüglich der anderen Vorurteile zu erfinden.
Betrachtend, dass die schweren Probleme der Menschheit einen Teil ihrer Ursachen in der Abweisung des anderen haben ;
betrachtend, dass diese Abweisung sehr oft auf der Basis von Vorurteilen gemacht wird;
davon überzeugt, dass das Kommunikationsdefizit in grossem Masse diesen Vorurteilen zugrunde liegt;
davon überzeugt, dass die Annäherung und die Verständigung zwischen Völkern zur Harmonie auf der Erde und zur Ausstrahlung der ganzen Menschheit beitragen wird,haben wir entschieden, einen Verein zum Beitrag zur Völkerannäherung und Völkerverständigung zu gründen.</span>
                </h3>
                <div>
                    <h3 className="LayTxt" align="justify">
                        <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                        <div id='NAME, SITZ, GESCHÄFTSJAHR'></div>
                            1 : <span style={{ textDecoration: "underline" }}> NAME , SITZ , GESCHÄFTSJAHR</span>
                        </span>
                    </h3>
                    <h3 className="styltxt" align="justify">
                        <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                        Der Verein führt den Namen "Deutschland-Afrika" und soll in das Vereinsregister eingetragen werden; nach der Eintragung führt er den Zusatz "e.V.". Je nach den Umständen darf der Name in andere Sprachen übersetzt werden. Der Verein hat seinen Sitz in Deutschland, in Bonn. Das Geschäftsjahr des Vereins ist das Kalenderjahr.

                        </span>
                    </h3>
                    <h3 className="LayTxt" align="justify">
                        <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                        <div id='ZWECK'></div>
                            2 : <span style={{ textDecoration: "underline" }}>DER ZWECK DES VEREINS</span>
                        </span>
                    </h3>
                    <h3 className="styltxt" align="justify">
                        <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                        Zweck des Vereins ist die Verständigung zwischen Völkern. "Deutschland-Afrika" konzentriert sich aber auf die Verständigung zwischen dem deutschen Volk und den afrikanischen Völkern. Der Verein trägt zur Annäherung des deutschen Volkes und der afrikanischen Völker bei. "Deutschland-Afrika" muss zum Kampf gegen Vorurteile zwischen dem deutschen Volk und den afrikanischen Völkern beitragen.
                        Der Verein verfolgt ausschließlich und unmittelbar gemeinnützige Zwecke im Sinne des Abschnitts "Steuerbegünstigte Zwecke" der Abgabenordnung. Der Satzungszweck wird verwirklicht insbesondere durch folgende Maßnahmen:

                        </span>
                    </h3>
                    <div className="Style1" align="justify">
                        <ol className="Style8">
                            <li>
                                <h3 className="styltxt" align="justify">
                                    <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                                     Veranstaltung von Festivals der deutschen Kultur in afrikanischen Ländern. Einmal jährlich organisiert "Deutschland-Afrika" in einem afrikanischen Land ein Festival der deutschen Kultur. Der Inhalt des Festivals hängt vom Kommunikationsniveau zwischen dem deutschen Volk und dem jeweiligen afrikanischen Land ab.


</span>
                                </h3>
                            </li>
                            <li>
                                <h3 className="styltxt" align="justify">
                                    <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                                     Veranstaltung von Kolloquien im Erziehungsbereich.


</span>
                                </h3>
                            </li>
                            <li>
                                <h3 className="styltxt" align="justify">
                                    <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                                    Organisierung von Radio- und Fernsehsendungen über Deutschland in den afrikanischen Ländern und über die afrikanischen Länder in Deutschland.


</span>
                                </h3>
                            </li>
                            <li>
                                <h3 className="styltxt" align="justify">
                                    <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                                     Veranstaltung von Festivals der afrikanischen Kulturen in Deutschland.

</span>
                                </h3>
                            </li>
                        </ol>
                    </div>
                    <h3 className="styltxt" align="justify">
                        <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                        Bei den Veranstaltungen von "Deutschland-Afrika" in den afrikanischen Ländern sind die Kommunikationssprachen Deutsch und die Amtssprache des Landes, wo die Veranstaltung stattfindet.

"Deutschland-Afrika" ist selbstlos tätig. Der Verein verfolgt nicht in erster Linie eigenwirtschaftliche Zwecke. Mittel des Vereins dürfen nur für die satzungsmäßigen Zwecke verwendet werden. Die Mitglieder erhalten keine Gewinnanteile und in ihrer Eigenschaft als Mitglieder auch keine sonstigen Zuwendungen aus Mitteln des Vereins. Es darf keine Person durch Ausgaben, die dem Zweck des Vereins fremd sind, oder durch unverhältnismäßig hohe Vergütungen begünstigt werden.
Bei Auflösung des Vereins oder bei Wegfall seines bisherigen Zwecks fällt das Vermögen des Vereins an das Goethe-Institut zur Verwendung für die Ausbildung ausländischer Leute.
Alle Inhaber von Vereinsämter sind ehrenamtlich tätig. Jeder Beschluß über die Änderung der Satzung ist vor dessen Anmeldung beim Registergericht dem zuständigen Finanzamt vorzulegen.
 </span>
                    </h3>
                   
                    <h3 className="styltxt" align="justify">
                        <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                        <div id='MITGLIED'></div>
                            3 : <span style={{ textDecoration: "underline" }}> ERWERB DER MITGLIEDSCHAFT

</span>
                        </span>
                    </h3>
                    <h3 className="styltxt" align="justify">
                        <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                        Mitglied von "Deutschland-Afrika" kann jede Person werden, die sich den Zielen des Vereins verbunden fühlt. Über den schriftlichen Aufnahmeantrag entscheidet der Vorstand. Der Antrag soll den Namen, das Alter, den Beruf und die Anschrift des Antragstellers enthalten. Der Antrag kann abgelehnt werden. Gegen den ablehnenden Bescheid des Vorstands, der mit Gründen zu versehen ist, kann der Antragsteller Beschwerde erheben. Die Beschwerde ist innerhalb eines Monats ab Zugang des ablehnenden Bescheids schriftlich beim Vorstand einzulegen. Über die Beschwerde entscheidet die nächste ordentliche Mitgliederversammlung.

</span>
                    </h3>
                    <h3 className="LayTxt" align="justify">
                        <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                            4 : <span style={{ textDecoration: "underline" }}>BEENDIGUNG DER MITGLIEDSCHAFT</span>
                        </span>
                    </h3>
                    <h3 className="styltxt" align="justify">
                        <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                        Die Mitgliedschaft endet
mit dem Tod des Mitglieds;
durch freiwilligen Austritt;
durch Streichung von der Mitgliederliste;
durch Ausschluß aus dem Verein.
Der freiwillige Austritt erfolgt durch eine schritliche Erklärung gegenüber einem Mitglied des Vorstands.
Ein Mitglied kann durch Beschluß des Vorstands von der Mitgliederliste gestrichen werden, wenn es mit der Zahlung des Beitrags im Rückstand ist.
Ein Mitglied kann, wenn es gegen die Vereinsinteressen gröblich verstoßen hat, durch Beschluß des Vorstands aus dem Verein ausgeschlossen werden.                 </span>
                    </h3>
                    
                            
                       
                    <h3 className="LayTxt" align="justify">
                        <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                            5 : <span style={{ textDecoration: "underline" }}>MITGLIEDSBEITRÄGE</span>
                        </span>
                    </h3>
                    <h3 className="styltxt" align="justify">
                        <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                        Von den Mitgliedern werden Beiträge erhoben. Der Beitrag beträgt monatlich 5(fünf) Euro. Die Beiträge jedes Mitglieds betragen jährlich 60(sechzig) Euro. Ehrenmitglieder sind von der Beitragspflicht befreit.
                        </span>
                    </h3>
                    <h3 className="LayTxt" align="justify">
                        <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                        <div id='ORGANE'></div>
                            6 : <span style={{ textDecoration: "underline" }}>ORGANE DES VEREINS</span>
                        </span>
                    </h3>
                    <h3 className="styltxt" align="justify">
                        <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                        Die Organe des Vereins sind:

                        1 – der Vorstand;

                        2 – die Mitgliederversammlung.
                        </span>
                    </h3>
                    
                    <h3 className="LayTxt" align="justify">
                        <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                            7 : <span style={{ textDecoration: "underline" }}>DER VORSTAND

</span>
                        </span>
                    </h3>
                    <h3 className="styltxt" align="justify">
                        <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                        Der Vorstand von "Deutschland-Afrika" besteht aus dem Vorsitzenden und den von ihm ernannten Vorstandsmitgliedern nämlich dem Vize-Vorsitzenden, dem Schatzmeister und dem Generalsekretär. "Deutschland-Afrika" wird gerichtlich und außergerichtlich durch 2 (zwei) Mitglieder des Vorstands, darunter der Vorsitzende oder der Vize-Vorsitzende vertreten. Der Vorsitzende amtiert in Bonn, am Sitz des Vereins.
                        </span>
                    </h3>
                    <h3 className="LayTxt" align="justify">
                        <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                            8 : <span style={{ textDecoration: "underline" }}>: DIE ZUSTÄNDIGKEIT DES VORSTANDS

</span>
                        </span>
                    </h3>
                    <h3 className="styltxt" align="justify">
                        <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                        Der Vorstand ist für die Angelegenheiten von "Deutschland-Afrika" zuständig, soweit sie nicht durch die Satzung einem anderen Vereinsorgan zugewiesen sind. Er hat vor allem folgende Aufgaben:

1 –Vorbereitung der Mitgliederversammlungen und Aufstellung der Tagesordnungen;

2– Einberufung der Mitgliederversammlung;

3– Ausführung der Beschlüsse der Mitgliederversammlung;

4 – Aufstellung eines Haushaltsplans für jedes Geschäftsjahr, Buchführung, Erstellung eines Jahresberichts;

5– Abschluß und Kündigung von Arbeitsverträgen;

6 – Beschlußfassung über Aufnahme, Streichung und Ausschluß von Mitgliedern.
                        </span>
                    </h3>
                        

                    <h3 className="LayTxt" align="justify">
                                <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                                    9 : <span style={{ textDecoration: "underline" }}> AMTSDAUER DES VORSTANDS </span>
                                </span>
                            </h3>
                            <h3 className="styltxt" align="justify">
                                    <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                                    Der Vorsitzende von "Deutschland-Afrika", der dann die Mitglieder des Vorstands ernennt, wird von der Mitgliederversammlung auf die Dauer von 3(drei) Jahren, vom Tage der Wahl an gerechnet, gewählt. Er bleibt bis zur Neuwahl im Amt.
Wählbar ist jedes kommunikatives Vereinsmitglied aus Afrika oder aus Deutschland mit sehr guten Kenntnissen in deutscher Sprache und einem Hochschulabschluß.
Der Vorsitzende ist wiederwählbar.</span>
                                </h3>



                            <h3 className="LayTxt" align="justify">
                                <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                                    10: <span style={{ textDecoration: "underline" }}> BESCHLUSSFASSUNG DES VORSTANDS </span>
                                </span>
                            </h3>

                            <h3 className="styltxt" align="justify">
                                <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                                Der Vorstand fasst seine Beschlüsse im allgemeinen in Vorstandssitzungen, die vom Vorsitzenden, bei dessen Verhinderung vom stellvertretenden Vorsitzenden schriftlich, fernmündlich oder durch Telefax einberufen werden. In jedem Fall ist eine Einberufungsfrist von einer Woche einzuhalten. Der Vorstand ist beschlussfähig, wenn mindestens zwei Drittel des Vorstandes darunter der Vorsitzende anwesend sind. Bei der Beschlussfassung entscheidet die Mehrheit der der abgegebenen Stimmen. Bei Stimmengleichheit entscheidet die Stimme des Leiters der Vorstandssitzung. Die Vorstandssitzung leitet der Vorsitzende, bei dessen Verhinderung der Vize-Vorsitzende. Die Beschlüsse des Vorstands sind zu Beweiszwecken in ein Beschlussbuch einzutragen und vom Sitzungsleiter zu unterschreiben. Die Niederschrift soll Ort und Zeit der Vorstandssitzung, die Namen der Teilnehmer, die gefassten Beschlüsse und das Abstimmungsergebnis enthalten. Die Vereinigung mehrerer Vorstandsämter in einer Person ist unzulässig.
</span>
                            </h3>

                           

                            <h3 className="LayTxt" align="justify">
                                <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                                <div id='VERTRETUNG'></div>
                                    11 : <span style={{ textDecoration: "underline" }}> DIE VERTRETUNGEN </span>
                                </span>
                            </h3>

                            <h3 className="styltxt" align="justify">
                                <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                                Die Vertretung leitet und koordiniert in einem afrikanischen Land die Aktivitäten von Deutschland-Afrika.

                                </span>
                            </h3>

                            <h3 className="LayTxt" align="justify">
                                <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                                    12 : <span style={{ textDecoration: "underline" }}>DIE MITGLIEDERVERSAMMLUNG

</span>
                                </span>
                            </h3>

                            <h3 className="styltxt" align="justify">
                                <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
In der Mitgliederversammlung hat jedes Mitglied – auch ein Ehrenmitglied – eine Stimme. Zur Ausübung des Stimmenrechts kann ein anderes Mitglied schriftlich bevollmächtigt werden. Die Bevollmächtigung ist für jede Mitgliederversammlung gesondert zu erteilen. Ein Mitglied darf jedoch nicht mehr als drei fremde Stimmen vertreten. Die Mitgliederversammlung ist ausschliesslich für folgende Angelegenheiten zuständig:

1 – Genehmigung des vom Vorstand aufgestellten Haushaltsplans für das nächste Geschäftsjahr ;


2–Entgegennahme des Jahresberichts des Vorstands ;


3 – Entlassung des Vorstands ;


4 – Wahl und Abberufung des Vorsitzenden ;


5 – Beschlussfassung über Änderung der Satzung und über die Auflösung des Vereins ;

6 –Beschlussfassung über die Beschwerde gegen die Ablehnung des Aufnahmeantrags sowie über die Berufung gegen einen Ausschliessungsbeschluss des Vorstands ;


7 –Ernennung von Ehrenmitgliedern .

In Angelegenheiten, die in den Zuständigkeitsbereich des Vorstands fallen, kann die Mitgliederversammlung Empfehlungen an den Vorstand beschliessen. Der Vorstand kann seinerseits in Angelegenheiten seines Zuständigkeitsbereichs die Meinung der Mitgliederversammlung einholen.
                                </span>
                            </h3>

                            

                            <h3 className="LayTxt" align="justify">
                                <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                                    13 : <span style={{ textDecoration: "underline" }}>EINBERUFUNG DER MITGLIEDERVERSAMMLUNG

</span>
                                </span>
                            </h3>

                            <h3 className="styltxt" align="justify">
                                <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                                Die ordentliche Mitgliederversammlung findet zweimal im Jahr statt :
                                Eine Sitzung im März und eine im November. Sie wird vom Vorstand unter Einhaltung einer Frist von einem Monat schriftlich unter Angabe der Tagesordnung einberufen. Die Tagesordnung setzt der Vorstand fest.
                                </span>
                            </h3>

                            <h3 className="LayTxt" align="justify">
                                <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                                    14 : <span style={{ textDecoration: "underline" }}> DIE BESCHLUSSFASSUNG DER MITGLIEDERVERSAMMLUNG

</span>
                                </span>
                            </h3>

                            <h3 className="styltxt" align="justify">
                                <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                                Die Mitgliederversammlung wird vom Vorsitzenden, bei dessen Verhinderung, vom Vize-Vorsitzenden oder einem anderen Vorstandsmitglied geleitet. Ist kein Vorstandsmitglied anwesend, bestimmt die Versammlung den Leiter. Bei Wahlen kann die Versammlungsleitung für die Dauer des Wahlganges und der vorhergehenden Diskussion einem Wahlausschuss übertragen werden. Der Protokollführer wird vom Versammlungsleiter bestimmt. Die Art der Abstimmung bestimmt der Versammlungsleiter. Die Abstimmung muss schriftlich durchgeführt werden, wenn ein Drittel der bei der Abstimmung anwesenden stimmberechtigten Mitglieder dies beantragt. Die Mitgliederversammlung ist nicht öffentlich. Der Versammlungsleiter kann Gäste zulassen. Über die Zulassung der Presse, des Rundfunks und des Fernsehens beschliesst die Mitgliederversammlung. Die Mitgliederversammlung ist beschlussfähig, wenn mindestens ein Drittel sämtlicher Vereinsmitglieder anwesend ist. Bei Beschlussunfähigkeit ist der Vorstand verpflichtet, innerhalb von sechs Wochen eine zweite Mitgliederversammlung mit der gleichen Tagesordnung einzuberufen ; diese ist ohne Rücksicht auf die Zahl der erschienenen Mitglieder beschlussfähig. Hierauf ist dies in der Einladung hinzuweisen.
Die Mitgliederversammlung fasst Beschlüsse mit einfacher Mehrheit der abgegebenen gültigen Stimmen. Zur Änderung der Satzung ist jedoch eine Mehrheit von zwei Drittel der abgegebenen gültigen Stimmen erforderlich. Eine Änderung des Zwecks von „Deutchland-Afrika“ kann nur mit Zustimmung aller Mitglieder beschlossen werden. Die schriftliche Zustimmung der in der Mitgliederversammlung nicht erschienenen Mitglieder kann nur innerhalb von vier Wochen gegenüber dem Vorstand erklärt werden. Für Wahlen gilt folgendes :
Hat im ersten Wahlgang kein Kandidat die absolute Mehrheit der abgegebenen gültigen Stimmen erreicht, findet eine Stichwahl zwischen den Kandidaten statt, welche die beiden höchsten Stimmzahlen erreicht haben.
Über die Beschlüsse der Mitgliederversammlung ist ein Protokoll
aufzunehmen, das vom jeweiligen Versammlungsleiter und dem Protokollführer zu unterzeichnen ist. Es soll folgende Feststellungen enthalten : Ort und Zeit der Versammlung, die Person des Versammlungsleiters, des Protokollführers, die Zahl der erschienenen Mitglieder, die Tagesordnung, die einzelnen Abstimmungsergebnisse und die Art der Abstimmung. Bei Satzungsänderungen soll der genaue Wortlaut angegeben werden.
Während der Mitgliederversammlung besteht Rauchverbot.
                                </span>
                            </h3>

                        
                            <h3 className="LayTxt" align="justify">
                                <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                                    15 : <span style={{ textDecoration: "underline" }}> NACHTRÄGLICHE ANTRÄGE ZUR TAGESORDNUNG</span>
                                </span>
                            </h3>

                            <h3 className="styltxt" align="justify">
                                <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                                Jedes Mitglied kann bis spätestens eine Woche vor dem Tag der Mitgliederversammlung beim Vorstand schriftlich beantragen, dass weitere Angelegenheiten nachträglich auf Tagesorordnung gesetzt werden. Der Versammlungsleiter hat zu beginn der Mitgliederversammlung die Tagesordnung entsprechend zu ergänzen. Über Anträge auf Ergänzung der Tagesordnung, die erst in der Mitgliederversammlung gestellt werden, beschliesst die Mitgliederversammlung. Zur Annahme des Antrages ist eine Mehrheit von drei Viertel der abgegebenen gültigen Stimmen erforderlich.
                                </span>
                            </h3>

                            <h3 className="LayTxt" align="justify">
                                <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                                    16 : <span style={{ textDecoration: "underline" }}> AUSSERORDENTLICHE MITGLIEDERVERSAMMLUNG</span>
                                </span>
                            </h3>

                            <h3 className="styltxt" align="justify">
                                <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                                Der Vorstand kann jederzeit eine ausserordentliche Mitgliederversammlung einberufen. Diese muss einberufen werden, wenn das Interesse des Vereins es erfordert oder wenn die Einberufung von zwei Drittel aller Mitglieder schriftlich unter Angabe des Zwecks und der Gründe vom Vorstand verlangt wird. Für die ausserordentliche Mitgliederversammlung gelten die § 12, 13, 14 und 15.
                                </span>
                            </h3>

                            <h3 className="LayTxt" align="justify">
                                <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                                    17 : <span style={{ textDecoration: "underline" }}> BREAKING THE NGO AND WELL-FOUNDED DATE OF TERM

</span>
                                </span>
                            </h3>

                            <h3 className="styltxt" align="justify">
                                <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                                Die Auflösung des Vereins kann nur in einer Mitgliederversammlung mit der im §14 festgestellten Stimmenmehrheit beschlossen werden. Sofern die Mitgliederversammlung nichts anderes beschliesst , sind der Vorsitzende und der Vize-Vorsitzende gemeinsam vertretungsberechtigte Liquidatoren. Bei Auflösung des Vereins fällt das Vermögen des Vereins an das Goethe-Institut zur Verwendung für die Ausbildung ausländischer Leute .
Die vorstehende Satzung wurde in der Gründungsversammlung vom Freitag , dem 12 . Mai 2006 errichtet .
Es folgen die deutlichen Unterschriften der dem Verein und der Gründungsversammlung beigetretenen Personen . </span>
                            </h3>

                      </div>

                  </div>
                </article>
              </div>
            </div>

              <div id="close">
              <a href="#" onClick={() => auf('right')}>
                <span id="bild">Info schließen</span>
              </a>
            </div>
              <aside id="right">
              <h2 className="unseen">Zusätzliche Informationen</h2>
              <div className="moduletable">
                <h3>Vertretung</h3>
                <div className="custom">
                  <p>Unser Hauptsitz befindet sich in Bonn, Deutschland.</p>
                  <p>
                    Die Vertretung leitet und koordiniert in einem afrikanischen Land die Aktivitäten
                    von "Deutschland-Afrika".
                  </p>
                  <p>Die erste und einzige Vertretung befindet sich derzeit in Côte d'Ivoire.</p>
                  <p>&nbsp;</p>
                  <p>&nbsp;</p>
                </div>
              </div>
            </aside>
            </div>
            <div id="footer-outer">
          <div id="footer-sub">
            <footer id="footer"></footer>
          </div>
        </div>
          </div>
        </div>
      </body>
    </>
  );
}

export default NGOText;
