import React from 'react';
import { BrowserRouter as Router, Routes, Route, } from 'react-router-dom';



import Home from './components/Home';
import Accueil from './pages/Accueil';
import MotivationsObjectifs from './pages/MotivationsObjectifs';
import TextesAllemagneAfrique from './pages/TextesAllemagneAfrique';
import PresidentAllemagneAfrique from './pages/PresidentAllemagneAfrique'
import Adhesion from './pages/Adhesion';
import Contact from './pages/Contact';
import CurriculumVitae from './pages/CurriculumVitae';


import HomeEn from './pages/HomeEn';
import Membership from './pages/Membership';
import Contacts from './pages/Contacts';
import MotivationObjectif from './pages/MotivationObjectif';
import PresidentGermanyAfrica from './pages/PresidentGermanyAfrica';
import TextsOfTheNgo from './pages/TextsOfTheNgo';
import VitaeCurriculum from './pages/VitaeCurriculum';


import Empfang from './pages/Empfang';
import Mitgliedschaft from './pages/Mitgliedschaft';
import Kontakt from './pages/Kontakt';
import MotivationenZiele from './pages/MotivationenZiele';
import PräsidentDeutschlandAfrika from './pages/PräsidentDeutschlandAfrika';
import NGOText from './pages/NGOText';
import Lebenslauf from './pages/Lebenslauf';



import './styles/position.css';
import './styles/layout.css';
import './styles/general.css';
import './styles/personal.css';
import './styles/ie7only.css';
import './styles/template.css';
import './language/template.css';



const App = () => {
  return (
    <Router>
      <Routes>
        
        <Route path="/" element={<Home />} />
        <Route path="/Accueil" element={<Accueil />} />
        <Route path="/MotivationsObjectifs" element={<MotivationsObjectifs />} />
        <Route path="/TextesAllemagneAfrique" element={<TextesAllemagneAfrique />} />
        <Route path="/PresidentAllemagneAfrique" element={<PresidentAllemagneAfrique />} />
        <Route path="/Adhesion" element={<Adhesion />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/CurriculumVitae" element={<CurriculumVitae />} />
        
        
        <Route path="/HomeEn" element={<HomeEn />} />
        <Route path="/Membership" element={<Membership />} />
        <Route path="/Contacts" element={<Contacts />} />
        <Route path="/MotivationObjectif" element={<MotivationObjectif />} />
        <Route path="/PresidentGermanyAfrica" element={<PresidentGermanyAfrica />} />
        <Route path="/TextsOfTheNgo" element={<TextsOfTheNgo />} />
        <Route path="/VitaeCurriculum" element={<VitaeCurriculum />} />

       
       <Route path="/Empfang" element={<Empfang />} />
       <Route path="/Mitgliedschaft" element={<Mitgliedschaft />} />
       <Route path="/Kontakt" element={<Kontakt />} />
       <Route path="/MotivationenZiele" element={<MotivationenZiele />} />
       <Route path="/PräsidentDeutschlandAfrika" element={<PräsidentDeutschlandAfrika />} /> 
       <Route path="/NGOText" element={<NGOText />} />
       <Route path="/Lebenslauf" element={<Lebenslauf />} />
       




      </Routes>
    </Router>
  );
};


export default App;