import React from 'react';

const Empfang = () => {
  // Definition der fehlenden Funktionen
  const changeFontSize = (size) => {
    const body = document.body;
    const currentSize = parseFloat(window.getComputedStyle(body).fontSize);
    body.style.fontSize = `${currentSize + size}px`;
  };

  const revertStyles = () => {
    document.body.style.fontSize = "16px"; // Standard-Schriftgröße
  };

  const auf = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.style.display = 'none'; // Abschnitt schließen
    }
  };

  return (
    <div id="shadow">
      <div id="all">
        <div id="back">
          <header id="header">
            <div className="logoheader">
              <h1 id="logo">
                <img src="/images/deustch-afrika-logo.png" alt="Deutschland-Afrika!" />
              </h1>
              <table>
                <tr colspan="2">
                  <td>
                    <div className="custom">
                      <p>&nbsp;</p>
                      <div>
                        <table>
                          <tbody>
                            <tr>
                              <td id="nomong" align="center" valign="bottom" bgcolor="transparent">
                                <span className="Style2">Deutschland-Afrika e.V.</span>
                              </td>
                            </tr>
                            <tr>
                              <td id="tagline" align="center" valign="top" bgcolor="transparent">
                                <span className="Style1">Internationale NGO Deutschland-Afrika</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <div id="lang"></div>
                  </td>
                </tr>
              </table>
            </div>
            <ul className="skiplinks">
              <li>
                <a href="#main" className="u2">Zum Inhalt springen</a>
              </li>
              <li>
                <a href="#nav" className="u2">Zum Hauptmenü und zur Anmeldung</a>
              </li>
              <li>
                <a href="#right" className="u2">Zu den zusätzlichen Informationen</a>
              </li>
            </ul>
            <h2 className="unseen">Suchnavigation</h2>
            <h3 className="unseen">Navigation</h3>

            <div id="line">
              <div id="fontsize">
                <h3>Schriftgröße</h3>
                <p>
                  <a
                    title="Schriftgröße erhöhen"
                    href="#"
                    onClick={(e) => { e.preventDefault(); changeFontSize(2); }}
                  >
                    Erhöhen
                  </a>
                  <span className="unseen">.</span>
                  <a
                    href="#"
                    title="Standard-Stile zurücksetzen"
                    onClick={(e) => { e.preventDefault(); revertStyles(); }}
                  >
                    Zurücksetzen
                  </a>
                  <span className="unseen">.</span>
                  <a
                    href="#"
                    title="Schriftgröße verringern"
                    onClick={(e) => { e.preventDefault(); changeFontSize(-2); }}
                  >
                    Verringern
                  </a>
                </p>
              </div>
              <h3 className="unseen">Suche</h3>
              <div className="mod-languages">
                <ul className="lang-inline">
                  <li className="" dir="ltr">
                    <a href="Empfang">
                      <img src="/lang/de_de.gif" alt="Deutsch" title="Deutsch" />
                    </a>
                  </li>
                  <li className="" dir="ltr">
                    <a href="Accueil">
                      <img src="/lang/fr_fr.gif" alt="Français" title="Français" />
                    </a>
                  </li>
                  <li className="" dir="ltr">
                    <a href="HomeEn">
                      <img src="/lang/en.gif" alt="English (UK)" title="English (UK)" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </header>

          <div id="contentarea2">
            <div id="breadcrumbs">
              <div className="breadcrumbs">
                <span className="showHere">Sie sind hier: </span><span>Startseite</span>
              </div>
            </div>

            <nav className="left1" id="nav">
              <div className="moduletable_menu">
                <h3>wichtigstes Menü</h3>
                <ul className="nav menu mod-list">
                  <li className="item-103 default current active">
                    <a href="Empfang">Empfangsseite</a>
                  </li>
                  <li className="item-105 divider deeper parent">
                    <span className="separator">Vorstellung</span>
                    <ul className="nav-child unstyled small">
                      <li className="item-106"></li>
                      <li>
                        <a href="MotivationenZiele">Motivationen / Ziele</a>
                      </li>
                      <li className="item-107"></li>
                      <li>
                        <a href="NGOText">SATZUNG DES VEREINS</a>
                      </li>
                      <li className="item-108"></li>
                      <li>
                        <a href="PräsidentDeutschlandAfrika">Der internationale Präsident</a>
                      </li>
                    </ul>
                  </li>
                  <li className="item-109"></li>
                  <li>
                    <a href="Mitgliedschaft">Mitgliedschaft</a>
                  </li>
                  <li className="item-110"></li>
                  <li>
                    <a href="Kontakt">Kontakt</a>
                  </li>
                </ul>
              </div>
            </nav>

            <div id="wrapper">
              <div id="main">
                <div id="top">
                  <div className="custom">
                    <div className="LayMotObj">
                      <p className="Stylemotiv">
                        <span style={{ fontFamily: 'verdana,geneva' }}>
                          <strong>
                            Die Welt wäre so schön, die Menschheit wäre so entwickelt, wenn die Menschen einander akzeptieren würden.
                          </strong>
                        </span>
                      </p>
                      <p className="Stylemotiv">
                        <span style={{ fontFamily: 'verdana,geneva' }}>
                          <strong>
                            Die Welt wäre so schön, die Menschheit wäre so erfüllt, wenn die Menschen einander nicht aufgrund von Vorurteilen ablehnen würden.
                          </strong>
                        </span>
                      </p>
                      <p className="Stylemotiv">
                        <span style={{ fontFamily: 'verdana,geneva' }}>
                          <strong>
                            Die Welt wäre so schön, die Menschheit wäre so voller Freude, wenn die Völker der Welt miteinander kommunizieren, in Harmonie und Freude leben und Vorurteile gegeneinander vermeiden würden.
                          </strong>
                        </span>
                      </p>
                      <p>
                        <span style={{ fontFamily: 'verdana,geneva' }}>
                          <a href=""> </a>
                          <a href="MotivationenZiele" id="index.php?Item=6">Den gesamten Text lesen...</a>
                        </span>
                      </p>
                    </div>
                    <p>&nbsp;</p>
                  </div>
                </div>

                <div id="system-message-container"></div>

                <section className="blog-featured">
                  <div className="items-leading">
                    <article className="leading-0">
                      <div className="pull-left"></div>
                      <div className="centrer_accueil">
                        <p style={{ textAlign: 'center' }}>
                          <img src="/images/Empfang_deutschland_afrika.png" alt="startseiteDeutschland" width="373" height="280" />
                        </p>
                      </div>
                      <div className="item-separator"></div>
                    </article>
                  </div>
                </section>
              </div>
            </div>

            <div id="close">
              <a href="#" onClick={() => auf('right')}>
                <span id="bild">Info schließen</span>
              </a>
            </div>

            <aside id="right">
              <h2 className="unseen">Zusätzliche Informationen</h2>
              <div className="moduletable">
                <h3>Vertretung</h3>
                <div className="custom">
                  <p>Unser Hauptsitz befindet sich in Deutschland, in BONN.</p>
                  <p>Die Vertretung leitet und koordiniert in einem afrikanischen Land die Aktivitäten von „DEUTSCHLAND-AFRIKA“.</p>
                  <p>Die erste und bisher einzige Vertretung befindet sich in der Elfenbeinküste.</p>
                  <p>&nbsp;</p>
                  <p>&nbsp;</p>
                </div>
              </div>
            </aside>
          </div>

          <div className="wrap"></div>
        </div>
      </div>

      <div id="footer-outer">
        <div id="footer-sub">
          <footer id="footer"></footer>
        </div>
      </div>
    </div>
  );
};

export default Empfang;
