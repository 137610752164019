import React from 'react';

const MotivationenZiele = () => {

      // Definition der fehlenden Funktionen
      const changeFontSize = (size) => {
        const body = document.body;
        const currentSize = parseFloat(window.getComputedStyle(body).fontSize);
        body.style.fontSize = `${currentSize + size}px`;
      };

      const revertStyles = () => {
        document.body.style.fontSize = "16px"; // Standard-Schriftgröße
      };

      const auf = (id) => {
        const element = document.getElementById(id);
        if (element) {
          element.style.display = 'none'; // Abschnitt schließen
        }
      };

  return (
    <>
      
        <title>Motivationen / Ziele</title>
      
      <body id="shadow">
        <div id="all">
          <div id="back">
            <header id="header">
              <div className="logoheader">
                <h1 id="logo">
                  <img src="/images/deustch-afrika-logo.png" alt="Deutschland-Afrika !" />
                </h1>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div className="custom">
                          <p>&nbsp;</p>
                          <div>
                            <table>
                              <tbody>
                                <tr>
                                  <td id="nomong" align="center" valign="bottom" bgcolor="transparent">
                                    <span className="Style2">Deutschland-Afrika e.V. </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td id="tagline" align="center" valign="top" bgcolor="transparent">
                                    <span className="Style1">Internationale NGO Deutschland-Afrika</span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>
                        <div id="lang"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <ul className="skiplinks">
                <li>
                  <a href="#main" className="u2">
                    Zum Inhalt gehen
                  </a>
                </li>
                <li>
                  <a href="#nav" className="u2">
                    Zum Hauptmenü und zur Identifikation gehen
                  </a>
                </li>
                <li>
                  <a href="#right" className="u2">
                    Zu weiteren Informationen gehen
                  </a>
                </li>
              </ul>
            <div id="line">
              <div id="fontsize">
                <h3>Schriftgröße</h3>
                <p>
                  <a
                    title="Schriftgröße vergrößern"
                    href=""
                    onClick={(e) => { e.preventDefault(); changeFontSize(2); }}
                  >
                    Vergrößern
                  </a>
                  <span className="unseen">.</span>
                  <a
                    href=""
                    title="Zurück zu den Standardstilen"
                    onClick={(e) => { e.preventDefault(); revertStyles(); }}
                  >
                    Zurücksetzen
                  </a>
                  <span className="unseen">.</span>
                  <a
                    href=""
                    title="Schriftgröße verringern"
                    onClick={(e) => { e.preventDefault(); changeFontSize(-2); }}
                  >
                    Verkleinern
                  </a>
                </p>
              </div>
              <h3 className="unseen">Suche</h3>
              <div className="mod-languages">
                <ul className="lang-inline">
                  <li className="" dir="ltr">
                    <a href="MotivationenZiele">
                      <img src="/lang/de_de.gif" alt="Deutsch" title="Deutsch" />
                    </a>
                  </li>
                  <li className="" dir="ltr">
                    <a href="MotivationsObjectifs">
                      <img src="/lang/fr_fr.gif" alt="Français" title="Français" />
                    </a>
                  </li>
                  <li className="" dir="ltr">
                    <a href="MotivationObjectif">
                      <img src="/lang/en.gif" alt="English (UK)" title="English (UK)" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </header>
            <div id="contentarea2">
              <div id="breadcrumbs">
                <div className="breadcrumbs">
                  <span className="showHere">Sie sind hier: </span>
                  <a href="Empfang" className="pathway">
                  Empfangsseite
                  </a>{' '}
                  <img src="/images/arrow.png" alt="" /> <span>Präsentation</span>{' '}
                  <img src="/images/arrow.png" alt="" /> <span>Motivationen / Ziele</span>
                </div>
              </div>
              <nav className="left1" id="nav">
                <div className="moduletable_menu">
                  <h3>Hauptmenü</h3>
                  <ul className="nav menu mod-list">
                    <li className="item-103 default">
                      <a href="Empfang">Empfangsseite</a>
                    </li>
                    <li className="item-105 active divider deeper parent">
                      <span className="separator">Präsentation</span>
                      <ul className="nav-child unstyled small">
                        <li className="item-106 current active">
                          <a href="MotivationenZiele">Motivationen / Ziele</a>
                        </li>
                        <li className="item-107">
                          <a href="NGOText">SATZUNG DES VEREINS</a>
                        </li>
                        <li className="item-108">
                          <a href="PräsidentDeutschlandAfrika">Der internationale Präsident</a>
                        </li>
                      </ul>
                    </li>
                    <li className="item-109">
                      <a href="Mitgliedschaft">Mitgliedschaft</a>
                    </li>
                    <li className="item-110">
                      <a href="Kontakt">Kontakt</a>
                    </li>
                  </ul>
                </div>
              </nav>
              <div id="wrapper">
                <div id="main">
                  <div id="system-message-container"></div>
                  <article className="item-page">
                    <p className="Stylemotiv">
                      Angesichts der schweren Probleme der Menschheit, die teilweise ihre Ursachen in der Ablehnung des Anderen haben.
                    </p>
                    <p className="Stylemotiv">
                      Angesichts der Tatsache, dass diese Ablehnung oft auf Vorurteilen beruht;
                    </p>
                    <p className="Stylemotiv">
                      Überzeugt, dass der Mangel an Kommunikation zu diesen Vorurteilen beiträgt;
                    </p>
                    <p className="Stylemotiv">
                      Überzeugt, dass die Annäherung und Verständigung zwischen den Völkern zur Harmonie auf der Erde und zur Förderung der gesamten Menschheit beitragen können, haben wir beschlossen, eine NGO zu gründen, die sich für die Annäherung und Verständigung zwischen den Völkern einsetzt.
                    </p>
                    <div className="Layer4">
                      <span className="Style3" style={{ fontFamily: "verdana, geneva", fontSize: "10pt" }}>
                        UNSERE ZIELE
                      </span>
                    </div>
                    <div>
                      <span style={{ fontFamily: "verdana, geneva", fontSize: "10pt" }}>&nbsp;</span>
                    </div>
                    <div className="LayMotObj">
                      <p className="Stylemotiv" align="left">
                        Das Ziel der NGO ist die Verständigung zwischen den Völkern. Aber "Deutschland-Afrika" konzentriert sich auf die Verständigung zwischen dem deutschen Volk und den afrikanischen Völkern. Die NGO trägt zur Annäherung des deutschen Volkes und der afrikanischen Völker bei. "Deutschland-Afrika" soll zur Bekämpfung von Vorurteilen zwischen dem deutschen Volk und den afrikanischen Völkern beitragen.
                      </p>
                      <p className="Stylemotiv" align="left">
                        Die NGO verfolgt ausschließlich und direkt gemeinnützige Ziele im Sinne des Paragraphen „Steuerlich begünstigte Ziele“ der steuerrechtlichen Bestimmungen. Das Ziel wird vor allem durch die folgenden Maßnahmen erreicht:
                      </p>
                      <div align="left">
                        <ol className="Stylemotiv">
                          <li className="Stylemotiv">
                            Organisation von Festivals der deutschen Kultur in afrikanischen Ländern. Einmal im Jahr organisiert "Deutschland-Afrika" in einem afrikanischen Land ein Festival der deutschen Kultur. Der Inhalt des Festivals hängt vom Kommunikationsniveau zwischen dem deutschen Volk und dem betreffenden afrikanischen Land ab;
                          </li>
                          <li className="Stylemotiv">Organisation von Konferenzen im Bereich Bildung;</li>
                          <li className="Stylemotiv">
                            Organisation von Radio- oder Fernsehsendungen über Deutschland in afrikanischen Ländern und über afrikanische Länder in Deutschland;
                          </li>
                          <li className="Stylemotiv">Organisation von Festivals der afrikanischen Kultur in Deutschland.</li>
                        </ol>
                      </div>
                      <p className="Stylemotiv" align="left">
                        Während der Veranstaltungen von "DEUTSCHLAND-AFRIKA" in afrikanischen Ländern werden Deutsch und die offizielle Sprache des Gastgeberlandes verwendet.<br />
                        <br /> "DEUTSCHLAND-AFRIKA" ist ehrenamtlich. Die NGO verfolgt keine kommerziellen Ziele. Die Mittel der NGO dürfen nur für die in den Satzungen vorgesehenen Ziele verwendet werden. Die Mitglieder erhalten keine Gewinne und erhalten in ihrer Funktion als Mitglieder keine besonderen Zuschüsse aus den Mitteln der NGO. Es ist nicht erlaubt, Ausgaben zu tätigen, die nicht den Zielen der NGO entsprechen oder unangemessene Entschädigungen zu leisten, um eine Person zu begünstigen.
                      </p>
                      <p className="Stylemotiv" align="left">
                        Im Falle der Auflösung der NGO oder des Verzichts auf das Ziel, für das sie gegründet wurde, gehen die Mittel der NGO an das GOETHE-Institut, um sie für die Ausbildung von Personen außerhalb Deutschlands zu verwenden.
                      </p>
                      <p className="Stylemotiv" align="left">
                        Alle, die die Organe der NGO leiten, haben nur eine ehrenamtliche Funktion. Jede Entscheidung über eine Änderung der Satzung muss dem zuständigen Finanzamt vorgelegt werden, bevor sie dem Gericht mitgeteilt wird.
                      </p>
                    </div>
                    <p>
                      <span style={{ fontFamily: "verdana, geneva", fontSize: "10pt" }}>&nbsp;</span>
                    </p>
                    <div className="zoom-block" id="images"></div>
                  </article>
                </div>
              </div>
              <div id="close">
              <a href="#" onClick={() => auf('right')}>
                <span id="bild">Infos schließen</span>
              </a>
            </div>
              <aside id="right">
              <h2 className="unseen">Zusätzliche Informationen</h2>
              <div className="moduletable">
                <h3>Vertretung</h3>
                <div className="custom">
                  <p>Unser Hauptsitz befindet sich in Bonn, Deutschland.</p>
                  <p>
                    Die Vertretung leitet und koordiniert in einem afrikanischen Land die Aktivitäten
                    von "Deutschland-Afrika".
                  </p>
                  <p>Die erste und einzige Vertretung befindet sich derzeit in Côte d'Ivoire.</p>
                  <p>&nbsp;</p>
                  <p>&nbsp;</p>
                </div>
              </div>
            </aside>
            </div>
            <div id="footer-outer">
          <div id="footer-sub">
            <footer id="footer"></footer>
          </div>
        </div>
          </div>
        </div>
      </body>
    </>
  );
}

export default MotivationenZiele;
