import React from 'react';

const Lebenslauf = () => {

  // Définition des fonctions manquantes
  const changeFontSize = (size) => {
    const body = document.body;
    const currentSize = parseFloat(window.getComputedStyle(body).fontSize);
    body.style.fontSize = `${currentSize + size}px`;
  };

  const revertStyles = () => {
    document.body.style.fontSize = "16px"; // Taille de police par défaut
  };

  const auf = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.style.display = 'none'; // Ferme la section
    }
  };
 
      return (
          <div id="shadow">
              <div id="all">
                  <div id="back">
                      <header id="header">
                          <div className="logoheader">
                              <h1 id="logo">
                                  <img src="/images/deustch-afrika-logo.png" alt="Deutschland-Afrika!" />
                              </h1>
                              <table>
                                  <tbody>
                                      <tr>
                                          <td>
                                              <div className="custom">
                                                  <p>&nbsp;</p>
                                                  <div>
                                                      <table>
                                                          <tbody>
                                                              <tr>
                                                                  <td id="nomong" align="center" valign="bottom" bgcolor="transparent">
                                                                      <span className="Style2">Deutschland-Afrika e.V.</span>
                                                                  </td>
                                                              </tr>
                                                              <tr>
                                                                  <td id="tagline" align="center" valign="top" bgcolor="transparent">
                                                                      <span className="Style1">ONG Internationale Allemagne-Afrique</span>
                                                                  </td>
                                                              </tr>
                                                          </tbody>
                                                      </table>
                                                  </div>
                                              </div>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td>
                                              <div id="lang"></div>
                                          </td>
                                      </tr>
                                  </tbody>
                              </table>
                          </div>
                          <ul className="skiplinks">
              <li>
                <a href="#main" className="u2">Aller au contenu</a>
              </li>
              <li>
                <a href="#nav" className="u2">Aller au menu principal et à l'identification</a>
              </li>
              <li>
                <a href="#right" className="u2">Aller aux informations additionnelles</a>
              </li>
            </ul>
            <h2 className="unseen">Navigation de recherche</h2>
            <h3 className="unseen">Navigation</h3>

            <div id="line">
              <div id="fontsize">
                <h3>Taille de la police</h3>
                <p>
                  <a
                    title="Augmenter la taille"
                    href="#"
                    onClick={(e) => { e.preventDefault(); changeFontSize(2); }}
                  >
                    Augmenter
                  </a>
                  <span className="unseen">.</span>
                  <a
                    href="#"
                    title="Revenir aux styles par défaut"
                    onClick={(e) => { e.preventDefault(); revertStyles(); }}
                  >
                    Réinitialiser
                  </a>
                  <span className="unseen">.</span>
                  <a
                    href="#"
                    title="Réduire la taille"
                    onClick={(e) => { e.preventDefault(); changeFontSize(-2); }}
                  >
                    Diminuer
                  </a>
                </p>
              </div>
              <h3 className="unseen">Recherche</h3>
              <div className="mod-languages">
                <ul className="lang-inline">
                  <li className="" dir="ltr">
                    <a href="Lebenslauf">
                      <img src="/lang/de_de.gif" alt="Deutsch" title="Deutsch" />
                    </a>
                  </li>
                  <li className="" dir="ltr">
                    <a href="CurriculumVitae">
                      <img src="/lang/fr_fr.gif" alt="Français" title="Français" />
                    </a>
                  </li>
                  <li className="" dir="ltr">
                    <a href="VitaeCurriculum">
                      <img src="/lang/en.gif" alt="English (UK)" title="English (UK)" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
                      </header>
                      <div id="contentarea2">
                          <div id="breadcrumbs">
                              <div className="breadcrumbs">
                                  <span className="showHere">Startseite</span>
                                  <a href="Empfang" className="pathway">Vorstellung</a>
                                  <img src="/images/arrow.png" alt="" />
                                  <span>Der internationale Präsident</span>
                                  <img src="/images/arrow.png" alt="" />
                                  <span>LEBENSLAUF PROF. DR. DABA, GBOTA</span>
                              </div>
                          </div>
                          <nav className="left1" id="nav">
                <div className="moduletable_menu">
                  <h3>wichtigstes Menü</h3>
                  <ul className="nav menu mod-list">
                    <li className="item-103 default">
                      <a href="Empfang">Empfangsseite</a>
                    </li>
                    <li className="item-105 active divider deeper parent">
                      <span className="separator">Vorstellung</span>
                      <ul className="nav-child unstyled small">
                        <li className="item-106 ">
                          <a href="MotivationenZiele">Motivationen / Ziele</a>
                        </li>
                        <li className="item-107 ">
                          <a href="NGOText">SATZUNG DES VEREINS</a>
                        </li>
                        <li className="item-108 current active">
                          <a href="PräsidentDeutschlandAfrika">Der internationale Präsident</a>
                        </li>
                      </ul>
                    </li>
                    <li className="item-109">
                      <a href="Mitgliedschaft">Mitgliedschaft</a>
                    </li>
                    <li className="item-110">
                      <a href="Kontakt">Kontakt</a>
                    </li>
                  </ul>
                </div>
              </nav>
                          <div id="wrapper">
                              <div id="main">
                                  <article className="item-page"  align="justify">

                                  <p style={{ textAlign: 'center' }}>
                                          <strong>BILANZ</strong>
                                      </p>

                                      <p style={{ textAlign: 'center' }}  >
                                          <strong> 1. Der DAAD hat meine Studien in Deutschland wirklich finanziert. Sowohl an der Universität TÜBINGEN (im Jahre 1993) als auch an der HEINRICH-HEINE-UNIVERSITÄT DÜSSELDORF (vom Jahre 1996 bis zum Jahre 2001) hat der DEUTSCHE AKADEMISCHE AUSTAUSCHDIENST (DAAD) alle meine Studien in Deutschland finanziert. Irrtümlicherweise wurde mein Vorname geschrieben : "GBOTHA". Ich verstehe nicht warum. Zu jener Zeit trug ich den Familiennamen "DABO". In den Akten vom DAAD heisse ich deshalb "GBOTHA DABO". Aber dem Beschluß des Amtsgerichts der Stadt LAKOTA (in der Republik ELFENBEINKÜSTE) vom 17.April 2007 gemäß trage ich nunmehr den Familiennamen DABA. Ich heisse also DABA, GBOTA. Als "GBOTHA DABO" hatte ich beim DAAD folgende Daten :

Zeichen:413/sn-4-pa  ;  Tel.-Durchwahl:882-391  ;  Datum:15.03.1999  ;  zuständige/r Sachbearbeiter/in:Gabi Parmentier-Dienst  ;  Referat:413  ;  PKZ:A/99/07448  ;  i.A. Siegrun Hansen. In DÜSSELDORF habe ich GESCHICHTE UND DEUTSCHE PHILOLOGIE studiert. Ich danke Ihnen. Dr. DABA, GBOTA</strong>
                                      </p>

                                      <p style={{ textAlign: 'center' }}>
                                          <strong>DIE DATEN VON Prof. Dr. DABA IN DEN AKTEN VOM DAAD</strong>
                                      </p>

                                      <p style={{ textAlign: 'center' }}>
                                          <strong>2. LEBENSLAUF :</strong>
                                      </p>
                                      <p style={{ textAlign: 'center' }}>
                                          <img src="/images/photo-Dr-Daba.jpg" alt="photo-Dr-Daba" width="198" height="236" />
                                      </p>
                                      <p style={{ textAlign: 'center' }}>
                                          <strong>PROF. DR. DABA , GBOTA</strong>
                                      </p>

                                      <p style={{ textAlign: 'center' }}>
                                      <strong><span style={{ color: '#ff0000' }}> Doktor Phil</span></strong>
                                      </p>
                                     
                                      
                                      <p style={{ textAlign: 'center' }}>
                                          <strong><span style={{ color: '#ff0000'  }}>(Spezialitäten: Geschichte und Deutsche Philologie)</span></strong>
                                      </p>
                                      <p style={{ textAlign: 'center' }}>
                                          <strong><span style={{ color: '#0000ff' }}>Am 24. Januar 2001 : DOKTORGRAD</span></strong>
                                      </p>
                                      <p style={{ textAlign: 'center' }}>
                                          <strong><span style={{ color: '#800080' }}>ERLANGT AN DER PHILOSOPHISCHEN FAKULTÄT DER</span></strong>
                                      </p>
                                      <p style={{ textAlign: 'center' }}>
                                          <strong><span style={{ color: '#800080' }}>HEINRICH-HEINE-UNIVERSITÄT DÜSSELDORF</span></strong>
                                      </p>
                                      <p><strong>  Ich heiße DABA , GBOTA .

Ich bin am 01.Juli 1960 in Dakouritrohoin bei LAKOTA in der ELFENBEINKÜSTE geboren. Mein Vater starb 1967. Ich wurde deshalb nicht früh genug in die Schule geschickt.

Schuljahr 1968-1969: Beginn der Grundschule an der öffentlichen Grundschule Guéyo

Infolge eines im Jahre 1974 am Standesamt LAKOTA in meinem Familiennamen begangenen Tippfehlers habe ich vom Jahre 1974 bis zum Jahre 2007 unter dem Namen "DABO",GBOTA gelebt. Dem Beschluss Nr18/7/STL/306 vom 17.April 2007 des Amtsgerichts der Stadt LAKOTA gemäss habe ich meinen natürlichen Namen DABA GBOTA wieder aufgenommen .

Juni 1974: Zertifikat der Grundschule (C.E.P.E) an der öffentlichen Grundschule Guéyo

Juni 1975: Abschlussprüfung von der Grundschule zum "Collège" (Entrée en 6ème) an der öffentlichen Grundschule Guéyo

Schuljahr 1975-1976: Beginn der Sekundarschule am "Collège" für allgemeine Ausbildung Sassandra (CEG de Sassandra)

Juni 1979: Abschlussprüfung vom "Collège" (B.E.P.C) am CEG Sassandra

Schuljahr 1979-1980: Beginn der Oberstufe der Sekundarschule (Oberstufe des Gymnasiums) am Klassischen und Modernen Gymnasium 1 Daloa (Lycée 1 de Daloa)

Juni 1982: ABITUR A4 (Philosophie, Literatur, Sprachen) am Lycée 1 Daloa

Akademisches Jahr 1982-1983: Beginn der akademischen Ausbildung an der Deutsch-Abteilung der Pädagogischen Hochschule Abidjan (ENS, Département d’Allemand)

Von Juli bis September 1984: Sprachaufenthalt in Deutschland (Goethe-Institut München), denn ich war einer der sechs besten Studenten des Zweiten Jahrgangs der Deutsch-Abteilung der Pädagogischen Hochschule.

Akademisches Jahr 1984-1985: Ich war einer der zehn besten Studenten der Deutsch-Abteilung, die nur ein akademisches Jahr die Deutsch-Abteilung der Universität Cocody besuchen durften, um die "LICENCE" abzulegen. Ich habe am Ende des Jahres (im September 1985) die "LICENCE" bestanden.

Akademisches 1985-1986: Rückkehr zur Pädagogischen Hochschule, um die pädagogische Ausbildung fortzusetzen; Theoretisches Zertifikat zur Lehrtätigkeit (C.A.P.E.S Théorique)

September 1987: Freiwillige private Lehrprobe am Eduard Spranger Gymnasium Bernhausen (FILDERSTADT 1 in Deutschland)

Juni 1988: Ende der pädagogischen Ausbildung: Praktisches Zertifikat zur Lehrtätigkeit für das Fach DEUTSCH bestanden an der Pädagogischen Hochschule ABIDJAN (C.A.P.E.S Pratique d’Allemand à l’ENS)

Oktober 1988: Deutschlehrer für die Oberstufe des Gymnasiums am Modernen Gymnasium Adzopé

Oktober 1991: Deutschlehrer für die Oberstufe des Gymnasiums am Gemeindegymnasium KOUMASSI (ABIDJAN)

Von Mai bis Juli 1993: Universität Tübingen in Deutschland, Institut für Politikwissenschaft : Forschung zur Maîtrise-Arbeit ; DAAD-Stipendiat

Februar 1996 : Ich habe die "MAÎTRISE" bestanden. Titel der Maîtrise-Arbeit : "Der Wandel der Politik der SED gegenüber der Bundesrepublik Deutschland in den 80er Jahren"

Ab Oktober 1996: Am Historischen Seminar der Philosophischen Fakultät der Heinrich-Heine-Universität Düsseldorf für die Promotion; DAAD-Stipendiat

Mai 2000: Deutscher Magister im Fach GESCHICHTE an der Universität Düsseldorf; Titel der Magister-Arbeit : "Die Entwicklungspolitik der EG bis zur ersten Konvention von Jaunde 1963" ; DAAD-Stipendiat

Am 24. Januar 2001: Doktorprüfung bestanden; DOKTORGRAD erlangt; Spézialitäten: Geschichte und Deutsche Philologie; Universität Düsseldorf; DOKTORARBEIT : "Die Politik Deutschlands und Frankreichs bei der Aushandlung der Verträge von Jaunde bis Lomé-III" ; DAAD-Stipendiat

Am 07. Februar 2002: angenommen vom Ministerium der ELFENBEINKÜSTE für Akademisches als Universitäts-Dozent

Ab dem 15. April 2002: tätig an der Deutsch-Abteilung der Universität BOUAKE als Universitäts-Dozent für Deutsche Geschichte und Deutsche Literatur

Von Juli 2004 bis August 2006 war ich nochmals in Deutschland, aber diesmal in der Stadt Wipperfürth. Während dieses Aufenthalts habe ich im November und Dezember 2005 als Journalist an der Französisch-Abteilung der DEUTSCHEN WELLE in Bonn gearbeitet.

Am 04. Dezember 2006 war ich wieder im Dienst an der Universität BOUAKE, die seit Ende 2002 in ABIDJAN arbeitet .

Ich heiratete am Freitag, dem 03. August 2007 legal am Rathaus Cocody in ABIDJAN. Ich bin Vater von vier Kindern .

Ich bin der Weltvorsitzende des Internationalen Vereins Deutschland-Afrika, der seinen Weltsitz in der Stadt Bonn in Deutschland hat.

Ende Februar 2012 : Die Universität BOUAKÉ arbeitet wieder in der Stadet BOUAKÉ.

Anfang März 2012 : Wegen des Vereins und meiner wissenschaftlichen Recherchen pendle ich seit Anfang März 2012 zwischen Deutschland und Afrika.

Am 17. Juli 2018 erlangte ich den Grad „MAÎTRE-ASSISTANT“ (Spezialität : DEUTSCHE ZIVILISATION).

Am 28. Juli 2023 erlangte ich den Grad „MAÎTRE DE CONFÉRENCES“ [„PROFESSOR“] (Spezialität : DEUTSCHE ZIVIL/ISATION).

Ich bin Ivorer.

Ich danke Ihnen. Prof. Dr. DABA , GBOTA.    </strong></p>
                                      <p style={{ textAlign: 'center' }}>
                                          <strong>PUBLIKATIONEN</strong>
                                      </p>
                                      <p>
                                          <strong>1. „DABO“ <i>schrieb, hieβ er noch „DABO“  ,  daher DABO GBOTA, Die Politik Deutschlands und Frankreichs bei der Aushandlung der Verträge von Jaunde bis Lomé-III, Institut d’Histoire de l’Université de Düsseldorf, Allemagne), 2000, Düsseldorf, Universität, Dissertation, Katalog der Deutschen Nationalbibliothek, Signatur : H 2001 A 1726, Bereitstellung in Frankfurt (en ALLEMAGNE), (Thèse de Doctorat Unique, Institut d’Histoire de l’Université de Düsseldorf, Allemagne), 2000, Düsseldorf, Universität, Dissertation, Katalog der Deutschen Nationalbibliothek, Signatur : H 2001 A 1726, Bereitstellung in Frankfurt (en ALLEMAGNE), Signatur : H 2001 A 1726, Bereitstellung in Leipzig (en ALLEMAGNE).</i></strong>
                                      </p>

                                      <p>
                                            <i>
                                                 <a href="/Articles/article_non_indexe18_17.pdf" > 2. DABA GBOTA, Willy Brandt, le Tiers-Monde et l’Internationale Socialiste, in LETTRES D’IVOIRE N°22, Université Alassane Ouattara, Bouaké, Côte d’Ivoire, Juin 2016, de la page 287 à a page 297, ISSN 1991-8666.</a> 
                                            </i>     
                                      </p>


                                      <p>
                                            <i>
                                                 <a href="/Articles/article_non_indexe18_19.pdf" > 3. DABA GBOTA, Willy Brandt et le rapprochement entre l’Allemagne de l’Est et l’Allemagne de l’Ouest, in LETTRES D’IVOIRE N°26, Université Alassane Ouattara, Bouaké, Côte d’Ivoire, Décembre 2017, de la page 377 à la page 387, ISSN 1991-8666.</a> 
                                            </i>     
                                      </p>


                                      <p>
                                            <i>
                                                 <a href="/Articles/article_non_indexe18_20.pdf"  >4. DABA GBOTA, Die deutsche AKP-Politik ab 1975, in GEVOIX (GESTE ET VOIX) N°26, Université d’Abomey-Calavi, Bénin, Décembre 2017, de la page 58 à la page 69, ISSN 1840-572X.</a> 
                                            </i>     
                                      </p>


                                      <p>
                                            <i>
                                                 <a href="/Articles/article_non_indexe18_18.pdf"  > 5. DABA GBOTA, Deutschland in der Problematik des Eintritts Großbritanniens in die Europäische Wirtschaftsgemeinschaft, in GERMIVOIRE N°7, Numéro Spécial, in GERMIVOIRE (Revue Scientifique en Ligne) N°7, Numéro Spécial, Université Félix Houphouët-Boigny, Côte d’Ivoire, Décembre 2017, de la page 126 à la page 140, ISSN 2411-6750.</a> 
                                            </i>     
                                      </p>


                                      <p>
                                            <i>
                                                 <a href="/Articles/LE_RWANDA_ET_L'ALLEMAGNE _FEDERALE_AUTOUR_DE_1962.pdf" > 6. DABA GBOTA, Le Rwanda et l’Allemagne Fédérale autour de 1962, in N’ZASSA N°1, Revue Scientifique des Lettres, Langues et Arts, Littératures et Civilisations, Sciences Humaines et Sociales, Communication, Numéro 1, Juin 2019, UFR Communication, Milieu et Société, Université Alassane Ouattara, Bouaké, (Côte d’Ivoire), de la page 10 à la page 20, ISSN 2706-5405 , Indexation : ZDB (Zeitschriften Datenbank) : https://zdb-katalog.de /title.xhtml?idn= 1251430449 &view=full .</a> 
                                            </i>     
                                      </p>


                                      <p>
                                            <i>
                                                 <a href="/Articles/article_indexe18_7.pdf"> 7. DABA GBOTA, Le Cameroun et l’Allemagne : de la colonisation à la période postcoloniale, juste après 1960, des relations en mutation, in GERMIVOIRE 11/2019, GERMIVIRE (Revue Scientifique en Ligne) , Université Félix Houphouët-Boigny, Côte d’Ivoire, Numéro 11/2019, de la page 118 à la page 136, ISSN 2411-6750.</a> 
                                            </i>     
                                      </p>


                                      <p>
                                            <i>
                                                 <a href="/Articles/article_non_indexe18_6.pdf" > 8. DABA GBOTA,Die Elfenbeinküste und die Bundesrepublik Deutschland um 1960, in LETTRES D’IVOIRE N°30, LETTRES D’IVOIRE, Revue Scientifique de Littératures, Langues et Sciences Humaines, Littératures et Langues, Numéro 30, Université Alassane Ouattara, Décembre 2019, de la page 273 bis à la page 283, ISSN 1991-8666.</a> 
                                            </i>     
                                      </p>


                                      <p>
                                            <i>
                                                 <a href="/Articles/article_non_indexe18_7.pdf" > 9. DABA GBOTA, Le Dahomey et l’Allemagne de l’Ouest autour de 1960, in GEVOIX (GESTE ET VOIX) N°30, Ge Voix (GESTE ET VOIX), Revue Scientifique, Université d’Abomey-Calavi, Benin, Numéro 30, Décembre 2019, de la page 178 à la page 189, ISSN 1840-572 X.</a> 
                                            </i>     
                                      </p>


                                      <p>
                                            <i>
                                                 <a href="/Articles/article_indexe18_8.pdf"> 10. DABA GBOTA, Citoyenneté et mondialisation, in N’ZASSA N°3, www.nzassa-revue.net, N’ZASSA, Revue Scientifique des Lettres, Langues et Arts, Littératures et Civilisations, Sciences Humaines et Sociales, Communication, Numéro 3, Juin 2020, UFR Communication, Milieu et Société, Université Alassane Ouattara, Bouaké, (Côte d’Ivoire), de la page15 à la page 25, ISSN 2706-5405 , Indexation : ZDB (Zeitschriften Datenbank) : https://zdb-katalog.de /title.xhtml?idn= 1251430449 &view=full .</a> 
                                            </i>     
                                      </p>


                                      <p>
                                            <i>
                                                 <a href="/Articles/article_non_indexe18_8.pdf" >11. DABA GBOTA, Le Sénégal et l’Allemagne de l’Ouest autour de 1960, in GEVOIX (GESTE ET VOIX) N°31, Ge Voix (GESTE ET VOIX), Revue Scientifique, Université d’Abomey-Calavi, Benin, Numéro 31, Juin 2020, de la page 230 à la page 245, ISSN 1840-572 X.</a> 
                                            </i>     
                                      </p>


                                      <p>
                                            <i>
                                                 <a href="/Articles/article_non_indexe18_10.pdf" > 12. DABA GBOTA, Ghana und West-Deutschland bis kurz nach 1957, in THE JOURNAL OF AFRICAN AND POSTCOLONIAL STUDIES, Issue Number 2, July 2020 , Book two, THE JOURNAL OF AFRICAN AND POSTCOLONIAL STUDIES, Migration, Literature and Society, Issue Number 2, July 2020, BOOK TWO: OFF CONFERENCE ARTICLES, ARCIV, FLSH, CHEIKH ANTA DIOP UNIVERSITY, DAKAR, SENEAL, de la page 305 à la page 326, ISSN: 2772-2082.</a> 
                                            </i>     
                                      </p>


                                      <p>
                                            <i>
                                                 <a href="/Articles/article_indexe18_9.pdf" > 13. DABA GBOTA, Kongo-Léopoldville und die Bundesrepublik Deutschland um das Jahr 1960, in N’ZASSA N°4, www.nzassa-revue.net, N’ZASSA, Revue Scientifique des Lettres, Langues et Arts, Littératures et Civilisations, Sciences Humaines et Sociales, Communication, Numéro 4, Décembre 2020, UFR Communication, Milieu et Société, Université Alassane Ouattara, Bouaké, (Côte d’Ivoire), de la page 12 à la page 24, ISSN 2706-5405 , Indexation : ZDB (Zeitschriften Datenbank) : https://zdb-katalog.de /title.xhtml?idn= 1251430449 &view=full .</a> 
                                            </i>     
                                      </p>


                                      <p>
                                            <i>
                                                 <a href="/Articles/article_non_indexe18_14.pdf" > 14. DABA GBOTA, La Namibie et l’Allemagne. Jusque juste après 1990, in LETTRES D’IVOIRE N°33, LETTRES D’IVOIRE, Revue Semestrielle Scientifique de Littératures, Langues et Sciences Humaines, N°33, Juin 2021, de la page 219 à la page 233, Université Alassane Ouattara, Bouaké, Côte d’Ivoire, ISSN 1991-8666.</a> 
                                            </i>     
                                      </p>


                                      <p>
                                            <i>
                                                 <a href="/Articles/.pdf" > 15. DABA GBOTA, West-Deutschland und Togo um das Jahr 1960, in RILALE N°2, RILALE, (Revue Internationale de Linguistique Appliquée de Littérature et d’Éducation), Revue en Ligne (ONLINE), Université d’Abomey-Calavi, BENIN, Volume 4, Numéro 2, Juin 2021, de la page 13 à la page 26, ISSN 1840-9318 , INDEXATION : ISSUU YOU PUBLISH https://bit.ly/2JPtqiJ ; INDEX COPERNICUS .</a> 
                                            </i>     
                                      </p>


                                      <p>
                                            <i>
                                                 <a href="/Articles/article_non_indexe18_15.pdf"  > 16. DABA GBOTA, West-Deutschland und Mali um 1960: Die politischen Beziehungen dieser beiden Länder besonders kurz nach der Unabhängigkeit Malis, in UIRTUS Vol.1, UIRTUS, Revue Internationale en Ligne (ONLINE) des Lettres, Langues et Sciences Sociales, Vol.1, Août 2021, Université de Lomé, TOGO, de la page 328 à la page 346, ISSN: 2710 – 4699.</a> 
                                            </i>     
                                      </p>


                                      <p>
                                            <i>
                                                 <a href="/Articles/article_indexe18_14.pdf" > 17. DABA GBOTA, DIE BUNDESREPUBLIK DEUTSCHLAND UND OBERVOLTA UM DAS JAHR 1960, in RILALE (Revue Internationale de Linguistique Appliquée et d’Éducation) , Volume 5 , Numéro 1 , Février 2022, RILALE, (Revue Internationale de Linguistique Appliquée de Littérature et d’Éducation), Revue en Ligne (ONLINE), Université d’Abomey-Calavi, BENIN, Volume 5, Numéro 1, Février 2022, de la page 39 à la page 56, ISSN 1840-9318, INDEXATION : ISSUU YOU PUBLISH https://bit.ly/2JPtqiJ ; INDEX COPERNICUS , https://bit.ly/2IHsH9k .</a> 
                                            </i>     
                                      </p>


                                      <p>
                                            <i>
                                                 <a href="/Articles/.pdf" > 18. DABA GBOTA, Das Tanganjika und die Bundesrepublik Deutschland bis kurz nach 1964, in UIRTUS (Revue Internationale en LIGNE (ONLINE), Volume 2 , Numéro 2 , Août 2022, UIRTUS, Revue Internationale en Ligne (ONLINE) des Lettres, Langues et Sciences Sociales, Vol.2, Août 2022, Université de Lomé, TOGO, de la page 156 à la page 172, ISSN: 2710 – 4699.</a> 
                                            </i>     
                                      </p>


                                      <p>
                                            <i>
                                                 <a href="/Articles/article_indexe18_15.pdf"> 19. DABA GBOTA, L’Allemagne et la formation des élites africaines, in GERMIVOIRE N°16, GERMIVIRE (Revue Scientifique en Ligne) , Université Félix Houphouët-Boigny, Côte d’Ivoire, Numéro 16/2022, (Août 2022) de la page 06 à la page 20, ISSN 2411-6750.</a> 
                                            </i>     
                                      </p>

                                      <p>
                                            <i>
                                                 <a href="/Articles/article_indexe18_16.pdf"> 20. DABA GBOTA, La mondialisation dans l’histoire de l’humanité, in N’ZASSA N° 10 , N’ZASSA, Revue Scientifique des Lettres, Langues et Arts, Littératures et Civilisations, Sciences Humaines et Sociales, Communication, Numéro 10, Janvier 2023 , UFR Communication, Milieu et Société, Université Alassane Ouattara, Bouaké, (Côte d’Ivoire), de la page 29 à la page 40, ISSN 2706-5405 , Revue indexée , Indexation : ZDB (Zeitschriften Datenbank) : https://zdb-katalog.de /title.xhtml?idn= 1251430449 &view=full.</a> 
                                            </i>     
                                      </p>

                                      <p>
                                            <i>
                                                 <a href="/Articles/13DABA.pdf"  > 21. DABA GBOTA, L’Allemagne et le DAAD dans la formation des élites africaines, in Akkunda Scientific, Revue électronique pluridisciplinaire semestrielle, Lettres, Langues, Communication, Sciences Humaines et Sociales, Université Alassane Ouattara, Bouaké, (Côte d’Ivoire), de la page 159 à la page 171, ISSN 2959-927X N°3 Spécial – Mai 2024.</a> 
                                            </i>     
                                      </p>

                                      <p>
                                        <img src="/images/professeur-daba-gbota.jpg" alt="professeur-daba-gbota" width="530" height="870" /> 
                                      </p>
                                  </article>
                              </div>
                          </div>
                          <div id="close">
              <a href="#" onClick={() => auf('right')}>
                <span id="bild">Info schließen</span>
              </a>
            </div>

            <aside id="right">
              <h2 className="unseen">Zusätzliche Informationen</h2>
              <div className="moduletable">
                <h3>Vertretung</h3>
                <div className="custom">
                  <p>Unser Hauptsitz befindet sich in Deutschland, in BONN.</p>
                  <p>Die Vertretung leitet und koordiniert in einem afrikanischen Land die Aktivitäten von „DEUTSCHLAND-AFRIKA“.</p>
                  <p>Die erste und bisher einzige Vertretung befindet sich in der Elfenbeinküste.</p>
                  <p>&nbsp;</p>
                  <p>&nbsp;</p>
                </div>
              </div>
            </aside>
                      </div>
                  </div>
                  <div id="footer-outer">
            <div id="footer-sub">
                <footer id="footer"></footer>
            </div>
        </div>

              </div>
          </div>
      );
  };
  
  export default Lebenslauf;