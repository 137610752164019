import React from 'react';

const Adhesion = () => {

  // Définition des fonctions manquantes
  const changeFontSize = (size) => {
    const body = document.body;
    const currentSize = parseFloat(window.getComputedStyle(body).fontSize);
    body.style.fontSize = `${currentSize + size}px`;
  };

  const revertStyles = () => {
    document.body.style.fontSize = "16px"; // Taille de police par défaut
  };

  const auf = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.style.display = 'none'; // Ferme la section
    }
  };

  return (
    <div id="shadow">
      <div id="all">
        <div id="back">
          <header id="header">
            <div className="logoheader">
              <h1 id="logo">
                <img src="/images/deustch-afrika-logo.png" alt="Deutschland-Afrika !" />
              </h1>
              <table>
                <tr>
                  <td>
                    <div className="custom">
                      <p>&nbsp;</p>
                      <div>
                        <table>
                          <tbody>
                            <tr>
                              <td
                                id="nomong"
                                align="center"
                                valign="bottom"
                                bgcolor="transparent"
                              >
                                <span className="Style2">Deutschland-Afrika e.V. </span>
                              </td>
                            </tr>
                            <tr>
                              <td
                                id="tagline"
                                align="center"
                                valign="top"
                                bgcolor="transparent"
                              >
                                <span className="Style1">ONG Internationale Allemagne-Afrique</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <div id="lang"></div>
                  </td>
                </tr>
              </table>
            </div>
            <ul className="skiplinks">
              <li>
                <a href="#main" className="u2">
                  Aller au contenu
                </a>
              </li>
              <li>
                <a href="#nav" className="u2">
                  Aller au menu principal et à l'identification
                </a>
              </li>
              <li>
                <a href="#right" className="u2">
                  Aller aux informations additionnelles
                </a>
              </li>
            </ul>
            <h2 className="unseen">Navigation de recherche</h2>
            <h3 className="unseen">Navigation</h3>

            <div id="line">
              <div id="fontsize">
                <h3>Taille de la police</h3>
                <p>
                  <a
                    title="Augmenter la taille"
                    href="#"
                    onClick={(e) => { e.preventDefault(); changeFontSize(2); }}
                  >
                    Augmenter
                  </a>
                  <span className="unseen">.</span>
                  <a
                    href="#"
                    title="Revenir aux styles par défaut"
                    onClick={(e) => { e.preventDefault(); revertStyles(); }}
                  >
                    Réinitialiser
                  </a>
                  <span className="unseen">.</span>
                  <a
                    href="#"
                    title="Réduire la taille"
                    onClick={(e) => { e.preventDefault(); changeFontSize(-2); }}
                  >
                    Diminuer
                  </a>
                </p>
              </div>
              <h3 className="unseen">Recherche</h3>
              <div className="mod-languages">
                <ul className="lang-inline">
                  <li className="" dir="ltr">
                    <a href="Mitgliedschaft">
                      <img src="/lang/de_de.gif" alt="Deutsch" title="Deutsch" />
                    </a>
                  </li>
                  <li className="" dir="ltr">
                    <a href="Adhesion">
                      <img src="/lang/fr_fr.gif" alt="Français" title="Français" />
                    </a>
                  </li>
                  <li className="" dir="ltr">
                    <a href="Membership">
                      <img src="/lang/en.gif" alt="English (UK)" title="English (UK)" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </header>

          <div id="contentarea2">
            <div id="breadcrumbs">
              <div className="breadcrumbs">
                <span className="showHere">Vous êtes ici : </span>
                <a href="Accueil" className="pathway">
                  Accueil
                </a>
                <img src="/images/arrow.png" alt="" /> <span>Adhésion</span>
              </div>
            </div>

            <nav className="left1" id="nav">
              <div className="moduletable_menu">
                <h3>Menu principal</h3>
                <ul className="nav menu mod-list">
                  <li className="item-103 default">
                    <a href="Accueil">Accueil</a>
                  </li>
                  <li className="item-105 divider deeper parent">
                    <span className="separator">Présentation</span>
                    <ul className="nav-child unstyled small">
                      <li className="item-106">
                        <a href="MotivationsObjectifs">Motivations / Objectifs</a>
                      </li>
                      <li className="item-107">
                        <a href="TextesAllemagneAfrique">Les textes de l'ONG</a>
                      </li>
                      <li className="item-108">
                        <a href="PresidentAllemagneAfrique">Le président international</a>
                      </li>
                    </ul>
                  </li>
                  <li className="item-109 current active">
                    <a href="Adhesion">Adhésion</a>
                  </li>
                  <li className="item-110">
                    <a href="Contact">Contacts</a>
                  </li>
                </ul>
              </div>
            </nav>

            <div id="wrapper">
              <div id="main">
                <div id="system-message-container"></div>

                <article className="item-page">
                  <div className="pull-left"></div>
                  <div style={{ textAlign: 'center' }}>
                    <span style={{ fontSize: '14pt' }}>
                      Vous pouvez devenir membre de Allemagne-Afrique !
                    </span>
                  </div>
                  <div>
                    <br />
                    <br />
                    <br />
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    <span style={{ fontSize: '14pt' }}>
                      Après avoir pris connaissance
                    </span>
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    <span style={{ fontSize: '14pt' }}>
                      <li>
                        <a href="TextesAllemagneAfrique">des articles relatifs au membre dans nos
                          textes</a>
                      </li>
                    </span>
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    <span style={{ fontSize: '14pt' }}>
                      faites une demande d'adhésion en{' '}
                      <li>
                        <a href="Contact">cliquant ici</a>
                      </li>
                    </span>
                  </div>
                  <p>&nbsp;</p>
                  <p>&nbsp;</p>
                </article>
              </div>
            </div>

            <div id="close">
              <a href="#" onClick={() => auf('right')}>
                <span id="bild">Fermer les infos</span>
              </a>
            </div>
            <aside id="right">
              <h2 className="unseen">Informations supplémentaires</h2>
              <div className="moduletable">
                <h3>Représentation</h3>
                <div className="custom">
                  <p>Notre siège est en Allemagne, à BONN.</p>
                  <p>
                    La Représentation dirige et coordonne dans un pays africain, les activités de
                    "Allemagne-AFRIQUE".
                  </p>
                  <p>La première et seule représentation pour l'instant est en Côte d'Ivoire.</p>
                  <p>&nbsp;</p>
                  <p>&nbsp;</p>
                </div>
              </div>
            </aside>
          </div>
        </div>
            <div id="footer-outer">
                <div id="footer-sub">
                    <footer id="footer"></footer>
                </div>
            </div>
      </div>
    </div>
  );
};

export default Adhesion;
