import React from 'react';

const CurriculumVitae = () => {

  // Définition des fonctions manquantes
  const changeFontSize = (size) => {
    const body = document.body;
    const currentSize = parseFloat(window.getComputedStyle(body).fontSize);
    body.style.fontSize = `${currentSize + size}px`;
  };

  const revertStyles = () => {
    document.body.style.fontSize = "16px"; // Taille de police par défaut
  };

  const auf = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.style.display = 'none'; // Ferme la section
    }
  };
 
      return (
          <div id="shadow">
              <div id="all">
                  <div id="back">
                      <header id="header">
                          <div className="logoheader">
                              <h1 id="logo">
                                  <img src="/images/deustch-afrika-logo.png" alt="Deutschland-Afrika!" />
                              </h1>
                              <table>
                                  <tbody>
                                      <tr>
                                          <td>
                                              <div className="custom">
                                                  <p>&nbsp;</p>
                                                  <div>
                                                      <table>
                                                          <tbody>
                                                              <tr>
                                                                  <td id="nomong" align="center" valign="bottom" bgcolor="transparent">
                                                                      <span className="Style2">Deutschland-Afrika e.V.</span>
                                                                  </td>
                                                              </tr>
                                                              <tr>
                                                                  <td id="tagline" align="center" valign="top" bgcolor="transparent">
                                                                      <span className="Style1">ONG Internationale Allemagne-Afrique</span>
                                                                  </td>
                                                              </tr>
                                                          </tbody>
                                                      </table>
                                                  </div>
                                              </div>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td>
                                              <div id="lang"></div>
                                          </td>
                                      </tr>
                                  </tbody>
                              </table>
                          </div>
                          <ul className="skiplinks">
              <li>
                <a href="#main" className="u2">Aller au contenu</a>
              </li>
              <li>
                <a href="#nav" className="u2">Aller au menu principal et à l'identification</a>
              </li>
              <li>
                <a href="#right" className="u2">Aller aux informations additionnelles</a>
              </li>
            </ul>
            <h2 className="unseen">Navigation de recherche</h2>
            <h3 className="unseen">Navigation</h3>

            <div id="line">
              <div id="fontsize">
                <h3>Taille de la police</h3>
                <p>
                  <a
                    title="Augmenter la taille"
                    href="#"
                    onClick={(e) => { e.preventDefault(); changeFontSize(2); }}
                  >
                    Augmenter
                  </a>
                  <span className="unseen">.</span>
                  <a
                    href="#"
                    title="Revenir aux styles par défaut"
                    onClick={(e) => { e.preventDefault(); revertStyles(); }}
                  >
                    Réinitialiser
                  </a>
                  <span className="unseen">.</span>
                  <a
                    href="#"
                    title="Réduire la taille"
                    onClick={(e) => { e.preventDefault(); changeFontSize(-2); }}
                  >
                    Diminuer
                  </a>
                </p>
              </div>
              <h3 className="unseen">Recherche</h3>
              <div className="mod-languages">
                <ul className="lang-inline">
                  <li className="" dir="ltr">
                    <a href="Lebenslauf">
                      <img src="/lang/de_de.gif" alt="Deutsch" title="Deutsch" />
                    </a>
                  </li>
                  <li className="" dir="ltr">
                    <a href="CurriculumVitae">
                      <img src="/lang/fr_fr.gif" alt="Français" title="Français" />
                    </a>
                  </li>
                  <li className="" dir="ltr">
                    <a href="VitaeCurriculum">
                      <img src="/lang/en.gif" alt="English (UK)" title="English (UK)" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
                      </header>
                      <div id="contentarea2">
                          <div id="breadcrumbs">
                              <div className="breadcrumbs">
                                  <span className="showHere">Vous êtes ici :</span>
                                  <a href="Accueil" className="pathway">Accueil</a>
                                  <img src="/images/arrow.png" alt="" />
                                  <span>Le président international</span>
                                  <img src="/images/arrow.png" alt="" />
                                  <span>Curriculum Vitae de Professeur GBOTA</span>
                              </div>
                          </div>
                          <nav className="left1" id="nav">
                              <div className="moduletable_menu">
                                  <h3>Menu principal</h3>
                                  <ul className="nav menu mod-list">
                                      <li className="item-103 default"><a href="Accueil">Accueil</a></li>
                                      <li className="item-105 divider deeper parent">
                                          <span className="separator">Présentation</span>
                                          <ul className="nav-child unstyled small">
                                              <li className="item-106"><a href="MotivationsObjectifs">Motivations / Objectifs</a></li>
                                              <li className="item-107"><a href="TextesAllemagneAfrique">Les textes de l'ONG</a></li>
                                              <li className="item-108"><a href="PresidentAllemagneAfrique">Le président international</a></li>
                                          </ul>
                                      </li>
                                      <li className="item-109"><a href="Adhesion">Adhésion</a></li>
                                      <li className="item-110"><a href="Contact">Contacts</a></li>
                                  </ul>
                              </div>
                          </nav>
                          <div id="wrapper">
                              <div id="main">
                                  <article className="item-page"  align="justify">
                                      <p style={{ textAlign: 'center' }}>
                                          <strong>CURRICULUM VITAE DE :</strong>
                                      </p>
                                      <p style={{ textAlign: 'center' }}>
                                          <img src="/images/photo-Dr-Daba.jpg" alt="photo-Dr-Daba" width="198" height="236" />
                                      </p>
                                      <p style={{ textAlign: 'center' }}>
                                          <strong>Professeur DABA GBOTA</strong>
                                      </p>
                                      <p style={{ textAlign: 'center' }}>
                                          <strong>MAÎTRE DE CONFÉRENCES</strong>
                                      </p>
                                      <p style={{ textAlign: 'center' }}>
                                          <strong>ès Lettres</strong>
                                      </p>
                                      <p style={{ textAlign: 'center' }}>
                                          <strong>(Spécialités : Histoire et Philologie Germanique)</strong>
                                      </p>
                                      <p style={{ textAlign: 'center' }}>
                                          <strong><span style={{ color: '#0000ff' }}>24 JANVIER 2001 : DOCTORAT UNIQUE</span></strong>
                                      </p>
                                      <p style={{ textAlign: 'center' }}>
                                          <strong><span style={{ color: '#0000ff' }}>- HISTOIRE ET ALLEMAND -</span></strong>
                                      </p>
                                      <p style={{ textAlign: 'center' }}>
                                          <strong><span style={{ color: '#800080' }}>DOCTORAT RÉUSSI A L’UNIVERSITÉ DE DÜSSELDORF EN ALLEMAGNE</span></strong>
                                      </p>
                                      
                                      <p style={{ textAlign: 'center' }}>
                                          <strong><span style={{ color: '#ff0000' }}>Enseignant d’Université ; Spécialités : Histoire de l’Allemagne et Littérature Allemande</span></strong>
                                      </p>
                                      <p><strong>Je m’appelle DABA GBOTA.</strong></p>
                                      <p><strong><i>Je suis né le 1er Juillet 1960 à Dakouritrohoin, dans la Sous-Préfecture de Lakota (CÔTE D’IVOIRE).</i></strong></p>
                                      <i><p><strong>Mon père est décédé en 1967; je ne suis donc pas allé tôt à l’école.</strong></p>
                                      <p><strong>Année Scolaire 1968-1969: début d’école primaire à l’Ecole Primaire Publique de Guéyo</strong></p>
                                      <p><strong>A la suite d’une faute de frappe commise sur mon nom en 1974 à l’Etat Civil de LAKOTA, j’ai vécu de 1974 à l’an 2007 avec le nom "DABO" GBOTA.  Conformément à la décision N°18 /7/STL/306 du 17 Avril 2007 du Tribunal de Première Instance de LAKOTA, j’ai recouvré mon nom naturel qui est DABA GBOTA.</strong></p>
                                      <p><strong>Juin 1974: C.E.P.E (EPP Guéyo)</strong></p>
                                      <p><strong>Juin 1975: Entrée en 6ème (EPP Guéyo)</strong></p>
                                      <p><strong>Année Scolaire 1975-1976: début du Secondaire au CEG de Sassandra</strong></p></i>
                                      <p><strong>Juin 1979: B.E.P.C (CEG Sassandra)</strong></p>
                                      <p><strong>Année Scolaire 1979-1980: début du Second Cycle du Secondaire au Lycée Classique et Moderne 1 de Daloa</strong></p>
                                      <p><strong>Juin 1982: BAC A4 (Philosophie, Littérature et Langues) au Lycée 1 de Daloa</strong></p>
                                      <p><strong>Année Académique 1982-1983: début du cursus académique à l’ENS à Abidjan: Département d’Allemand</strong></p>
                                      <p><strong>De Juillet à Septembre 1984: séjour linguistique en Allemagne; j’ai bénéficié de ce séjour, parce que j’étais l’un des six meilleurs Etudiants de la 2ème Année du Département d’Allemand.</strong></p>
                                      <p><strong>Année Universitaire 1984-1985: l’un des dix meilleurs Etudiants du Département d’Allemand étant autorisés à passer une année et une seule à l’Université de Cocody pour présenter la Licence d’Allemand; à la fin de ladite Année Universitaire, j’ai réussi en Septembre 1985, la LICENCE ès LETTRES (Spécialité: Etudes Germaniques).</strong></p>
                                      <p><strong>Année Universitaire 1985-1986: Retour à l’ENS pour poursuivre la formation pédagogique; C.A.P.E.S Théorique d’Allemand</strong></p>
                                      <p><strong>Septembre 1987: Stagiaire Privé Bénévole au Lycée Eduard Spranger de Bernhausen (Filderstadt 1) en Allemagne.</strong></p>
                                      <p><strong>Juin 1988: Fin de la Formation Pédagogique: C.A.P.E.S Pratique d’Allemand</strong></p>
                                      <p><strong>Octobre 1988: Professeur Certifié d’Allemand au Lycée Moderne d’Adzopé</strong></p>
                                      <p><strong>Octobre 1991: Professeur Certifié d’Allemand au Lycée Municipal de Koumassi</strong></p>
                                      <p><strong>De Mai à Juillet 1993: Université de Tübingen en Allemagne, Institut de Politologie: Recherches pour le Mémoire de Maîtrise d’Allemand; Boursier du DAAD (Office Allemand des Echanges Universitaires)</strong></p>
                                      <p><strong>Février 1996: Maîtrise ès Lettres (Spécialités : Etudes Germaniques) à l’Université de Cocody ; Mémoire: <span style={{ color: '#800080' }}>"Der Wandel der Politik der SED gegenüber der Bundesrepublik Deutschland in den 80er Jahren</span>" (Entendez :<span style={{ color: '#ff0000' }}>Le changement de la politique du SED vis-à-vis de la République Fédérale d’Allemagne dans les années 80</span>)</strong></p>
                                      <p><strong>A partir d’Octobre 1996: Institut d’Histoire de l’Université de Düsseldorf pour faire le 3ème Cycle ; Boursier du DAAD (Office Allemand des Echanges Universitaires)</strong></p>
                                      <p><strong>Mai 2000: Magister Allemand d’Histoire à l’Université de Düsseldorf; Mémoire du Magister: <span style={{ color: '#800080' }}>"Die Entwicklungspolitik der EG bis zur ersten Konvention von Jaunde 1963"</span> (Entendez : <span style={{ color: '#ff0000' }}>La politique d’aide au développement de la CEE jusqu’à la première convention de Yaoundé de 1963); Boursier du DAAD (Office Allemand des Echanges Universitaires</span>)</strong></p>
                                      <p><strong>24 Janvier 2001: Doctorat ès Lettres; Spécialités: Histoire et Philologie Germanique, Université de Düsseldorf; Thèse de Doctorat : <span style={{ color: '#800080' }}>"Die Politik Deutschlands und Frankreichs bei der Aushandlung der Verträge von Jaunde bis Lomé-III</span>" (Entendez :<span style={{ color: '#ff0000' }}> La politique de l’Allemagne et de la France dans les négociations des accords de Yaoundé à Lomé-III</span>); Boursier du DAAD (Office Allemand des Echanges Universitaires)</strong></p>
                                      <p><strong>07 Février 2002: Recruté par la Commission Nationale de Recrutement des Enseignants du Supérieur.</strong></p>
                                      <p><strong>A partir du 15 Avril 2002: Assistant au Département d’Allemand de l’Université de BOUAKE.</strong></p>
                                      <p><strong>De Juillet 2004 à Août 2006: de nouveau en Allemagne (mais cette fois,dans la ville de Wipperfürth) ; pendant cette période,, j’ai travaillé en Novembre et Décembre 2005, en tant que Journaliste Politique au Service Francophone de la Radio Internationale Allemande LA VOIX D’Allemagne, à BONN.</strong></p>
                                      <p><strong>Le 04 Décembre 2006, j’ai repris le service à l’Université de BOUAKE, qui travaille depuis fin 2002 à ABIDJAN.</strong></p>
                                      <p><strong>J’ai été légalement marié à la Mairie de Cocody (ABIDJAN) le Vendredi, 03 Août 2007. Je suis père de quatre enfants.</strong></p>
                                      <p><strong>Je suis aussi le Président Mondial de l’ONG Internationale Allemagne-Afrique qui a son Siège Mondial dans la ville de Bonn en Allemagne.</strong></p>
                                      <p><strong>Fin Février 2012: L'Université de BOUAKÉ travaille de nouveau dans la ville de BOUAKÉ.</strong></p>
                                      <p><strong>Début Mars 2012: A cause de l'ONG et de mes Recherches Universitaires, je fais la navette entre l'Afrique et l'Allemagne depuis le début du mois de Mars 2012.</strong></p>
                                      <p><strong>Je suis Ivoirien.</strong></p>
                                      <p><strong>Je vous remercie. Prof DABA GBOTA (MAÎTRE DE CONFÉRENCES).</strong></p>
                                      <p style={{ textAlign: 'center' }}>
                                          <strong>PUBLICATIONS</strong>
                                      </p>


                                      <p>
                                          <strong>1. „DABO“ <i>(Lorsque Prof. DABA (MaÎtre de Conférences) rédigeait sa Thèse de DOCTORAT,</i> il s’appelait encore „DABO“ , d’où, DABO GBOTA, Die Politik Deutschlands und Frankreichs bei der Aushandlung der Verträge von Jaunde bis Lomé-III, Institut d’Histoire de l’Université de Düsseldorf, Allemagne), 2000, Düsseldorf, Universität, Dissertation, Katalog der Deutschen Nationalbibliothek, Signatur : H 2001 A 1726, Bereitstellung in Frankfurt (en ALLEMAGNE), (Thèse de Doctorat Unique, Institut d’Histoire de l’Université de Düsseldorf, Allemagne), 2000, Düsseldorf, Universität, Dissertation, Katalog der Deutschen Nationalbibliothek, Signatur : H 2001 A 1726, Bereitstellung in Frankfurt (en ALLEMAGNE), Signatur : H 2001 A 1726, Bereitstellung in Leipzig (en ALLEMAGNE).</strong>
                                      </p>
                                     

                                      <p>
                                            <i>
                                                 <a href="/Articles/article_non_indexe18_17.pdf"> 2. DABA GBOTA, Willy Brandt, le Tiers-Monde et l’Internationale Socialiste, in LETTRES D’IVOIRE N°22, Université Alassane Ouattara, Bouaké, Côte d’Ivoire, Juin 2016, de la page 287 à a page 297, ISSN 1991-8666.</a> 
                                            </i>     
                                      </p>

                                      <p>
                                            <i>
                                                 <a href="/Articles/article_non_indexe18_19.pdf" > 3. DABA GBOTA, Willy Brandt et le rapprochement entre l’Allemagne de l’Est et l’Allemagne de l’Ouest, in LETTRES D’IVOIRE N°26, Université Alassane Ouattara, Bouaké, Côte d’Ivoire, Décembre 2017, de la page 377 à la page 387, ISSN 1991-8666.</a> 
                                            </i>     
                                      </p>

                                      <p>
                                            <i>
                                                 <a href="/Articles/article_non_indexe18_20.pdf" > 4. DABA GBOTA, Die deutsche AKP-Politik ab 1975, in GEVOIX (GESTE ET VOIX) N°26, Université d’Abomey-Calavi, Bénin, Décembre 2017, de la page 58 à la page 69, ISSN 1840-572X.</a> 
                                            </i>     
                                      </p>

                                      <p>
                                            <i>
                                                 <a href="/Articles/article_non_indexe18_18.pdf" > 5. DABA GBOTA, Deutschland in der Problematik des Eintritts Großbritanniens in die Europäische Wirtschaftsgemeinschaft, in GERMIVOIRE N°7, Numéro Spécial, in GERMIVOIRE (Revue Scientifique en Ligne) N°7, Numéro Spécial, Université Félix Houphouët-Boigny, Côte d’Ivoire, Décembre 2017, de la page 126 à la page 140, ISSN 2411-6750.</a> 
                                            </i>     
                                      </p>

                                      <p>
                                            <i>
                                                 <a href="/Articles/LE_RWANDA_ET_L'ALLEMAGNE _FEDERALE_AUTOUR_DE_1962.pdf" > 6. DABA GBOTA, Le Rwanda et l’Allemagne Fédérale autour de 1962, in N’ZASSA N°1, Revue Scientifique des Lettres, Langues et Arts, Littératures et Civilisations, Sciences Humaines et Sociales, Communication, Numéro 1, Juin 2019, UFR Communication, Milieu et Société, Université Alassane Ouattara, Bouaké, (Côte d’Ivoire), de la page 10 à la page 20, ISSN 2706-5405 , Indexation : ZDB (Zeitschriften Datenbank) : https://zdb-katalog.de /title.xhtml?idn= 1251430449 &view=full .</a> 
                                            </i>     
                                      </p>


                                      <p>
                                            <i>
                                                 <a href="/Articles/article_indexe18_7.pdf" >7. DABA GBOTA, Le Cameroun et l’Allemagne : de la colonisation à la période postcoloniale, juste après 1960, des relations en mutation, in GERMIVOIRE 11/2019, GERMIVIRE (Revue Scientifique en Ligne) , Université Félix Houphouët-Boigny, Côte d’Ivoire, Numéro 11/2019, de la page 118 à la page 136, ISSN 2411-6750.</a>
                                            </i>     
                                      </p>
                                      
                                      <p>
                                            <i>
                                                 <a href="/Articles/article_non_indexe18_6.pdf" > 8. DABA GBOTA,Die Elfenbeinküste und die Bundesrepublik Deutschland um 1960, in LETTRES D’IVOIRE N°30, LETTRES D’IVOIRE, Revue Scientifique de Littératures, Langues et Sciences Humaines, Littératures et Langues, Numéro 30, Université Alassane Ouattara, Décembre 2019, de la page 273 bis à la page 283, ISSN 1991-8666.</a> 
                                            </i>     
                                      </p>

                                      <p>
                                            <i>
                                                 <a href="/Articles/article_non_indexe18_7.pdf" > 9. DABA GBOTA, Le Dahomey et l’Allemagne de l’Ouest autour de 1960, in GEVOIX (GESTE ET VOIX) N°30, Ge Voix (GESTE ET VOIX), Revue Scientifique, Université d’Abomey-Calavi, Benin, Numéro 30, Décembre 2019, de la page 178 à la page 189, ISSN 1840-572 X.</a> 
                                            </i>     
                                      </p>

                                      <p>
                                            <i>
                                                 <a href="/Articles/article_indexe18_8.pdf" > 10. DABA GBOTA, Citoyenneté et mondialisation, in N’ZASSA N°3, www.nzassa-revue.net, N’ZASSA, Revue Scientifique des Lettres, Langues et Arts, Littératures et Civilisations, Sciences Humaines et Sociales, Communication, Numéro 3, Juin 2020, UFR Communication, Milieu et Société, Université Alassane Ouattara, Bouaké, (Côte d’Ivoire), de la page15 à la page 25, ISSN 2706-5405 , Indexation : ZDB (Zeitschriften Datenbank) : https://zdb-katalog.de /title.xhtml?idn= 1251430449 &view=full .</a> 
                                            </i>     
                                      </p>

                                      <p>
                                            <i>
                                                 <a href="/Articles/article_non_indexe18_8.pdf" > 11. DABA GBOTA, Le Sénégal et l’Allemagne de l’Ouest autour de 1960, in GEVOIX (GESTE ET VOIX) N°31, Ge Voix (GESTE ET VOIX), Revue Scientifique, Université d’Abomey-Calavi, Benin, Numéro 31, Juin 2020, de la page 230 à la page 245, ISSN 1840-572 X.</a> 
                                            </i>     
                                      </p>


                                      <p>
                                            <i>
                                                 <a href="/Articles/article_non_indexe18_10.pdf" > 12. DABA GBOTA, Ghana und West-Deutschland bis kurz nach 1957, in THE JOURNAL OF AFRICAN AND POSTCOLONIAL STUDIES, Issue Number 2, July 2020 , Book two, THE JOURNAL OF AFRICAN AND POSTCOLONIAL STUDIES, Migration, Literature and Society, Issue Number 2, July 2020, BOOK TWO: OFF CONFERENCE ARTICLES, ARCIV, FLSH, CHEIKH ANTA DIOP UNIVERSITY, DAKAR, SENEAL, de la page 305 à la page 326, ISSN: 2772-2082.</a> 
                                            </i>     
                                      </p>


                                      <p>
                                            <i>
                                                 <a href="/Articles/article_indexe18_9.pdf" > 13. DABA GBOTA, Kongo-Léopoldville und die Bundesrepublik Deutschland um das Jahr 1960, in N’ZASSA N°4, www.nzassa-revue.net, N’ZASSA, Revue Scientifique des Lettres, Langues et Arts, Littératures et Civilisations, Sciences Humaines et Sociales, Communication, Numéro 4, Décembre 2020, UFR Communication, Milieu et Société, Université Alassane Ouattara, Bouaké, (Côte d’Ivoire), de la page 12 à la page 24, ISSN 2706-5405 , Indexation : ZDB (Zeitschriften Datenbank) : https://zdb-katalog.de /title.xhtml?idn= 1251430449 &view=full .</a> 
                                            </i>     
                                      </p>


                                      <p>
                                            <i>
                                                 <a href="/Articles/article_non_indexe18_14.pdf" > 14. DABA GBOTA, La Namibie et l’Allemagne. Jusque juste après 1990, in LETTRES D’IVOIRE N°33, LETTRES D’IVOIRE, Revue Semestrielle Scientifique de Littératures, Langues et Sciences Humaines, N°33, Juin 2021, de la page 219 à la page 233, Université Alassane Ouattara, Bouaké, Côte d’Ivoire, ISSN 1991-8666.</a> 
                                            </i>     
                                      </p>


                                      <p>
                                            <i>
                                                 <a href="/Articles/.pdf" > 15. DABA GBOTA, West-Deutschland und Togo um das Jahr 1960, in RILALE N°2, RILALE, (Revue Internationale de Linguistique Appliquée de Littérature et d’Éducation), Revue en Ligne (ONLINE), Université d’Abomey-Calavi, BENIN, Volume 4, Numéro 2, Juin 2021, de la page 13 à la page 26, ISSN 1840-9318 , INDEXATION : ISSUU YOU PUBLISH https://bit.ly/2JPtqiJ ; INDEX COPERNICUS.</a> 
                                            </i>     
                                      </p>


                                      <p>
                                            <i>
                                                 <a href="/Articles/article_non_indexe18_15.pdf" > 16. DABA GBOTA, West-Deutschland und Mali um 1960: Die politischen Beziehungen dieser beiden Länder besonders kurz nach der Unabhängigkeit Malis, in UIRTUS Vol.1, UIRTUS, Revue Internationale en Ligne (ONLINE) des Lettres, Langues et Sciences Sociales, Vol.1, Août 2021, Université de Lomé, TOGO, de la page 328 à la page 346, ISSN: 2710 – 4699.</a> 
                                            </i>     
                                      </p>


                                      <p>
                                            <i>
                                                 <a href="/Articles/article_indexe18_14.pdf" > 17. DABA GBOTA, DIE BUNDESREPUBLIK DEUTSCHLAND UND OBERVOLTA UM DAS JAHR 1960, in RILALE (Revue Internationale de Linguistique Appliquée et d’Éducation) , Volume 5 , Numéro 1 , Février 2022, RILALE, (Revue Internationale de Linguistique Appliquée de Littérature et d’Éducation), Revue en Ligne (ONLINE), Université d’Abomey-Calavi, BENIN, Volume 5, Numéro 1, Février 2022, de la page 39 à la page 56, ISSN 1840-9318, INDEXATION : ISSUU YOU PUBLISH https://bit.ly/2JPtqiJ ; INDEX COPERNICUS , https://bit.ly/2IHsH9k .</a> 
                                            </i>     
                                      </p>


                                      <p>
                                            <i>
                                                 <a href="/Articles/.pdf" > 18. DABA GBOTA, Das Tanganjika und die Bundesrepublik Deutschland bis kurz nach 1964, in UIRTUS (Revue Internationale en LIGNE (ONLINE), Volume 2 , Numéro 2 , Août 2022, UIRTUS, Revue Internationale en Ligne (ONLINE) des Lettres, Langues et Sciences Sociales, Vol.2, Août 2022, Université de Lomé, TOGO, de la page 156 à la page 172, ISSN: 2710 – 4699.</a> 
                                            </i>     
                                      </p>


                                      <p>
                                            <i>
                                                 <a href="/Articles/article_indexe18_15.pdf" > 19. DABA GBOTA, L’Allemagne et la formation des élites africaines, in GERMIVOIRE N°16, GERMIVIRE (Revue Scientifique en Ligne) , Université Félix Houphouët-Boigny, Côte d’Ivoire, Numéro 16/2022, (Août 2022) de la page 06 à la page 20, ISSN 2411-6750.</a> 
                                            </i>     
                                      </p>


                                      <p>
                                            <i>
                                                 <a href="/Articles/article_indexe18_16.pdf" > 20. DABA GBOTA, La mondialisation dans l’histoire de l’humanité, in N’ZASSA N° 10 , N’ZASSA, Revue Scientifique des Lettres, Langues et Arts, Littératures et Civilisations, Sciences Humaines et Sociales, Communication, Numéro 10, Janvier 2023 , UFR Communication, Milieu et Société, Université Alassane Ouattara, Bouaké, (Côte d’Ivoire), de la page 29 à la page 40, ISSN 2706-5405 , Revue indexée , Indexation : ZDB (Zeitschriften Datenbank) : https://zdb-katalog.de /title.xhtml?idn= 1251430449 &view=full.</a> 
                                            </i>     
                                      </p>


                                      <p>
                                            <i>
                                                 <a href="/Articles/13DABA.pdf" > 21. DABA GBOTA, L’Allemagne et le DAAD dans la formation des élites africaines, in Akkunda Scientific, Revue électronique pluridisciplinaire
semestrielle, Lettres, Langues, Communication, Sciences Humaines et Sociales, Université Alassane Ouattara, Bouaké, (Côte d’Ivoire), de la page 159 à la page 171, ISSN 2959-927X N°3 Spécial – Mai 2024. </a> 
                                            </i>     
                                      </p>
                                      
                                      
                                      <p>
                                        <img src="/images/professeur-daba-gbota.jpg" alt="professeur-daba-gbota" width="530" height="870" /> 
                                      </p>
                                  </article>
                              </div>
                          </div>
                          <div id="close">
              <a href="#" onClick={() => auf('right')}>
                <span id="bild">Fermer les infos</span>
              </a>
            </div>

                          <aside id="right">
              <h2 className="unseen">Informations supplémentaires</h2>
              <div className="moduletable">
                <h3>Représentation</h3>
                <div className="custom">
                  <p>Notre siège est en Allemagne, à BONN.</p>
                  <p>La Représentation dirige et coordonne dans un pays africain, les activités de "Allemagne-AFRIQUE".</p>
                  <p>La première et seule représentation pour l'instant est en Côte d'Ivoire.</p>
                  <p>&nbsp;</p>
                  <p>&nbsp;</p>
                </div>
              </div>
            </aside>
                      </div>
                  </div>
                  <div id="footer-outer">
            <div id="footer-sub">
                <footer id="footer"></footer>
            </div>
        </div>

              </div>
          </div>
      );
  };
  
  export default CurriculumVitae;