import React from 'react';

const TextsOfTheNgo = () => {
  // Define missing functions
  const changeFontSize = (size) => {
    const body = document.body;
    const currentSize = parseFloat(window.getComputedStyle(body).fontSize);
    body.style.fontSize = `${currentSize + size}px`;
  };

  const revertStyles = () => {
    document.body.style.fontSize = "16px"; // Default font size
  };

  const closeSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.style.display = 'none'; // Close the section
    }
  };

  return (
    <div id="shadow">
      <div id="all">
        <div id="back">
          <header id="header">
            <div className="logoheader">
              <h1 id="logo">
                <img src="/images/deustch-afrika-logo.png" alt="Deutschland-Afrika!" />
              </h1>
              <table>
                <tr>
                  <td>
                    <div className="custom">
                      <p>&nbsp;</p>
                      <div>
                        <table>
                          <tbody>
                            <tr>
                            <td id="nomong" align="center" valign="bottom" bgcolor="transparent">
                                <span className="Style2">Deutschland-Afrika e.V.</span>
                              </td>
                            </tr>
                            <tr>
                              <td
                                id="tagline"
                                align="center"
                                valign="top"
                                bgcolor="transparent"
                              >
                                <span className="Style1">
                                  International NGO Germany-Africa
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <div id="lang"></div>
                  </td>
                </tr>
              </table>
            </div>
            <ul className="skiplinks">
              <li>
                <a href="#main" className="u2">
                  Skip to content
                </a>
              </li>
              <li>
                <a href="#nav" className="u2">
                  Skip to main menu and login
                </a>
              </li>
              <li>
                <a href="#right" className="u2">
                  Skip to additional information
                </a>
              </li>
            </ul>
            <h2 className="unseen">Search Navigation</h2>
            <h3 className="unseen">Navigation</h3>
            <div id="line">
              <div id="fontsize">
                <h3>Font Size</h3>
                <p>
                  <a
                    title="Increase font size"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      changeFontSize(2);
                    }}
                  >
                    Increase
                  </a>
                  <span className="unseen">.</span>
                  <a
                    href="#"
                    title="Reset styles to default"
                    onClick={(e) => {
                      e.preventDefault();
                      revertStyles();
                    }}
                  >
                    Reset
                  </a>
                  <span className="unseen">.</span>
                  <a
                    href="#"
                    title="Decrease font size"
                    onClick={(e) => {
                      e.preventDefault();
                      changeFontSize(-2);
                    }}
                  >
                    Decrease
                  </a>
                </p>
              </div>
              <h3 className="unseen">Search</h3>
              <div className="mod-languages">
                <ul className="lang-inline">
                  <li className="" dir="ltr">
                    <a href="NGOText">
                      <img src="/lang/de_de.gif" alt="Deutsch" title="Deutsch" />
                    </a>
                  </li>
                  <li className="" dir="ltr">
                    <a href="TextesAllemagneAfrique">
                      <img src="/lang/fr_fr.gif" alt="Français" title="Français" />
                    </a>
                  </li>
                  <li className="" dir="ltr">
                    <a href="TextsOfTheNgo">
                      <img src="/lang/en.gif" alt="English (UK)" title="English (UK)" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </header>
          <div id="contentarea2">
            <div id="breadcrumbs">
              <div className="breadcrumbs">
                <span className="showHere">You are here: </span>
                <a href="HomeEn" className="pathway">
                  Home
                </a>
                <img src="/images/arrow.png" alt="" /> <span>Texts of the NGO </span>
              </div>
            </div>
            <nav className="left1" id="nav">
              <div className="moduletable_menu">
                <h3>Main Menu</h3>
                <ul className="nav menu mod-list">
                  <li className="item-103 default">
                    <a href="HomeEn">Home</a>
                  </li>
                  <li className="item-105 divider deeper parent">
                    <span className="separator">Presentation</span>
                    <ul className="nav-child unstyled small">
                      <li className="item-106">
                        <a href="MotivationObjectif">Motivations / Objectives</a>
                      </li>
                      <li className="item-107 current active">
                        <a href="TextsOfTheNgo ">Texts of the NGO</a>
                      </li>
                      <li className="item-108">
                        <a href="PresidentGermanyAfrica">International President</a>
                      </li>
                    </ul>
                  </li>
                  <li className="item-109">
                    <a href="Membership">Membership</a>
                  </li>
                  <li className="item-110">
                    <a href="Contacts">Contacts</a>
                  </li>
                </ul>
              </div>
            </nav>
            
            <div id="wrapper">
    <div id="main">
        <div id="system-message-container"></div>
        <article className="item-page">
            <div className="pull-left"></div>
            <div className="styltit">
                <span style={{ fontFamily: 'verdana, geneva', fontSize: '10pt' }}>SUMMARY</span>
            </div>
            <div className="Laysom">
                <p className="Laysom">
                    <span style={{ fontFamily: 'verdana, geneva', fontSize: '10pt' }}>
                        <a href="#PREAMBLE">PREAMBLE</a>
                    </span>
                </p>
                <p className="Laysom">
                    <span style={{ fontFamily: 'verdana, geneva', fontSize: '10pt' }}>
                        <a href="#NAME, HEADQUARTERS, WORKING YEAR">NAME, HEADQUARTERS, WORKING YEAR</a>
                    </span>
                </p>
                <p className="Laysom">
                    <span style={{ fontFamily: 'verdana, geneva', fontSize: '10pt' }}>
                        <a href="#OBJECTIVE">OBJECTIVE</a>
                    </span>
                </p>
                <p className="Laysom">
                    <span style={{ fontFamily: 'verdana, geneva', fontSize: '10pt' }}>
                        <a href="#MEMBER">MEMBER</a>
                    </span>
                </p>
                <p className="Laysom">
                    <span style={{ fontFamily: 'verdana, geneva', fontSize: '10pt' }}>
                        <a href="#ORGANS">ORGANS</a>
                    </span>
                </p>
                <p className="Laysom">
                    <span style={{ fontFamily: 'verdana, geneva', fontSize: '10pt' }}>
                        <a href="#REPRESENTATION">REPRESENTATION</a>
                    </span>
                </p>
            </div>
            <div className="LayTxt">
                <p className="styltxt" align="center">
                    <span style={{ fontFamily: 'verdana, geneva', fontSize: '10pt', textDecoration: "underline" }}>
                        <div id="PREAMBLE">PREAMBLE</div>
                    </span>
                </p>
                <h3 className="styltxt" align="justify">
                    <span className="Style1" style={{ fontSize: '10pt', fontFamily: 'verdana, geneva' }}>
                    The world would be so beautiful, the mankind would be so well-adjusted, if men didn’t reject each other on the basis of prejudices.

The world would be so nice, the humanity would be so radiant with happiness, if the people of the world could communicate with other , could live in harmony and in cheerfulness and if ones could avoid thinking up prejudices about others.

Considering that the serious problems of the mankind have a part of their causes in the rejection of the other,

Considering that this rejection happens usually on the basis of prejudices,

Being convinced that the deficit of communication is in great part on the basis of those prejudices,

Being convinced that bringing people together and understanding between people can contribute to harmony on the earth and to the radiance of the whole mankind, we decided to found an NGO, in order to bring people together and to let them understand each other.                    </span>
                </h3>
                <div>
                    <h3 className="LayTxt" align="justify">
                        <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                           <div id="NAME, HEADQUARTERS, WORKING YEAR"></div>
                            1 : <span style={{ textDecoration: "underline" }}> NAME, HEADQUARTERS, WORKING YEAR</span>
                        </span>
                    </h3>
                    <h3 className="styltxt" align="justify">
                        <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                        The Organization has the name “DEUTSCHLAND-AFRIKA” and must be registered in the Associations’ Register ; after having been registered, the name will additional have “e.V.” .  

According to circumstances, it is allowed to translate the name in other languages. The NGO has its Headquarters in Germany, in BONN. The working year of the NGO is the calendar year.


                        </span>
                    </h3>
                    <h3 className="LayTxt" align="justify">
                        <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                        <div id="OBJECTIVE"></div>
                            2 : <span style={{ textDecoration: "underline" }}>OBJECTIVE OF THE NGO</span>
                        </span>
                    </h3>
                    <h3 className="styltxt" align="justify">
                        <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                        The objective of the NGO is the understanding between people. But “GERMANY-AFRICA” concentrates on the understanding between the German People and the African People. The NGO contributes bringing German People and African People together. “DEUTSCHLAND-AFRIKA” must contribute to the fight against prejudices between the German People and the African People. The NGO pursues exclusively and directly aims of communal interests in the sense of the paragraph “OBJECTIVES ARE PROMOTED IN CASE OF TAXES” in the texts about the taxes’ regulation. The objective is realized throw the following actions:
                        </span>
                    </h3>
                    <div className="Style1" align="justify">
                        <ol className="Style8">
                            <li>
                                <h3 className="styltxt" align="justify">
                                    <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                                    1 - Organization of festivals of German Culture in the African        Countries. Once a year , “Germany-Africa” organizes in an African Country, a festival of the German Culture. The content of the festival depends on the communication level between the German People and the concerned African Country ;

</span>
                                </h3>
                            </li>
                            <li>
                                <h3 className="styltxt" align="justify">
                                    <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                                    2 - Organization of seminars in the framework of Education

</span>
                                </h3>
                            </li>
                            <li>
                                <h3 className="styltxt" align="justify">
                                    <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                                    3 - Organization of Radio or Television Broadcastings about Germany in the African Countries and about the African Countries in Germany;

</span>
                                </h3>
                            </li>
                            <li>
                                <h3 className="styltxt" align="justify">
                                    <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                                    4 - Organization of festivals of the African Culture in Germany.

</span>
                                </h3>
                            </li>
                        </ol>
                    </div>
                    <h3 className="styltxt" align="justify">
                        <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                        During the events of “Germany-Africa” in the African Countries, the communication languages are German and the official language of the country, where the event is taking place.

“GERMANY-AFRICA” is a Voluntary Organization. The organization doesn’t pursue profit objectives. The means of the NGO have to be used only for the objectives, which are written in its texts. The members of the organization don’t receive any salary and in their quality of members, they receive no kind of subvention. It is not allowed to do expenditures, which are strange to the objectives of the NGO, or to do high compensations in order to promote someone.

In case of dissolution of the NGO, or in case of abandon of the objective for which it was created, the possessions of the NGO have to be given to the GOETHE INSTITUTE, in order to be used for the education of persons who are stranger to Germany.

The persons who are working in organs of the NGO are doing only an honor work. Any decision which concerns a changing of the texts, has to be presented to the competent Financial Office before being pointed out to the court.                        </span>
                    </h3>
                   
                    <h3 className="styltxt" align="justify">
                        <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                        <div id='MEMBER'></div>
                            3 : <span style={{ textDecoration: "underline" }}> ACQUISITION OF QUALITY OF MEMBER

</span>
                        </span>
                    </h3>
                    <h3 className="styltxt" align="justify">
                        <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                        Can become member of “GERMANY-AFRICA” , everyone who strives to the objectives of the NGO. The Board of Directors deliberates on the joining demands. Every joining demand has to give the age, the profession and the address of the demander. The demand can be refused. The demander can write a reclamation against this refusing, which should anyway be written and justified. The reclamation has to be laid by the Board of Directors within one month from the date of refusing on. And in this case only the next Annual General Meeting will decide about the reclamation.

</span>
                    </h3>
                    <h3 className="LayTxt" align="justify">
                        <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                            4 : <span style={{ textDecoration: "underline" }}> END OF THE QUALITY OF MEMBER</span>
                        </span>
                    </h3>
                    <h3 className="styltxt" align="justify">
                        <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                        The quality of member ceases:

1– with the death of the member;

2– with the deliberate demission of the member;

3– if the member’ name has been crossed off the members’ list;

4– after expulsion from the NGO.

The deliberate demission takes place after a written declaration

by a member of the Board of Directors.

A member can be crossed from the members’ list after decision of the Board of Directors, if he is late in paying his contributions.

A member can be expulsed from the NGO after deliberation of the Board of Directors, if this member acted rudely against the interests of the NGO.                        </span>
                    </h3>
                    
                            
                       
                    <h3 className="LayTxt" align="justify">
                        <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                            5 : <span style={{ textDecoration: "underline" }}>MEMBER'S CONTRIBUTIONS</span>
                        </span>
                    </h3>
                    <h3 className="styltxt" align="justify">
                        <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                        The NGO receives contributions from its members. The amount is 5(five) Euro monthly. The contributions of each member have an amount of 60(sixteen) Euro yearly. The Honor Members are exempted from contributions’ obligation.                        </span>
                    </h3>
                    <h3 className="LayTxt" align="justify">
                        <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                        <div id='ORGANS'></div>
                            6 : <span style={{ textDecoration: "underline" }}>THE ORGANS OF THE NGO</span>
                        </span>
                    </h3>
                    <h3 className="styltxt" align="justify">
                        <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                        The organs of the NGO are:

1 – The Board of Directors;

2 – The Annual General Meeting.
                        </span>
                    </h3>
                    
                    <h3 className="LayTxt" align="justify">
                        <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                            7 : <span style={{ textDecoration: "underline" }}>THE BOARD OF DIRECTORS

</span>
                        </span>
                    </h3>
                    <h3 className="styltxt" align="justify">
                        <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                        The Board of Directors of “GERMANY-AFRICA” is composed of the Chairman of the NGO and the other members of the Board of Directors, who have to be named by the Chairman, notably the Vice-Chairman, the General Treasurer and the General Secretary. “Germany-Africa” is legal and extra-legal represented by 2(two) members of the Board of Directors, among whom the Chairman or the Vice-Chairman. The Chairman of the NGO officiates in BONN (in Germany), at the Headquarters of the NGO.
                        </span>
                    </h3>
                    <h3 className="LayTxt" align="justify">
                        <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                            8 : <span style={{ textDecoration: "underline" }}>COMPETENCES OF THE BOARD OF DIRECTORS

</span>
                        </span>
                    </h3>
                    <h3 className="styltxt" align="justify">
                        <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                        It is the Board of Directors which is enabled to decide about the affairs of “Germany-Africa” , unless the texts give certain competences to another organ of the NGO. The Board of Directors is competent for following tasks:

1 – Making preparations for the Annual General Meeting and to draw up the agenda;

2– Convening the Annual General Meeting;

3– Publication of the decisions of the Annual General Meeting;

4 – Drawing up the working plan for the working year, accounting, drawing up the annual report;

5– Inter into working agreements, stop working agreements;

6 – Deliberation about joining or expulsion of members.
                        </span>
                    </h3>
                            
                                <h3 className="styltxt" align="justify">
                                    <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                                        Preparation of the General Assembly and drafting the agenda;
                                    </span>
                                </h3>
                            
                            
                          



                    <h3 className="LayTxt" align="justify">
                                <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                                    9 : <span style={{ textDecoration: "underline" }}>  MANDATE DURATION OF THE BOARD OF DIRECTORS </span>
                                </span>
                            </h3>
                            <h3 className="styltxt" align="justify">
                                    <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                                    The Chairman of “Germany-Africa” , who names then the members of the Board of the Directors, is elected by the Annual General Meeting for a 3(three) years’ time, from the election day on. And he stays in office till the day of a new election.

Is electable, every communicative member of the NGO, from Africa or from Germany, who has a good knowledge of GERMAN LANGUAGE and a University Degree. The Chairman can be reelected.
                                    </span>
                                </h3>



                            <h3 className="LayTxt" align="justify">
                                <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                                    10: <span style={{ textDecoration: "underline" }}> DELIBERATION OF THE BOARD OF DIRECTORS </span>
                                </span>
                            </h3>

                            <h3 className="styltxt" align="justify">
                                <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                                The Board of Directors deliberates generally in Meeting of the Board of Directors; this Meeting has to be convened by the Chairman of the NGO. In case of hitch of the Chairman, the Meeting has to be convened by the representative of the Chairman. The convening is done per writing, per telephone or per fax. In any case, a period of one week has to be respected between the convening of the Meeting of Board of Directors and its taking place. The Board of Directors is allowed to deliberate, if at least two thirds of the Board are present and if the Chairman is among those two thirds. During the deliberation, the decision is valid, if it obtains the majority of the expressed suffrages. If there is equality of votes, it is the vote of the Chairman that determines the result of the Voting.

The Meeting of the Board of Directors is directed by the Chairman of the NGO; if the Chairman is hitched, the Vice-Chairman directs the Meeting.

The decisions of the Board of Directors, have to be registered in a Register for Deliberations and signed by the Director of the Meeting, in order to give pieces of evidence.

The written deliberation has to give the place, the time of the meeting of the Board, the names of the participants, the taken decisions and the result of the Voting. It is not allowed, that only one person occupies several posts of the Board of Directors.
                                </span>
                            </h3>

                           

                            <h3 className="LayTxt" align="justify">
                                <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                                <div id='REPRESENTATION'></div>
                                    11 : <span style={{ textDecoration: "underline" }}> REPRESENTATIONS </span>
                                </span>
                            </h3>

                            <h3 className="styltxt" align="justify">
                                <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                                The Representation directs and coordinates in an African Country, the activities of “German-Africa”.

                                </span>
                            </h3>

                            <h3 className="LayTxt" align="justify">
                                <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                                    12 : <span style={{ textDecoration: "underline" }}>THE ANNUAL GENERAL MEETING

</span>
                                </span>
                            </h3>

                            <h3 className="styltxt" align="justify">
                                <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                                During the Annual General Meeting, each member has one vote, the Honor Member too. In order to express the right of voting, it is allowed to give a procuration to another member. For each Annual General Meeting, the procuration has to be given. A member has however not the right to represent more than 3(three) persons. The Annual General Meeting is competent for following affairs:

1 – Approval of the actions’ planning for the following year elaborated by the Board of Directors;

2– Acceptance of the Board of Directors’ Annual Report;

3 – Deposition of the Board of Directors;

4 – Election of the Board of Directors;

5 – Deliberation concerning the change of the Texts and the dissolution of the NGO;

6 – Deliberation concerning the dispute about the rejection of a joining demand and Deliberation concerning the recourse against an expulsion decision coming from the Board of Directors;

7 – Nomination of Honor Members.

About affairs in the competence domain of the Board of Directors, the Annual General Meeting is allowed to do recommendations to the Board. The Board of Directors too, may, about affairs in its competence domain, request for recommendations from the Annual General Meeting.
                                </span>
                            </h3>

                            

                            <h3 className="LayTxt" align="justify">
                                <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                                    13 : <span style={{ textDecoration: "underline" }}>CONVENING THE ANNUAL GENERAL MEETING

</span>
                                </span>
                            </h3>

                            <h3 className="styltxt" align="justify">
                                <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                                The General Meeting takes place twice yearly (two times in a year): a Meeting in March and another one in November. The General Meeting is convened by the Board of Directors. The convening must be done written, one month before and has to precise the Agenda of the meeting. It is the Board of Directors that gives the Agenda.

                                </span>
                            </h3>

                            <h3 className="LayTxt" align="justify">
                                <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                                    14 : <span style={{ textDecoration: "underline" }}> DELIBERATION DURING THE GENERAL MEETING

</span>
                                </span>
                            </h3>

                            <h3 className="styltxt" align="justify">
                                <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                                The General Meeting is directed by the Chairman of the NGO; if he is hitched, the meeting has to be directed by the Vice-Chairman or another member of the Board of the Directors. If there is no member of the Board of Directors, the assembly must decide about who will direct the meeting. If elections have to take place, about those elections and discussions, which take place before, the Meeting can be directed by an Election Comity. The person who directs the General Meeting nominates the General Secretary of the Meeting. The person who directs the Meeting gives the Voting Mode. The voting has to be done written, if one third of the present Voters want it. The General Meeting is not public. The Chairman of the Meeting can allow invited persons assist. The Assembly decides about the authorization of Press, Radio or Television. The General Meeting is allowed to deliberate, if at least one third of all the members of the NGO are present. If the conditions are not complete to take decisions, the Board of Directors has to convene a second General Meeting within 6(six) weeks, with the same agenda; this time, the number of present members doesn’t matter. The General Meeting is this time allowed to deliberate with any number of present members. The Board of Directors has the obligation to write this in the convocations.

The General Meeting accepts a decision, if this decision obtains the simple majority of the votes. However, for the change of the Texts, a majority of at least two thirds of the votes is required. A change of the objective of “GERMANY-AFRICA” can only be obtained with the agreement of all the members. A written agreement of the absent members during the General Meeting can only be presented to the Board of Directors within 4(four) weeks.

In case of election, the procedure is the following one:

If, during the first round, nobody obtains the absolute majority, a ballot voting (second round) has to take place, between both of the candidates, who obtained the greatest vote number.

A report has to be drawn up on the deliberations of the General Meeting. This report must be signed by the person who directed the General Meeting and by the General Secretary of the Meeting. The report must have following information: place, date and time of the Meeting, the person who directed the Meeting, the General Secretary of the Meeting, the number of present persons, the Agenda of the Meeting, the results of the Voting and the Voting Mode.

It is forbidden to smoke during the General Meeting.
                                </span>
                            </h3>

                        
                            <h3 className="LayTxt" align="justify">
                                <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                                    15 : <span style={{ textDecoration: "underline" }}> ADDITIONAL SUGGESTIONS TO THE AGENDA </span>
                                </span>
                            </h3>

                            <h3 className="styltxt" align="justify">
                                <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                                Every member has the right, at the latest one week before the day of the General Meeting, to request written the Board of Directors to join additional affairs to the agenda of the Meeting at the beginning of the Meeting. About the suggestions to the agenda done during the meeting, it is the General Meeting that decides. The suggestion must obtain a majority of three thirds of the votes, in order to be accepted.
                                </span>
                            </h3>

                            <h3 className="LayTxt" align="justify">
                                <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                                    16 : <span style={{ textDecoration: "underline" }}> EXTRAORDINARY GENERAL ASSEMBLY </span>
                                </span>
                            </h3>

                            <h3 className="styltxt" align="justify">
                                <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                                    The Board of Directors may convene an Extraordinary General Assembly at any time. It must be convened if the interest of the NGO requires it, or if this convening is required in writing by two-thirds of all members to the Board of Directors, specifying the purpose and reason for the Extraordinary General Assembly. For the Extraordinary General Assembly, chapters 12, 13, 14, and 15 apply.
                                </span>
                            </h3>

                            <h3 className="LayTxt" align="justify">
                                <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                                    17 : <span style={{ textDecoration: "underline" }}> BREAKING THE NGO AND WELL-FOUNDED DATE OF TERM

</span>
                                </span>
                            </h3>

                            <h3 className="styltxt" align="justify">
                                <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                                The Breaking of the NGO can only be decided during a General Meeting, which takes into account the majorities in the chapter 14. As long as the General Meeting doesn’t decide anything, the Chairman and the Vice-Chairman (both together), are the persons, who represent the NGO during the Liquidation Procedure.

At the Dissolution ( at the Breaking) of the NGO, the possessions of the NGO have to come to the GOETHE-INSTITUTE and have to be used for the education of people, who are stranger to Germany.

The present Texts have been written at the FOUNDING GENERAL MEETING on Friday, May, the 12th, 2006.
                                </span>
                            </h3>

                            <p className="Laysom">
                    <span style={{ fontFamily: 'verdana, geneva', fontSize: '10pt' }}>
                    FOR THE TEXTS IN FRENCH AND GERMAN; SEE PLEASE : <a href="HomeEn">www.deutschland-afrika.de</a>
                    </span>
                </p>

                            <h3 className="styltxt" align="justify">
                                <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                                  
WORLD CHEARMAN OF THE NGO : Dr. DABA GBOTA
                                </span>
                            </h3>

                      </div>

                  </div>
                </article>
              </div>
            </div>
                <aside id="right">
                  <h2 className="unseen">Additional Information</h2>
                  <div className="moduletable">
                    <h3>Representation</h3>
                    <div className="custom">
                      <p>Our headquarters are in Germany, in BONN.</p>
                      <p>
                        The Representation coordinates and directs the activities of "Germany-AFRICA" in an African country.
                      </p>
                      <p>The first and only representation currently is in Côte d'Ivoire.</p>
                      <p>&nbsp;</p>
                      <p>&nbsp;</p>
                    </div>
                  </div>
                </aside>
            </div>
        </div>
      </div>
         <div id="footer-outer">
            <div id="footer-sub">
                <footer id="footer"></footer>
            </div>
        </div>

    </div>
  );
};

export default TextsOfTheNgo;
