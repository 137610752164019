import React from 'react';

const TextesAllemagneAfrique = () => {
    
 // Définition des fonctions manquantes
 const changeFontSize = (size) => {
    const body = document.body;
    const currentSize = parseFloat(window.getComputedStyle(body).fontSize);
    body.style.fontSize = `${currentSize + size}px`;
  };

  const revertStyles = () => {
    document.body.style.fontSize = "16px"; // Taille de police par défaut
  };

  const auf = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.style.display = 'none'; // Ferme la section
    }
  };

  return (
    <div id="shadow">
      <div id="all">
        <div id="back">
          <header id="header">
            <div className="logoheader">
              <h1 id="logo">
                <img src="/images/deustch-afrika-logo.png" alt="Deutschland-Afrika !" />
              </h1>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div className="custom">
                        <p>&nbsp;</p>
                        <table>
                          <tbody>
                            <tr>
                              <td id="nomong" align="center" valign="bottom" bgcolor="transparent">
                                <span className="Style2">Deutschland-Afrika e.V. </span>
                              </td>
                            </tr>
                            <tr>
                              <td id="tagline" align="center" valign="top" bgcolor="transparent">
                                <span className="Style1">ONG Internationale Allemagne-Afrique</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <div id="lang"></div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <ul className="skiplinks">
              <li><a href="#main" className="u2">Aller au contenu</a></li>
              <li><a href="#nav" className="u2">Aller au menu principal et à l'identification</a></li>
              <li><a href="#right" className="u2">Aller aux informations additionnelles</a></li>
            </ul>
            <h2 className="unseen">Navigation de recherche</h2>
            <h3 className="unseen">Navigation</h3>
            <div id="line">
              <div id="fontsize">
                <h3>Taille de la police</h3>
                <p>
                  <a
                    title="Augmenter la taille"
                    href="#"
                    onClick={(e) => { e.preventDefault(); changeFontSize(2); }}
                  >
                    Augmenter
                  </a>
                  <span className="unseen">.</span>
                  <a
                    href="#"
                    title="Revenir aux styles par défaut"
                    onClick={(e) => { e.preventDefault(); revertStyles(); }}
                  >
                    Réinitialiser
                  </a>
                  <span className="unseen">.</span>
                  <a
                    href="#"
                    title="Réduire la taille"
                    onClick={(e) => { e.preventDefault(); changeFontSize(-2); }}
                  >
                    Diminuer
                  </a>
                </p>
              </div>
              <h3 className="unseen">Recherche</h3>
              <div className="mod-languages">
                <ul className="lang-inline">
                  <li className="" dir="ltr">
                    <a href="NGOText">
                      <img src="/lang/de_de.gif" alt="Deutsch" title="Deutsch" />
                    </a>
                  </li>
                  <li className="" dir="ltr">
                    <a href="TextesAllemagneAfrique">
                      <img src="/lang/fr_fr.gif" alt="Français" title="Français" />
                    </a>
                  </li>
                  <li className="" dir="ltr">
                    <a href="TextsOfTheNgo">
                      <img src="/lang/en.gif" alt="English (UK)" title="English (UK)" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </header>
          <div id="contentarea2">
            <div id="breadcrumbs">
              <div className="breadcrumbs">
                <span className="showHere">Vous êtes ici : </span>
                <a href="Accueil" className="pathway">Accueil</a>
                <img src="images/arrow.png" alt="" />
                <span>Presentation-allemagne-afrique</span>
                <img src="images/arrow.png" alt="" />
                <span>Les textes de l'ONG</span>
              </div>
            </div>

            <nav className="left1" id="nav">
              <div className="moduletable_menu">
                <h3>Menu principal</h3>
                <ul className="nav menu mod-list">
                  <li className="item-103 default"><a href="Accueil">Accueil</a></li>
                  <li className="item-105 active divider deeper parent">
                    <span className="separator">Présentation</span>
                    <ul className="nav-child unstyled small">
                      <li className="item-106"><a href="MotivationsObjectifs">Motivations / Objectifs</a></li>
                      <li className="item-107 current active"><a href="TextesAllemagneAfrique">Les textes de l'ONG</a></li>
                      <li className="item-108"><a href="PresidentAllemagneAfrique">Le président international</a></li>
                    </ul>
                  </li>
                  <li className="item-109"><a href="Adhesion">Adhésion</a></li>
                  <li className="item-110"><a href="Contact">Contacts</a></li>
                </ul>
              </div>
            </nav>

            <div id="wrapper">
              <div id="main">
                <div id="system-message-container"></div>
                <article className="item-page">
                  <div className="pull-left"></div>
                  <div className="styltit">
                    <span style={{ fontFamily: 'verdana, geneva', fontSize: '10pt' }}>SOMMAIRE</span>
                  </div>
                  <div className="Laysom">
                    <p className="Laysom">
                      <span style={{ fontFamily: 'verdana, geneva', fontSize: '10pt' }}>
                        <a href="#PREAMBULE">PREAMBULE </a>
                      </span>
                    </p>
                    <p className="Laysom">
                      <span style={{ fontFamily: 'verdana, geneva', fontSize: '10pt' }}>
                        <a href="#NOM, SIEGE, ANNEE D'EXERCICE">NOM, SIEGE, ANNEE D'EXERCICE</a>
                      </span>
                    </p>
                    <p className="Laysom">
                      <span style={{ fontFamily: 'verdana, geneva', fontSize: '10pt' }}>
                        <a href="#OBJECTIF">OBJECTIF</a>
                      </span>
                    </p>
                    <p className="Laysom">
                      <span style={{ fontFamily: 'verdana, geneva', fontSize: '10pt' }}>
                        <a href="#MEMBRE">MEMBRE</a>
                      </span>
                    </p>
                    <p className="Laysom">
                      <span style={{ fontFamily: 'verdana, geneva', fontSize: '10pt' }}>
                        <a href="#ORGANES">ORGANES</a>
                      </span>
                    </p>
                    <p className="Laysom">
                      <span style={{ fontFamily: 'verdana, geneva', fontSize: '10pt' }}>
                        <a href="#REPRESENTATION">REPRESENTATION</a>
                      </span>
                    </p>
                  </div>
                  <div className="LayTxt">
                    <p className="styltxt" align="center">
                      <span style={{ fontFamily: 'verdana, geneva', fontSize: '10pt', textDecoration: "underline" }}>
                        <div id="PREAMBULE">PREAMBULE</div>
                      </span>
                    </p>
                    <h3 className="styltxt" align="justify">
                      <span className="Style1" style={{ fontSize: '10pt', fontFamily: 'verdana, geneva' }}>
                        Le monde serait si beau, l’humanité serait si épanouie, si les hommes ne se repoussaient pas les uns les autres sur la base de préjugés.
                      </span>
                    </h3>
                    <h3 className="styltxt" align="justify">
                      <span style={{ fontSize: '10pt', fontFamily: 'verdana, geneva' }}>
                        Le monde serait si beau, l’humanité serait si rayonnante de joie, si les peuples du monde communiquaient les uns avec les autres, vivaient en harmonie et dans la gaieté et si les uns évitaient d’inventer des préjugés à propos des autres.
                      </span>
                    </h3>
                    <h3 className="styltxt" align="justify">
                      <span style={{ fontSize: '10pt', fontFamily: 'verdana, geneva' }}>
                        Considérant que les graves problèmes de l’humanité ont une partie de leurs causes dans le rejet de l’autre.
                      </span>
                    </h3>
                    <h3 className="styltxt" align="justify">
                      <span style={{ fontSize: '10pt', fontFamily: 'verdana, geneva' }}>
                        Convaincus que le déficit de communication est en grande partie à la base de ces préjugés ;
                      </span>
                    </h3>
                    <h3 className="styltxt" align="justify">
                      <span style={{ fontSize: '10pt', fontFamily: 'verdana, geneva' }}>
                        Convaincus du fait que le rapprochement et l’entente entre les peuples pourront contribuer à l’harmonie sur terre et au rayonnement de l’humanité entière, nous avons décidé de créer une ONG pour le rapprochement et l’entente entre les peuples.
                      </span>
                    </h3>
                    <div>
                    <h3 className="LayTxt" align="justify">
                        <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                        <div id="NOM, SIEGE, ANNEE D'EXERCICE">
                            1 : <span style={{ textDecoration: "underline" }}>DU NOM, DU SIEGE, DE L’ANNEE D’EXERCICE</span>
                        </div>
                        </span>
            
                    </h3>
                    <h3 className="styltxt" align="justify">
                        <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                        L’ONG porte le nom "DEUTSCHLAND-AFRIKA" et doit être enregistré dans le registre des Associations ; après l’enregistrement ce nom portera le supplément " e.V. ".
                        Selon les circonstances, il est permis de traduire le nom dans d’autres langues. L’ONG a son siège en Allemagne, à BONN. L’année d’exercice de l’ONG est l’année civile.
                        </span>
                    </h3>
                    <h3 className="LayTxt" align="justify">
                        <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                        <div id="OBJECTIF">
                            2 : <span style={{ textDecoration: "underline" }}>DE L’OBJECTIF DE L’ONG</span>
                        </div>
                        </span>
                    </h3>
                    <h3 className="styltxt" align="justify">
                        <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                        L’objectif de l’ONG est l’entente entre les peuples. Mais "Allemagne-Afrique" se concentre sur l’entente entre le peuple allemand et les peuples africains. L’ONG contribue au rapprochement du peuple allemand et des peuples africains. "Allemagne-Afrique" doit contribuer à la lutte contre les préjugés entre le peuple allemand et les peuples africains.
                        L’ONG poursuit exclusivement et directement des buts d’intérêts communautaires dans le sens du paragraphe « objectifs favorisés en matière d’impôts » des textes sur la réglementation fiscale. L’objectif est réalisé surtout à travers les mesures suivantes :
                        </span>   
                    </h3>
                    <div className="Style1" align="justify">
                        <ol className="Style8">
                    <li>
                    <h3 className="styltxt" align="justify">
                            <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                            Organisation de festivals de la culture allemande dans les pays africains. Une fois par an, "Allemagne-Afrique " organise dans un pays africain, un festival de la culture allemande. Le contenu du festival dépend du niveau de communication entre le peuple allemand et le pays africain concerné ;


                            </span>
                            </h3>
                        </li>
                        <li>
                        <h3 className="styltxt" align="justify">
                            <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                            Organisation de colloques dans le domaine de l’Education ;


                            </span>
                            </h3>
                        </li>
                        <li>
                        <h3 className="styltxt" align="justify">
                            <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                            Organisation d’émissions de radio ou de télévision sur l’Allemagne dans les pays africains et sur les pays africains en Allemagne ;


                            </span>
                            </h3>
                        </li>
                        <li>
                        <h3 className="styltxt" align="justify">
                            <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                            Organisation de festivals de la culture africaine en Allemagne.

                            </span>
                            </h3>
                        </li>
                        </ol>
                     </div>
                    <h3 className="styltxt" align="justify">
                        <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                        Pendant les manifestations d’"ALLEMAGNE-AFRIQUE" dans les pays africains, les langues de communication sont l’Allemand et la langue officielle du pays où la manifestation a lieu.
                        </span>
                    </h3>

                    <h3 className="styltxt" align="justify">
                        <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                        "ALLEMAGNE-AFRIQUE" est bénévole. L’ONG ne poursuit pas de but lucratif. Les moyens de l’ONG ne doivent être utilisés que pour les objectifs visés dans les textes la régissant. Les membres ne perçoivent pas de gains et dans leur qualité de membres aucune subvention particulière émanant des moyens de l’ONG non plus. Il n’est pas permis de faire des dépenses qui sont étrangères aux objectifs de l’ONG ou de faire des compensations démesurées dans le but de favoriser une personne.

                        </span>
                    </h3>


                    <h3 className="styltxt" align="justify">
                        <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                        Tous ceux qui dirigent les organes de l’ONG ne font qu’une fonction honorifique. Toute décision concernant un changement des textes doit être présentée à l’office des finances compétent avant d’être signalée au tribunal.


                        </span>
                    </h3>

                
                    <h3 className="styltxt" align="justify">
                        <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                        <div id="MEMBRE">
                            3 : <span style={{ textDecoration: "underline" }}>DE L’ACQUISITION DE LA QUALITE DE MEMBRE</span>
                        </div>
                        </span>
                    </h3>
                    <h3 className="styltxt" align="justify">
                        <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                        Peut devenir membre de "ALLEMAGNE-AFRIQUE" toute personne qui s’attache aux objectifs de l’ONG. Le Conseil d’Administration délibère sur les demandes d’adhésion. Toute demande d’adhésion doit comporter le nom, l’âge, la profession et l’adresse du requérant. La demande peut être rejetée. Le requérant peut formuler une réclamation contre ce rejet que le Conseil d’Administration doit d’ailleurs faire par écrit et justifier. La réclamation est à déposer auprès du Conseil d’Administration dans un délai d’un mois à partir de la date de réception de l’avis de refus. Cette fois, c’est la prochaine Assemblée Générale qui statue sur la réclamation.

                        </span>
                    </h3>
                    <h3 className="LayTxt" align="justify">
                        <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                          
                        4 : <span style={{ textDecoration: "underline" }}>DE LA CESSATION DE LA QUALITE DE MEMBRE</span>
                        </span>
                    </h3>
                    <h3 className="styltxt" align="justify">
                        <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                        La qualité de membre cesse :
                        </span>
                    </h3>
                    <div className="styltxt" align="justify">
                        <ol className="Style8">
                        <li>
                        <h3 className="styltxt" align="justify">
                            <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                                Avec le décès du membre ;
                            </span>
                            </h3>
                        </li>
                        <li>
                        <h3 className="styltxt" align="justify">
                            <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                                Après retrait volontaire ;
                            </span>
                            </h3>
                        </li>
                        <li>
                        <h3 className="styltxt" align="justify">
                            <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                                Si le membre est rayé de la liste des membres ;
                            </span>
                            </h3>
                        </li>
                        <li>
                        <h3 className="styltxt" align="justify">
                            <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                                Après exclusion de l’ONG.
                            </span>
                            </h3>
                        </li>
                        </ol>
                    </div>
                    <h3 className="styltxt" align="justify">
                        <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                        Le retrait volontaire a lieu après une déclaration écrite vis-à-vis
                        d’un membre du Conseil d’Administration.
                        </span>
                    </h3>
                    <h3 className="styltxt" align="justify">
                        <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                        Un membre peut être rayé de la liste des membres après décision du
                        Conseil d’Administration, s’il est en retard dans le paiement de ses
                        cotisations…
                        </span>
                    </h3>
                    <h3 className="styltxt" align="justify">
                        <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                        Un membre peut, s’il a grossièrement agi contre les intérêts de l’ONG,
                        être exclu de l’ONG après délibération du Conseil d’Administration.
                        </span>
                    </h3>
                    <h3 className="LayTxt" align="justify">
                        <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                        5 : <span style={{ textDecoration: "underline" }}>DES COTISATIONS DES MEMBRES</span>
                        </span>
                    </h3>
                    <h3 className="styltxt" align="justify">
                        <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                        De ses membres, l’ONG perçoit des cotisations. Le montant s’élève à 5(Cinq) Euros par mois. Les cotisations de chaque membre s’élèvent à 60(Soixante) Euros par an. Les membres d’honneur sont exemptés de l’obligation à cotisation.
                        </span>
                    </h3>
                    <h3 className="LayTxt" align="justify">
                        <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                        <div id="ORGANES">
                            6 : <span style={{ textDecoration: "underline" }}>DES ORGANES DE L’ONG</span>
                        </div>
                        </span>
                    </h3>
                    <h3 className="styltxt" align="justify">
                        <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                        Les organes de l’ONG sont :
                        </span>
                    </h3>
                    <div className="Style1" align="justify">
                        <ol className="Style8">
                        <li>
                        <h3 className="styltxt" align="justify">
                            <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                                Le Conseil d’Administration ;
                            </span>
                            </h3>
                        </li>
                        <li>
                        <h3 className="styltxt" align="justify">
                            <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                                L’Assemblée Générale.
                            </span>
                            </h3>
                        </li>
                        </ol>
                      </div>
                      
                        <h3 className="LayTxt" align="justify">
                            <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                            <a name="">
                                7 : <span style={{ textDecoration: "underline" }}>DU CONSEIL D’ADMINISTRATION
                                </span>
                            </a>
                            </span>
                       </h3>
              
                        <h3  className="styltxt"  align="justify">
                            <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                            Le Conseil d’Administration de "ALLEMAGNE-AFRIQUE" est composé du Président de l’ONG et des membres de ce Conseil nommés par lui, notamment le Vice-président, le Trésorier Général et le Secrétaire Général. "ALLEMAGNE-AFRIQUE" est représenté juridiquement et dans le domaine extrajudiciaire par 2(deux) membres du Conseil d’Administration, parmi lesquels le Président ou le Vice-président. Le Président de l’ONG officie à BONN, au siège de l’ONG.

                            </span>
                        </h3>
                        <h3 className="LayTxt" align="justify">
                            <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                            <a name="">
                                8 : <span style={{ textDecoration: "underline" }}>DES COMPETENCES DU CONSEIL D’ADMINISTRATION

                                </span>
                            </a>
                            </span>
                       </h3>

                       <h3 className="styltxt" align="justify">
                            <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                            C’est le Conseil d’Administration qui est habilité à décider des affaires de "ALLEMAGNE-AFRIQUE", à moins que les textes attribuent certaines compétences à un autre organe de l’ONG. Le Conseil d’Administration est compétent pour les tâches suivantes :


                            </span>
                        </h3>

                   

                    <div className="Style1" align="justify">
                        <ol className="Style8">
                        <li>
                        <h3 className="styltxt" align="justify">
                            <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                             préparation de l’Assemblée Générale et élaboration de l’ordre du jour ;

                            </span>
                            </h3>
                        </li>
                        <li>
                        <h3 className="styltxt" align="justify">
                            <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                             convocation de l’Assemblée Générale ;

                            </span>
                            </h3>
                        </li>
                        <li>
                        <h3 className="styltxt" align="justify">
                            <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                             publication des décisions de l’Assemblée Générale ;


                            </span>
                            </h3>
                        </li>
                        <li>
                        <h3 className="styltxt" align="justify">
                            <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                             élaboration d’un plan d’action pour l’année d’exercice, la comptabilité, rédaction du rapport annuel ;


                            </span>
                            </h3>
                        </li>
                        <li>
                        <h3 className="styltxt" align="justify">
                            <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                             conclusion et résiliation de contrats de travail ;

                            </span>
                            </h3>
                        </li>
                        <li>
                        <h3 className="styltxt" align="justify">
                            <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                             délibération sur le recrutement ou l’exclusion de membres.


                            </span>
                            </h3>
                        </li>

                        </ol>
                      </div>

                      <h3 className="LayTxt" align="justify">
                        <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                        9 : <span style={{ textDecoration: "underline" }}>DE LA DELIBERATION DU CONSEIL D’ADMINISTRATION</span>
                        </span>
                    </h3>
                    <h3 className="styltxt" align="justify">
                            <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                            Le Président de "ALLEMAGNE-AFRIQUE", qui nomme ensuite les membres de ce Conseil, est élu par l’Assemblée Générale pour une durée de 3 (trois) ans, à compter du jour de l’élection.

                            </span>
                    </h3>

                    <h3 className="styltxt" align="justify">
                            <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                            Il reste en service jusqu’au jour de la nouvelle élection.


                            </span>
                    </h3>

                    <h3 className="styltxt" align="justify">
                            <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                            Est éligible, tout membre communicatif de l’ONG, originaire d’Afrique ou d’Allemagne, ayant de bonnes connaissances en langue ALLEMANDE et ayant un diplôme universitaire. Le Président est rééligible.



                            </span>
                    </h3>


                    <h3 className="LayTxt" align="justify">
                        <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                        10 : <span style={{ textDecoration: "underline" }}> DE LA DELIBERATION DU CONSEIL D’ADMINISTRATION

                        </span>
                        </span>
                    </h3>

                    <h3 className="styltxt" align="justify">

                            <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                            Le Conseil d’Administration délibère en Général en Assemblée de Conseil d’Administration, convoquée par le Président ; en cas d’empêchement de celui-ci, la séance est convoquée par son représentant. La convocation est faite par écrit, par téléphone ou par fax. Dans tous les cas, il faut observer un délai d’une semaine entre la convocation et la tenue de l’Assemblée du Conseil. Le Conseil d’Administration est habilité à délibérer, si au moins deux tiers du Conseil sont présents et si le Président est parmi ces deux tiers. Pendant la délibération, la décision est valable, si elle requiert la majorité des suffrages exprimés. S’il y a égalité de voix, c’est la voix du Président qui détermine l’issue du vote.
                            </span>
                    </h3>

                    <h3 className="styltxt" align="justify">
                          <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                          L’Assemblée du Conseil d’Administration est dirigée par le Président ; en cas d’empêchement de celui-ci, c’est le Vice-président qui préside le Conseil.

                          </span>
                    </h3>


                    <h3 className="styltxt" align="justify">
                          <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                          Les décisions du Conseil d’Administration doivent, par soucis de preuve, être enregistrées dans un registre consacré aux délibérations et signées par le Président de séance.

                          </span>
                    </h3> 
                    <h3 className="styltxt" align="justify">
                          <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                          La délibération écrite doit comporter le lieu, l’heure de la séance du Conseil, les noms des participants, les décisions prises et le résultat du vote. Il n’est pas permis qu’une personne à elle seule cumule plusieurs fonctions du Conseil d’Administration.

                          </span>
                    </h3>

                    <h3 className="LayTxt" align="justify">
                        <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                        <div id="REPRESENTATION">
                              11 : <span style={{ textDecoration: "underline" }}> DES REPRESENTATIONS</span>
                        </div>
                        </span>
                    </h3>

                    <h3 className="styltxt" align="justify">
                          <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                          La Représentation dirige et coordonne dans un pays africain, les activités de "Allemagne-AFRIQUE".

                          </span>
                    </h3>

                    <h3 className="LayTxt" align="justify">
                        <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                        12 : <span style={{ textDecoration: "underline" }}> DE L’ASSEMBLE GENERALE
                       </span>
                        </span>
                    </h3>


                    <h3 className="styltxt" align="justify">
                          <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                          Pendant l’Assemblée Générale, chaque membre a une voix, le membre d’honneur aussi. Pour l’expression du droit de vote, il est permis de remettre une procuration à un autre membre. Pour chaque Assemblée Générale, la procuration doit être communiquée. Un membre n’a cependant pas le droit de représenter plus de 3 (trois) voix étrangères. L’Assemblée Générale est exclusivement compétente pour les affaires suivantes :

                          </span>
                    </h3>

                    </div>
                    <div className="Style1" align="justify">
                        <ol className="Style8">
                        <li>
                        <h3 className="styltxt" align="justify">
                            <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                            Approbation du plan d’action pour l’année suivante élaboré par le Conseil d’Administration ;


                            </span>
                            </h3>
                        </li>
                        <li>
                        <h3 className="styltxt" align="justify">
                            <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                            Acceptation du rapport annuel du Conseil d’Administration ;


                            </span>
                            </h3>
                        </li>
                        <li>
                        <h3 className="styltxt" align="justify">
                            <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                            Destitution du Conseil d’Administration ;



                            </span>
                            </h3>
                        </li>
                        <li>
                        <h3 className="styltxt" align="justify">
                            <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                            Election et révocation du Président ;



                            </span>
                            </h3>
                        </li>
                       
                        <li>
                        <h3 className="styltxt" align="justify">
                            <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                            Délibération concernant le changement des textes et la dissolution de l’ONG ;



                            </span>
                            </h3>
                        </li>

                        <li>
                        <h3 className="styltxt" align="justify">
                            <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                            Délibération concernant le litige à propos du rejet d’une demande d’adhésion et délibération concernant le recours contre une décision d’exclusion émanant du Conseil d’Administration ;




                            </span>
                            </h3>
                        </li>

                        <li>
                        <h3 className="styltxt" align="justify">
                            <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                            Nomination de membres d’honneur.




                            </span>
                            </h3>
                        </li>
                        </ol>
                      </div>
                      <h3 className="styltxt" align="justify">
                            <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                            Dans des affaires qui tombent dans le domaine de compétence du Conseil d’Administration, l’Assemblée Générale peut faire des recommandations au Conseil. Le Conseil d’Administration de son côté, peut, dans des affaires qui relèvent de son domaine de compétence, solliciter les recommandations de l’Assemblée Générale.
                           </span>
                      </h3>


                      <h3 className="LayTxt" align="justify">
                        <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                        13 : <span style={{ textDecoration: "underline" }}> DE LA CONVOCATION DE L’ASSEMBLEE GENERALE

                       </span>
                        </span>
                    </h3>

                    <h3 className="styltxt" align="justify">
                            <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                            L’Assemblée Générale se tient 2(deux) fois ans l’année : une séance en Mars et une en Novembre. Elle est convoquée par le Conseil d’Administration, par écrit ,1(un) mois avant la séance et en précisant l’ordre du jour. C’est le Conseil d’Administration qui détermine l’ordre du jour.

                           </span>
                      </h3>

                      <h3 className="LayTxt" align="justify">
                        <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                        14 : <span style={{ textDecoration: "underline" }}> DE LA CONVOCATION DE L’ASSEMBLEE GENERALE

                       </span>
                        </span>
                    </h3>

                    <h3 className="styltxt" align="justify">
                            <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                            L’Assemblée Générale est dirigée par le Président, en cas d’empêchement, le Vice-président ou un autre membre du Conseil d’Administration. Si aucun membre du Conseil d’Administration n’est présent, l’Assemblée décide de celui qui va la diriger. S’il y a des élections, pour la durée des élections et la discussion préliminaire, la Direction de l’Assemblée peut être assurée par un Comité Electoral. Le Secrétaire de séance est désigné par celui qui dirige l’Assemblée Générale. Le mode de scrutin est donné par celui qui dirige l’Assemblée. Le vote doit être fait par écrit, si un tiers des membres électeurs présents le demandent. L’Assemblée Générale n’est pas publique. Le Président de l’Assemblée peut autoriser des invités à y assister. L’Assemblée statue sur l’autorisation de la presse, de la radio ou de la télévision. L’Assemblée Générale est habilitée à délibérer, si au moins un tiers de tous les membres de l’ONG sont présents. Si les conditions ne sont pas requises pour que l’Assemblée délibère, le Conseil d’Administration doit convoquer, dans un délai de 6(six) semaines, une deuxième Assemblée Générale avec le même ordre du jour ; cette fois, cette Assemblée Générale ne tient plus compte du nombre de membres présents ; elle est habilitée à délibérer quel que soit le nombre de membres présents. Le Conseil d’Administration a l’obligation de mentionner ce fait dans les convocations.


                           </span>
                      </h3>

                      <h3 className="styltxt" align="justify">
                            <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                            L’Assemblée Générale accepte une décision si celle-ci requiert la majorité simple des suffrages exprimés. Pour le changement des textes cependant, il est exigé une majorité recouvrant au moins deux tiers des suffrages exprimés. Un changement de l’objectif de "ALLEMAGNE-AFRIQUE" ne peut être décidé qu’avec l’accord de tous les membres. Un accord écrit des membres absents à l’Assemblée Générale ne peut être présenté au Conseil d’Administration que dans un délai de quatre semaines.



                           </span>
                      </h3>

                      <h3 className="styltxt" align="justify">
                            <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                            En cas d’élection, la procédure est la suivante :



                           </span>
                      </h3>

                      <h3 className="styltxt" align="justify">
                            <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                            Si au premier tour, aucun candidat n’a la majorité absolue, un scrutin de ballottage (un deuxième tour) doit avoir lieu entre les deux candidats qui ont atteint le plus grand nombre de voix.



                           </span>
                      </h3>

                      <h3 className="styltxt" align="justify">
                            <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                            Un procès-verbal doit être établi sur les délibérations de l’Assemblée Générale. Ce procès-verbal doit être signé par celui qui a dirigé l’Assemblée et par le Secrétaire de séance. Il doit comporter les données suivantes : lieu, date et temps de l’Assemblée, la personne qui a dirigé l’Assemblée, le Secrétaire de séance, le nombre des membres présents, l’ordre du jour, les différents résultats de vote et le mode de scrutin.



                           </span>
                      </h3>

                      <h3 className="styltxt" align="justify">
                            <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                            Il est interdit de fumer pendant l’Assemblée Générale.
                           </span>
                      </h3>
                    
                      <h3 className="LayTxt" align="justify">
                        <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                        15 : <span style={{ textDecoration: "underline" }}> DES SUGGESTIONS ADDITIONNELLES A L’ORDRE DU JOUR

                       </span>
                        </span>
                    </h3>

                    <h3 className="styltxt" align="justify">
                            <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                            Tout membre a le droit, au plus tard une semaine avant le jour de l’Assemblée Générale, de demander par écrit auprès du Conseil d’Administration, que d’autres affaires soient inscrites additionnellement à l’ordre du jour au début de la séance. Quant à ce qui concerne les suggestions à l’ordre du jour qui sont faites seulement pendant l’Assemblée Générale, c’est l’Assemblée Générale qui décide. Pour que la suggestion soit acceptée à l’ordre du jour, il est exigé une majorité recouvrant trois quarts des voix.


                           </span>
                      </h3>

                      <h3 className="LayTxt" align="justify">
                        <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                        16 : <span style={{ textDecoration: "underline" }}>DE L’ASSEMBLEE GENERALE EXTRAORDINAIRE

                       </span>
                        </span>
                    </h3>

                    <h3 className="styltxt" align="justify">
                            <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                            Le Conseil d’Administration peut à tout moment convoquer une Assemblée Générale Extraordinaire. Celle-ci doit être convoquée si l’intérêt de l’ONG l’exige, ou si cette convocation est exigée par écrit par deux tiers de tous les membres auprès du Conseil d’Administration, en précisant le but et la raison de ladite Assemblée Générale Extraordinaire. Pour l’Assemblée Générale Extraordinaire, les chapitre 12, 13, 14 et 15 sont valables.
                           </span>
                      </h3>

                      <h3 className="LayTxt" align="justify">
                        <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                        17 : <span style={{ textDecoration: "underline" }}>DE LA DISSOLUTION DE L’ONG ET DU BIEN-FONDE D’ECHEANCE
                       </span>
                        </span>
                    </h3>

                    <h3 className="styltxt" align="justify">
                            <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                            Le Conseil d’Administration peut à tout moment convoquer une Assemblée Générale Extraordinaire. Celle-ci doit être convoquée si l’intérêt de l’ONG l’exige, ou si cette convocation est exigée par écrit par deux tiers de tous les membres auprès du Conseil d’Administration, en précisant le but et la raison de ladite Assemblée Générale Extraordinaire. Pour l’Assemblée Générale Extraordinaire, les chapitre 12, 13, 14 et 15 sont valables.
                           </span>
                      </h3>

                      <h3 className="styltxt" align="justify">
                            <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                            A la dissolution de l’ONG, les biens de l’ONG doivent revenir à l’Institut Goethe et doivent être utilisés pour la formation de personnes étrangères à l’Allemagne.

                           </span>
                      </h3>

                      <h3 className="styltxt" align="justify">
                            <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                            Les présents textes ont été rédigés à l’Assemblée Générale Constitutive du vendredi 12 Mai 2006.

                           </span>
                      </h3>

                      <h3 className="styltxt" align="justify">
                            <span style={{ fontSize: "10pt", fontFamily: "verdana, geneva" }}>
                            En annexe, se trouvent les signatures des personnes qui ont participé à l’Assemblée Générale Constitutive et qui ont d’emblée été membres fondateurs de l’ONG.

                           </span>
                      </h3>



                    <p>&nbsp;</p>
                    <p align="center">
                      <span className="styltxt"></span>
                    </p>
                  </div>
                </article>
              </div>
            </div>

            <div id="close">
              <a href="#" onClick={() => auf('right')}>
                <span id="bild">Fermer les infos</span>
              </a>
            </div>

              <aside id="right">
                            <h2 className="unseen">Informations supplémentaires</h2>
                            <div className="moduletable">
                                <h3>Représentation</h3>
                            <div className="custom">
                                <p>Notre siège est en Allemagne, à BONN.</p>
                                <p>
                                    La Représentation dirige et coordonne dans un pays africain, les activités de
                                    "Allemagne-AFRIQUE".
                                </p>
                                <p>La première et seule représentation pour l'instant est en Côte d'Ivoire.</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                            </div>
                            </div>
                </aside>
            </div>
        </div>
      </div>
         <div id="footer-outer">
            <div id="footer-sub">
                <footer id="footer"></footer>
            </div>
        </div>

    </div>
  );
};

export default TextesAllemagneAfrique;
