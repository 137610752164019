import React from 'react';

const MotivationObjectif = () => {

      // Define missing functions
      const changeFontSize = (size) => {
        const body = document.body;
        const currentSize = parseFloat(window.getComputedStyle(body).fontSize);
        body.style.fontSize = `${currentSize + size}px`;
      };

      const revertStyles = () => {
        document.body.style.fontSize = "16px"; // Default font size
      };

      const auf = (id) => {
        const element = document.getElementById(id);
        if (element) {
          element.style.display = 'none'; // Close the section
        }
      };

  return (
    <>
      <title>Motivations / Objectives</title>
      <body id="shadow">
        <div id="all">
          <div id="back">
            <header id="header">
              <div className="logoheader">
                <h1 id="logo">
                  <img src="/images/deustch-afrika-logo.png" alt="Deutschland-Afrika !" />
                </h1>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div className="custom">
                          <p>&nbsp;</p>
                          <div>
                            <table>
                              <tbody>
                                <tr>
                                  <td id="nomong" align="center" valign="bottom" bgcolor="transparent">
                                    <span className="Style2">Deutschland-Afrika e.V. </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td id="tagline" align="center" valign="top" bgcolor="transparent">
                                    <span className="Style1">International NGO Germany-Africa</span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>
                        <div id="lang"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <ul className="skiplinks">
                <li>
                  <a href="#main" className="u2">
                    Go to content
                  </a>
                </li>
                <li>
                  <a href="#nav" className="u2">
                    Go to the main menu and identification
                  </a>
                </li>
                <li>
                  <a href="#right" className="u2">
                    Go to additional information
                  </a>
                </li>
              </ul>
            <div id="line">
              <div id="fontsize">
                <h3>Font Size</h3>
                <p>
                  <a
                    title="Increase font size"
                    href=""
                    onClick={(e) => { e.preventDefault(); changeFontSize(2); }}
                  >
                    Increase
                  </a>
                  <span className="unseen">.</span>
                  <a
                    href=""
                    title="Revert to default styles"
                    onClick={(e) => { e.preventDefault(); revertStyles(); }}
                  >
                    Reset
                  </a>
                  <span className="unseen">.</span>
                  <a
                    href=""
                    title="Decrease font size"
                    onClick={(e) => { e.preventDefault(); changeFontSize(-2); }}
                  >
                    Decrease
                  </a>
                </p>
              </div>
              <h3 className="unseen">Search</h3>
              <div className="mod-languages">
                <ul className="lang-inline">
                  <li className="" dir="ltr">
                    <a href="MotivationenZiele">
                      <img src="/lang/de_de.gif" alt="Deutsch" title="Deutsch" />
                    </a>
                  </li>
                  <li className="" dir="ltr">
                    <a href="MotivationsObjectifs">
                      <img src="/lang/fr_fr.gif" alt="Français" title="Français" />
                    </a>
                  </li>
                  <li className="" dir="ltr">
                    <a href="MotivationObjectif">
                      <img src="/lang/en.gif" alt="English (UK)" title="English (UK)" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </header>
            <div id="contentarea2">
              <div id="breadcrumbs">
                <div className="breadcrumbs">
                  <span className="showHere">You are here: </span>
                  <a href="HomeEn" className="pathway">
                    Home
                  </a>{' '}
                  <img src="/images/arrow.png" alt="" /> <span>Presentation</span>{' '}
                  <img src="/images/arrow.png" alt="" /> <span>Motivations / Objectives</span>
                </div>
              </div>
              <nav className="left1" id="nav">
                <div className="moduletable_menu">
                  <h3>Main Menu</h3>
                  <ul className="nav menu mod-list">
                    <li className="item-103 default">
                      <a href="HomeEn">Home</a>
                    </li>
                    <li className="item-105 active divider deeper parent">
                      <span className="separator">Presentation</span>
                      <ul className="nav-child unstyled small">
                        <li className="item-106 current active">
                          <a href="MotivationObjectif">Motivations / Objectives</a>
                        </li>
                        <li className="item-107">
                          <a href="TextsOfTheNgo">Texts of the NGO</a>
                        </li>
                        <li className="item-108">
                          <a href="PresidentGermanyAfrica">International President</a>
                        </li>
                      </ul>
                    </li>
                    <li className="item-109">
                      <a href="Membership">Membership</a>
                    </li>
                    <li className="item-110">
                      <a href="Contacts">Contact</a>
                    </li>
                  </ul>
                </div>
              </nav>
              <div id="wrapper">
                <div id="main">
                  <div id="system-message-container"></div>
                  <article className="item-page">
                    <p className="Stylemotiv">
                      Considering that the serious problems of humanity have some of their causes in the rejection of others.
                    </p>
                    <p className="Stylemotiv">
                      Considering that this rejection is often based on prejudice;
                    </p>
                    <p className="Stylemotiv">
                      Convinced that the communication gap is largely at the root of these prejudices;
                    </p>
                    <p className="Stylemotiv">
                      Convinced that bringing people together and fostering understanding between peoples can contribute to harmony on Earth and the progress of all humanity, we have decided to create an NGO for rapprochement and understanding between peoples.
                    </p>
                    <div className="Layer4">
                      <span className="Style3" style={{ fontFamily: "verdana, geneva", fontSize: "10pt" }}>
                        OUR OBJECTIVES
                      </span>
                    </div>
                    <div>
                      <span style={{ fontFamily: "verdana, geneva", fontSize: "10pt" }}>&nbsp;</span>
                    </div>
                    <div className="LayMotObj">
                      <p className="Stylemotiv" align="left">
                        The NGO's objective is understanding between peoples. But "Germany-Africa" focuses on understanding between the German people and African peoples. The NGO contributes to bringing the German people and African peoples closer together. "Germany-Africa" aims to fight prejudice between the German people and African peoples.
                      </p>
                      <p className="Stylemotiv" align="left">
                        The NGO pursues exclusively and directly community-oriented goals as defined in the "tax-favored objectives" section of tax regulation texts. The objective is mainly achieved through the following measures:
                      </p>
                      <div align="left">
                        <ol className="Stylemotiv">
                          <li className="Stylemotiv">
                            Organization of German culture festivals in African countries. Once a year, "Germany-Africa" organizes a German culture festival in an African country. The content of the festival depends on the level of communication between the German people and the concerned African country;
                          </li>
                          <li className="Stylemotiv">Organization of conferences in the field of Education;</li>
                          <li className="Stylemotiv">
                            Organization of radio or television broadcasts about Germany in African countries and about African countries in Germany;
                          </li>
                          <li className="Stylemotiv">Organization of African culture festivals in Germany.</li>
                        </ol>
                      </div>
                      <p className="Stylemotiv" align="left">
                        During the "GERMANY-AFRICA" events in African countries, the languages of communication are German and the official language of the country where the event is held.<br />
                        <br /> "GERMANY-AFRICA" is a volunteer organization. The NGO does not pursue profit-making goals. The resources of the NGO should only be used for the objectives outlined in the texts governing it. Members do not receive profits, and no special subsidies are granted to them from the NGO's funds. Expenditures unrelated to the NGO's objectives or excessive compensations to favor an individual are prohibited.
                      </p>
                      <p className="Stylemotiv" align="left">
                        In case of dissolution of the NGO or abandonment of the goal for which it was created, the NGO's assets will be returned to the GOETHE Institute to be used for training foreigners in Germany.
                      </p>
                      <p className="Stylemotiv" align="left">
                        All those who lead the NGO's bodies hold honorary positions. Any decision to change the texts must be submitted to the competent tax office before being reported to the court.
                      </p>
                    </div>
                    <p>
                      <span style={{ fontFamily: "verdana, geneva", fontSize: "10pt" }}>&nbsp;</span>
                    </p>
                  </article>
                </div>
              </div>
              <aside id="right">
                  <h2 className="unseen">Additional Information</h2>
                  <div className="moduletable">
                    <h3>Representation</h3>
                    <div className="custom">
                      <p>Our headquarters are in Germany, in BONN.</p>
                      <p>
                        The Representation coordinates and directs the activities of "Germany-AFRICA" in an African country.
                      </p>
                      <p>The first and only representation currently is in Côte d'Ivoire.</p>
                      <p>&nbsp;</p>
                      <p>&nbsp;</p>
                    </div>
                  </div>
                </aside>
            </div>
          </div>
            <div id="footer-outer">
              <div id="footer-sub">
                <footer>
                  <p>&nbsp;</p>
                </footer>
              </div>
            </div>
        </div>
      </body>
    </>
  );
}

export default MotivationObjectif;
