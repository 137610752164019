import React from 'react';



const Accueil= () => {

  // Définition des fonctions manquantes
  const changeFontSize = (size) => {
    const body = document.body;
    const currentSize = parseFloat(window.getComputedStyle(body).fontSize);
    body.style.fontSize = `${currentSize + size}px`;
  };

  const revertStyles = () => {
    document.body.style.fontSize = "16px"; // Taille de police par défaut
  };

  const auf = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.style.display = 'none'; // Ferme la section
    }
  };
    return (
    <div id="shadow">
      <div id="all">
        <div id="back">
          <header id="header">
            <div className="logoheader">
              <h1 id="logo">
                <img src="/images/deustch-afrika-logo.png" alt="Deutschland-Afrika !" />
              </h1>
              <table>
                <tr colspan="2">
                  <td>
                    <div className="custom">
                      <p>&nbsp;</p>
                      <div>
                        <table>
                          <tbody>
                            <tr>
                              <td id="nomong" align="center" valign="bottom" bgcolor="transparent">
                                <span className="Style2">Deutschland-Afrika e.V.</span>
                              </td>
                            </tr>
                            <tr>
                              <td id="tagline" align="center" valign="top" bgcolor="transparent">
                                <span className="Style1">ONG Internationale Allemagne-Afrique</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <div id="lang"></div>
                  </td>
                </tr>
              </table>
            </div>
            <ul className="skiplinks">
              <li>
                <a href="#main" className="u2">Aller au contenu</a>
              </li>
              <li>
                <a href="#nav" className="u2">Aller au menu principal et à l'identification</a>
              </li>
              <li>
                <a href="#right" className="u2">Aller aux informations additionnelles</a>
              </li>
            </ul>
            <h2 className="unseen">Navigation de recherche</h2>
            <h3 className="unseen">Navigation</h3>

            <div id="line">
              <div id="fontsize">
                <h3>Taille de la police</h3>
                
                <p>
                  <a
                    title="Augmenter la taille"
                    href="#"
                    onClick={(e) => { e.preventDefault(); changeFontSize(2); }}
                  >
                    Augmenter
                  </a>
                  <span className="unseen">.</span>
                  <a
                    href="#"
                    title="Revenir aux styles par défaut"
                    onClick={(e) => { e.preventDefault(); revertStyles(); }}
                  >
                    Réinitialiser
                  </a>
                  <span className="unseen">.</span>
                  <a
                    href="#"
                    title="Réduire la taille"
                    onClick={(e) => { e.preventDefault(); changeFontSize(-2); }}
                  >
                    Diminuer
                  </a>
                </p>
              </div>
              <h3 className="unseen">Recherche</h3>
              <div className="mod-languages">
                  <ul className="lang-inline">
                      <li className="" dir="ltr">
                          <a href="Empfang">
                              <img src="/lang/de_de.gif" alt="Deutsch" title="Deutsch" />
                          </a>
                      </li>
                      <li className="" dir="ltr">
                          <a href="Accueil">
                              <img src="/lang/fr_fr.gif" alt="Français" title="Français" />
                          </a>
                      </li>
                      <li className="" dir="ltr">
                          <a href="HomeEn">
                              <img src="/lang/en.gif" alt="English (UK)" title="English (UK)" />
                          </a>
                      </li>
                  </ul>
                </div>
            </div>
          </header>

          <div id="contentarea2">
            <div id="breadcrumbs">
              <div className="breadcrumbs">
                <span className="showHere">Vous êtes ici : </span><span>Accueil</span>
              </div>
            </div>

            <nav className="left1" id="nav">
              <div className="moduletable_menu">
                <h3>Menu principal</h3>
                <ul className="nav menu mod-list">
                  <li className="item-103 default current active">
                    <a href="Accueil">Accueil</a>
                  </li>
                  <li className="item-105 divider deeper parent">
                    <span className="separator">Présentation</span>
                    <ul className="nav-child unstyled small">
                      <li className="item-106"></li>
                      <li>
                        <a href="MotivationsObjectifs">Motivations / Objectifs</a>
                      </li>
                      <li className="item-107"></li>
                      <li>
                        <a href="TextesAllemagneAfrique">Les textes de l'ONG</a>
                      </li>
                      <li className="item-108"></li>
                      <li>
                        <a href="PresidentAllemagneAfrique">Le président international</a>
                      </li>
                    </ul>
                  </li>
                  <li className="item-109"></li>
                  <li>
                    <a href="Adhesion">Adhésion</a>
                  </li>
                  <li className="item-110"></li>
                  <li>
                    <a href="Contact">Contacts</a>
                  </li>
                </ul>
              </div>
            </nav>

            <div id="wrapper">
              <div id="main">
                <div id="top">
                  <div className="custom">
                    <div className="LayMotObj">
                      <p className="Stylemotiv">
                        <span style={{ fontFamily: 'verdana,geneva' }}>
                          <strong>
                            Le monde serait si beau, l’humanité serait si développée, si les hommes s’acceptaient mutuellement.
                          </strong>
                        </span>
                      </p>
                      <p className="Stylemotiv">
                        <span style={{ fontFamily: 'verdana,geneva' }}>
                          <strong>
                            Le monde serait si beau, l’humanité serait si épanouie, si les hommes ne se repoussaient pas les uns les autres sur la base de préjugés.
                          </strong>
                        </span>
                      </p>
                      <p className="Stylemotiv">
                        <span style={{ fontFamily: 'verdana,geneva' }}>
                          <strong>
                            Le monde serait si beau, l’humanité serait si rayonnante de joie, si les peuples du monde communiquaient les uns avec les autres, vivaient en harmonie et dans la gaieté et si les uns évitaient d’inventer des préjugés à propos des autres.
                          </strong>
                        </span>
                      </p>
                      <p>
                        <span style={{ fontFamily: 'verdana,geneva' }}>
                          <a href=""> </a>
                          <a href="MotivationsObjectifs" id="index.php?Item=6">Lire tout le texte ...</a>
                        </span>
                      </p>
                    </div>
                    <p>&nbsp;</p>
                  </div>
                </div>

                <div id="system-message-container"></div>

                <section className="blog-featured">
                  <div className="items-leading">
                    <article className="leading-0">
                      <div className="pull-left"></div>
                      <div className="centrer_accueil">
                        <p style={{ textAlign: 'center' }}>
                          <img src="/images/acceuil_allemagne_afrique.png" alt="acceuildeutschland" width="373" height="280" />
                        </p>
                      </div>
                      <div className="item-separator"></div>
                    </article>
                  </div>
                </section>
              </div>
            </div>

            <div id="close">
              <a href="#" onClick={() => auf('right')}>
                <span id="bild">Fermer les infos</span>
              </a>
            </div>

            <aside id="right">
              <h2 className="unseen">Informations supplémentaires</h2>
              <div className="moduletable">
                <h3>Représentation</h3>
                <div className="custom">
                  <p>Notre siège est en Allemagne, à BONN.</p>
                  <p>La Représentation dirige et coordonne dans un pays africain, les activités de "Allemagne-AFRIQUE".</p>
                  <p>La première et seule représentation pour l'instant est en Côte d'Ivoire.</p>
                  <p>&nbsp;</p>
                  <p>&nbsp;</p>
                </div>
              </div>
            </aside>
          </div>

          <div className="wrap"></div>
        </div>
      </div>

      <div id="footer-outer">
        <div id="footer-sub">
          <footer id="footer"></footer>
        </div>
      </div>
     </div>
    
  );
}

export default Accueil;